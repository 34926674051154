import $ from 'jquery';
import { Component } from 'react';
import HomeController from './HomeController';

class AppointmentsController extends Component {
    IsAvailable(appointment, promise) {
        $.ajax({
            url: HomeController.UrlApi + "Appointments/IsAvailable",
            method: 'post',
            async: false,
            data: {
                Appointment: JSON.stringify(appointment)
            },
            success: () => {
                promise(true);
            },
            error: () => {
                promise(null);
            }
        });
    }

    GetAppointmentsByUser(user, promise) {
        $.ajax({
            url: HomeController.UrlApi + "Appointments/GetAppointmentsByUser",
            method: 'post',
            data: {
                User: JSON.stringify(user)
            },
            success: (appointments) => {
                promise(appointments);
            },
            error: () => {
                promise(null);
            }
        });
    }

    GetAvailableAppointments(date, promise) {
        $.ajax({
            url: HomeController.UrlApi + "Appointments/GetAvailableAppointments",
            method: 'post',
            data: {
                Date: date
            },
            success: (appointments) => {
                promise(appointments);
            },
            error: () => {
                promise(null);
            }
        });
    }

    MakeAppointments(appointment, user, promise) {
        $.ajax({
            url: HomeController.UrlApi + "Appointments/MakeAppointments",
            method: 'post',
            data: {
                Appointment: JSON.stringify(appointment),
                User: JSON.stringify(user)
            },
            success: () => {
                promise(true);
            },
            error: () => {
                promise(null);
            }
        });
    }

    RemoveAppointments(appointment, promise) {
        $.ajax({
            url: HomeController.UrlApi + "Appointments/RemoveAppointments",
            method: 'post',
            data: {
                Appointment: JSON.stringify(appointment)
            },
            success: () => {
                promise(true);
            },
            error: () => {
                promise(null);
            }
        });
    }

    CheckAppointment(appointment, user) {
        var error = null;
        if (appointment == null || appointment.DateTime == null) {
            error = "Rendez-vous invalide";
        }

        return error;
    }
}

AppointmentsController.displayName = 'AppointmentsController';

export default (new AppointmentsController());