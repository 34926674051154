import React, { Component } from 'react';
import AddUpdate from './AddUpdate';


class Add extends Component {
    render() {
        return (
            <AddUpdate parent={this.props.parent}/>
        );
    }
}

Add.displayName = "Add";

export default Add;
