import { textAlignCenter, justifyContentCenter, textAlignRight } from '../_MainStyles';

const RenewalLensStyles = theme => ({
	container: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	textField: {
		marginLeft: theme.spacing.unit,
		marginRight: theme.spacing.unit,
	},
	datepicker: {
		marginTop: 16,
		marginBottom: 8,
		marginLeft: theme.spacing.unit,
		marginRight: theme.spacing.unit,
	},
	dense: {
		marginTop: 16,
	},
	menu: {
		width: 200,
	},
	root: {
		flexGrow: 1,
	},
	demo: {
		height: 240,
	},
	control: {
		padding: theme.spacing.unit * 2,
	},
	paper: {
		width: '80%',
		minWidth: 256,
		maxWidth: 300,
		padding: '30px 20px',
		margin: '80px auto 80px',
		[theme.breakpoints.up('sm')]: {
			width: '80%',
			minWidth: 556,
			maxWidth: 600,
		},
	},
	paperWithButtonLens: {
		[theme.breakpoints.up('sm')]: {
			margin: '60px auto 100px',
		},
		[theme.breakpoints.only('xs')]: {
			margin: '40px auto 70px',
		},
	},
	headContainer: {
		display: 'flex',
	},
	title: {
		display: 'flex',
		flexGrow: '1',
		padding: '0 20px',
		marginTop: 15,
		marginBottom: 10
	},
	iconContainer: {
		marginTop: 5,
		height: 48
	},
	subContainer: {
		marginTop: 20
	},
	spaceBetweenGrid: {
		marginBottom: 20,
	},
	spaceBetweenTitle: {
		marginTop: 30,
	},
	textAlignCenter: {
		...textAlignCenter
	},
	justifyContentCenter: {
		...justifyContentCenter
	},
	lensRenewalContent: {
		...textAlignRight,
		...theme.mixins.gutters(),
		marginTop: 30,
		marginBottom: 40
	},
	inputTypeFile: {
		display: 'none',
	},
	pictureButton: {
		width: 256,
		height: 56,
		marginTop: 16,
		marginBottom: 8,
		marginLeft: theme.spacing.unit,
		marginRight: theme.spacing.unit,
	},
	pictureButtonIcon: {
		marginLeft: theme.spacing.unit,
	},
	lensRenewalButton: {
		position: 'absolute',
		right: 20,
		top: 76,
		'@media (min-width:0px) and (orientation: landscape)': {
			top: 64,
		},
		'@media (min-width:600px)': {
			top: 80,
		},
	},
	paperlist: {
		width: '80%',
		maxWidth: 980,
		margin: 'auto',
		padding: 20,
		marginTop: 40,
		marginBottom: 20,
		alignItems: 'center',
		display: 'flex'
	},
	typographyList: {
		flex: '1 1 auto',
		padding: '0 16px',
		minWidth: 0
	},
	listRenewalLens:{
		maxHeight: 450,
		overflow: 'auto'
	}
});

export default RenewalLensStyles