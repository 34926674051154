import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames';
import CloseIcon from 'mdi-material-ui/Close';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import SuccessIcon from 'mdi-material-ui/CheckCircle';
import ErrorIcon from 'mdi-material-ui/AlertCircle';
import WarningIcon from 'mdi-material-ui/Alert';
import InfoIcon from 'mdi-material-ui/Information';
import SnackbarHelperStyles from '../Views/Styles/HelperStyles/SnackbarHelperStyles';


class SnackbarHelper extends Component {

	renderByType = () => {
		const { classes } = this.props;
		
		switch (this.props.type) {
			case 'success':
				return <SuccessIcon className={classNames(classes.icon, classes.iconVariant)}/>
			case 'error':
				return <ErrorIcon className={classNames(classes.icon, classes.iconVariant)}/>
			case 'warning':
				return <WarningIcon className={classNames(classes.icon, classes.iconVariant)}/>
			case 'info':
				return <InfoIcon className={classNames(classes.icon, classes.iconVariant)}/>
			default:
				return null;
		}
	}

  render() {
	const { classes, closeSnackbar, type, message } = this.props;

    return (
		<Snackbar
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'left',
			}}
			open={message != null}
			autoHideDuration={6000}
			onClose={closeSnackbar}
		>
			<SnackbarContent
				className={classes[type]}
				message={
					<span className={classes.message}>
						{this.renderByType()}
						{message}
					</span>
				}
				action={[
					<IconButton
						key="close"
						color="inherit"
						onClick={closeSnackbar}
					>
						<CloseIcon
							className={classes.icon}
						/> 
					</IconButton>,
				]}
			/>
	  	</Snackbar>
    );
  }
}

SnackbarHelper.propTypes = {
	classes: PropTypes.object.isRequired,
	type: PropTypes.oneOf(['success','error','warning','info']),
	closeSnackbar: PropTypes.func.isRequired,
};

export default withStyles(SnackbarHelperStyles)(SnackbarHelper);