import $ from 'jquery';
import { Component } from 'react';
import HomeController from './HomeController';

let that;

class PresentationsController extends Component {
    constructor(props) {
        super(props);
        that = this;
    }

    MovePresentations(presentation, type, promise) {
        $.ajax({
            url: HomeController.UrlApi + "Presentations/MovePresentations",
            method: 'post',
            data: {
                Presentation: JSON.stringify(presentation),
                Type: type
            },
            success: () => {
                promise(true);
            },
            error: () => {
                promise(null);
            }
        });
    }

    GetPresentations(promise) {
        $.ajax({
            url: HomeController.UrlApi + "Presentations/GetPresentations",
            method: 'post',
            success: (presentations) => {
                promise(presentations);
            },
            error: () => {
                promise(null);
            }
        });
    }

    GetPresentationsById(idPresentations, promise) {
        $.ajax({
            url: HomeController.UrlApi + "Presentations/GetPresentationsById",
            method: 'post',
            data: {
                IdPresentations: idPresentations
            },
            success: (presentations) => {
                promise(presentations);
            },
            error: () => {
                promise(null);
            }
        });
    }

    AddUpdatePresentations(component, promise) {
        $.ajax({
            url: HomeController.UrlApi + "Presentations/AddUpdatePresentations",
            method: 'post',
            data: that.SetAjaxPresentations(component),
            success: () => {
                promise(true);
            },
            error: () => {
                promise(null);
            }
        });
    }

    DeletePresentations(idPresentations, promise) {
        $.ajax({
            url: HomeController.UrlApi + "Presentations/DeletePresentations",
            method: 'post',
            data: {
                IdPresentations: idPresentations
            },
            success: () => {
                promise(true);
            },
            error: () => {
                promise(null);
            }
        });
    }

    SetAjaxPresentations(component) {
        return {
            Type: !component.state.IdPresentations ? "Add" : "Update",
            Presentations: JSON.stringify(that.SetPresentations(component)),
            PresentationsPicture: component.state.Picture,
            PresentationsPictureExtension: component.state.PictureExtension
        };
    }

    CheckPresentations(component) {
        var error = null;

        if (!error && !component.state.IdPresentations && !component.state.Picture) {
            error = 'Oops, où est passé la photo ?';
        }

        if (!error && !component.state.Store && !component.state.Home) {
            error = 'Oops, où est passé le type de la présentation ?';
        }

        if (!error && !component.state.Title) {
            error = 'Oops, où est passé le titre ?';
        }

        if (!error && !component.state.Description) {
            error = 'Oops, où est passé la description ?';
        }

        return error;
    }

    SetPresentations(component) {
        if (!component.state.IdPresentations) {
            return {
                Title: component.state.Title,
                Description: component.state.Description,
                PicturePath: component.state.PicturePath,
                Store: component.state.Store,
                Home: component.state.Home,
            };
        }
        else {
            return {
                Id: component.state.IdPresentations,
                Title: component.state.Title,
                Description: component.state.Description,
                PicturePath: !component.state.Picture ? component.props.Presentations.PicturePath : component.state.PicturePath,
                DateTimePost: component.props.Presentations.DateTimePost,
                Position: component.props.Presentations.Position,
                Store: component.state.Store,
                Home: component.state.Home,
            };
        }
    }

    GetMatchParams(component) {
        if (component.props.match &&
            component.props.match.params &&
            component.props.match.params.presentationsId) {
            return component.props.match.params.presentationsId
        }

        return null;
    }
}

PresentationsController.displayName = 'PresentationsController';

export default (new PresentationsController());