import EXIF from 'exif-js';

export function formatPicture(file, size, promise) {
    var reader = new FileReader();
    reader.onload = function () {

        var image = new Image();
        image.src = reader.result;

        image.onload = function () {
            var fileTypes = ['jpg', 'jpeg', 'png'];
            var extension = file.name.split('.').pop().toLowerCase();
            var error = null;

            if (!error && image.width < 600 && image.height < 600) {
                error = "Oops, l'image est trop petite (hauteur ou largeur égale à 600px minimum)";
            }

            if (!error && file.size > 2000000) {
                error = "Oops, l'image est trop volumineuse (2 Mo maximum)";
            }

            if (!error && fileTypes.indexOf(extension) === -1) {
                error = "Oops, le format de l'image n'est pas accepté (jpg, jpeg, png)";
            }

            if (!error) {
                var imageSize = image.width > image.height ? image.height : image.width;
                var ratio = imageSize / size;
                var width = imageSize !== image.width ? image.width / ratio : size;
                var height = imageSize !== image.height ? image.height / ratio : size;

                var canvas = document.createElement('canvas');
                canvas.width = width;
                canvas.height = height;
                image.width = width;
                image.height = height;
                var ctx = canvas.getContext("2d");

                EXIF.getData(image, function () {
                    var exif = EXIF.getAllTags(image);
                    switch (exif.Orientation) {
                        case 8:
                            ctx.rotate(90 * Math.PI / 180);
                            break;
                        case 3:
                            ctx.rotate(180 * Math.PI / 180);
                            break;
                        case 6:
                            ctx.rotate(-90 * Math.PI / 180);
                            break;
                        default:
                            break;
                    }

                    ctx.drawImage(this, 0, 0, width, height);

                    var picture = image.src.split(';base64,')[1];
                    var thumbnail = canvas.toDataURL(file.type);

                    promise(picture, thumbnail, extension, null);
                });
            }
            else {
                promise(null, null, null, error);
            }
        };
    };
    reader.readAsDataURL(file);
}