import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles'
import RenewalLensStyles from '../Styles/_ProfilStyles/RenewalLensStyles';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Fab from '@material-ui/core/Fab';
import Typography from '@material-ui/core/Typography';
import SnackbarHelper from '../../Helpers/SnackbarHelper';
import AutocompleteHelper from '../../Helpers/AutocompleteHelper';
import MutualsController from '../../Controllers/MutualsController';
import Camera from 'mdi-material-ui/Camera';
import { Collapse, Button } from '@material-ui/core';
import classNames from 'classnames';
import { MuiPickersUtilsProvider, DatePicker } from "material-ui-pickers";
import MomentUtils from '@date-io/moment';
import ChevronDown from 'mdi-material-ui/ChevronDown';
import IconButton from '@material-ui/core/IconButton';
import Close from 'mdi-material-ui/Close';
import MaskedInput from 'react-text-mask';
import { formatPicture } from '../../Tools/ImageTool';
import RenewalLensController from '../../Controllers/RenewalLensController';
import Avatar from '@material-ui/core/Avatar';
import Account from 'mdi-material-ui/Account';
import DotsVertical from 'mdi-material-ui/DotsVertical';
import moment from 'moment';
import "moment/locale/fr";
moment.locale('fr');

var fileSize = 0;
let that;
const form = {
    SocialSecurityNumber: {
        name: 'SocialSecurityNumber',
        label: 'Numéro de sécurité social',
        required: true,
        regex: /^[123478] [0-9][0-9] ([0][1-9]|[1][0-2]|[6][23]) ([0-13-9][0-9]|[2][0-9AaBb]) ([0-9]{3} ){2}[0-9]{2}$/,
        errorMessage: 'Numéro de sécurité social invalide',
        type: "text"
    },
    DateOfBirth: {
        name: 'DateOfBirth',
        label: 'Date de naissance',
        required: true,
        regex: /^$/,
        errorMessage: 'Date de naissance invalide',
        type: "date"
    },
    AmcSearch: {
        name: 'AmcSearch',
        label: 'Numéro AMC',
        required: false,
        regex: /^[0-9]{6}$/,
        errorMessage: 'Numéro AMC invalide',
        type: "text"
    },
    AmcMutual: {
        name: 'AmcMutual',
        label: 'Mutuelle',
        required: false,
        regex: /^$/,
        errorMessage: 'Veuillez sélectionner une mutuelle grâce à votre identifiant AMC',
        type: "object"
    },
    SubscriberNumber: {
        name: 'SubscriberNumber',
        label: "Numéro d'adhérent",
        required: false,
        regex: /[0-9]{10}/,
        errorMessage: "Numéro d'adhérent invalide",
        type: "text"
    },
    EndDateValidity: {
        name: 'EndDateValidity',
        label: 'Date de fin de validité',
        required: false,
        regex: /^$/,
        errorMessage: 'Date de fin de validité invalide',
        type: "date"
    },
    MutualPicture: {
        name: 'MutualPicture',
        label: "Carte de mutuelle",
        required: false,
        regex: /^$/,
        errorMessage: 'Carte de mutuelle invalide',
        type: "picture"
    },
    DateOrder: {
        name: 'DateOrder',
        label: "Date de l'ordonnance",
        required: true,
        regex: /^$/,
        errorMessage: "Date de l'ordonnance invalide",
        type: "date"
    },
    OrderPicture: {
        name: 'OrderPicture',
        label: "Ordonnance",
        required: true,
        regex: /^$/,
        errorMessage: 'Ordonnance invalide',
        type: "picture"
    },
};

var mutualInput = [
    "AmcMutual",
    "SubscriberNumber",
    "EndDateValidity",
    "MutualPicture"
]

function TextFieldSocialSecurityNumber(props) {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={ref => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[/[123478]/, ' ', /[0-9]/, /[0-9]/, ' ', /[0-16]/, /[1-9]/, ' ', /[0-9]/,
                /[0-9]/, ' ', /[0-9]/, /[0-9]/, /[0-9]/, ' ', /[0-9]/, /[0-9]/, /[0-9]/, ' ', /[0-9]/, /[0-9]/]}
            placeholderChar={'\u2000'}
            showMask={false}
        />
    );
}

TextFieldSocialSecurityNumber.propTypes = {
    inputRef: PropTypes.func.isRequired,
};


class RenewalLens extends Component {
    constructor(props) {
        super(props);

        this.state = this.initState();

        that = this;
    }

    componentDidMount() {
        this.handleLoadRenewalLens();

        MutualsController.GetAmcMutuals(
            (mutuals) => {
                this.setState({
                    SuggestionsAmcMutual: mutuals
                });
            }
        )
    }

    initState = (user) => {
        var pUser = user || this.props.parent.state.User;
        var mutual = pUser && pUser.Mutuals && pUser.Mutuals.length > 0 ? pUser.Mutuals.reverse()[0] : null;
        var amcMutual = mutual ? mutual.IdAmcMutualNavigation : null;

        var order = pUser && pUser.Orders && pUser.Orders.length > 0 ? pUser.Orders.reverse()[0] : null;

        return {
            RenewalLens: [],
            UpdateRenewalLens: null,
            SocialSecurityNumber: pUser && pUser.SocialSecurityNumber ? pUser.SocialSecurityNumber : '',
            DateOfBirth: pUser && pUser.DateOfBirth ? pUser.DateOfBirth : null,
            HaveMutual: order && !mutual ? false : true,
            AmcMutual: amcMutual || null,
            SuggestionsAmcMutual: [],
            AmcSearch: amcMutual ? amcMutual.Name : '',
            SubscriberNumber: mutual ? mutual.SubscriberNumber : '',
            EndDateValidity: mutual ? mutual.EndDateValidity : null,
            Mutual: mutual,
            MutualPicturePath: mutual ? mutual.PicturePath : null,
            MutualPicture: null,
            MutualPictureExtention: null,
            Order: order,
            DateOrder: order ? order.DateOrder : null,
            OrderPicturePath: order ? order.PicturePath : null,
            OrderPicture: null,
            OrderPictureExtention: null,
            SnackbarMessage: null,
            SnackbarType: "warning",
            InfoCollapsed: order !== null,
            MyLensCollapsed: order === null,
        };
    }

    handleLoadRenewalLens = (rl) => {
        if (rl) {
            this.setState({
                RenewalLens: rl
            })
        }
        else {
            const { User } = this.props.parent.state;

            RenewalLensController.GetRenewalLensByIdUser(User.Id, (renewalLens) => {
                this.setState({
                    RenewalLens: renewalLens
                })
            })
        }
    }

    handleChangeTextField = event => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    handleChangeHaveMutual = () => {
        this.setState({
            HaveMutual: !this.state.HaveMutual
        });
    }

    handleExpendCollapse = (name) => {
        this.setState({
            [name]: !this.state[name]
        })
    }

    handleCheckData = () => {
        var error = null;

        for (let [key, value] of Object.entries(form)) {
            if (!error &&
                (value.required ||
                    (that.state.HaveMutual && mutualInput.indexOf(key) !== -1))) {
                if (value.type === "text") {
                    if (!value.regex.test(that.state[key])) {
                        error = value.errorMessage
                    }
                }
                if (value.type === "date") {
                    var date = moment.utc(that.state[key]);
                    if (!date.isValid()) {
                        error = value.errorMessage
                    }
                }
                if (value.type === "picture") {
                    if ((that.state[key] === null ||
                        that.state[key + "Extention"] === null) && !that.state[key + "Path"]) {
                        error = value.errorMessage
                    }
                }
                if (value.type === "object") {
                    if (that.state[key] === null ||
                        that.state[key + "Extention"] === null) {
                        error = value.errorMessage
                    }
                }
            }
        }

        that.setState({
            SnackbarMessage: error
        });

        return error;
    }

    handleDateChange = (date, name) => {
        this.setState({
            [name]: date
        });
    }

    handleDateFocused = (name) => {
        if (!this.state[name]) {
            this.setState({
                [name]: moment.utc()
            });
        }
    }

    handleAmcSearchChange = value => {
        this.setState({
            AmcSearch: value,
        });
    }

    handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({
            SnackbarMessage: null
        });
    };

    handleBrowsePicture = idElement => {
        document.getElementById(idElement).click();
    }

    handleChangePicture = (name, idElement) => {
        formatPicture(document.getElementById(idElement).files[0], fileSize, (picture, thumbnail, extension, error) => {
            if (!error) {
                this.setState({
                    [name]: picture,
                    [name + "Extention"]: extension
                });
            }
            else {
                this.setState({
                    SnackbarMessage: error,
                    SnackbarType: 'warning'
                });
            }
        })
        document.getElementById(idElement).value = null;
    }

    getSuggestionValue(suggestion) {
        that.setState({
            AmcMutual: suggestion
        })
        return suggestion.Name;
    }

    handleSubmitInfo = () => {
        if (this.handleCheckData() === null) {
            this.props.parent.handleLoadMainLoader();
            RenewalLensController.AddInfoRenewalLens(this, (user) => {
                if (user) {
                    that.props.parent.handleUpdateUser(user);
                }
                this.props.parent.handleUnloadMainLoader();
            })
        }
    }

    handleSubmit = () => {
        const { User } = this.props.parent.state;

        if (User && User.Orders.length !== 0) {
            RenewalLensController.AddRenewalLens(this, (renewalLens) => {
                this.handleLoadRenewalLens(renewalLens);
            })
        }
        else {
            this.setState({
                SnackbarType: "info",
                SnackbarMessage: 'Veuillez complèter vos informations de renouvellement de lentilles',
                MyLensCollapsed: true,
                InfoCollapsed: false
            })
        }
    }

    render() {
        const { classes } = this.props;
        const { User } = this.props.parent.state;

        return (
            <div>
                {
                    User && User.Orders && User.Orders.length !== 0 &&
                    <Button
                        className={classes.lensRenewalButton}
                        variant="contained"
                        color="secondary"
                        onClick={this.handleSubmit}
                    >
                        Renouveller mes lentilles
                    </Button>
                }
                <Paper
                    elevation={12}
                    className={classes.paper}
                >
                    <Grid justify='center' container spacing={0}>
                        <Grid xs={12} item className={classNames(classes.textAlignCenter, classes.headContainer)}>
                            <Typography variant="h6" color="primary" className={classes.title}>
                                Informations de renouvellement de lentilles
                            </Typography>
                            <IconButton
                                color="default"
                                className={classes.iconContainer}
                                onClick={() => this.handleExpendCollapse("InfoCollapsed")}
                            >
                                {this.state.InfoCollapsed ? <ChevronDown /> : <Close />}
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Collapse in={!this.state.InfoCollapsed}>
                        <Grid justify='center' container spacing={8} className={classNames(classes.spaceBetweenGrid, classes.subContainer)}>
                            <Grid xs={12} item className={classes.textAlignCenter}>
                                <TextField
                                    name={form.SocialSecurityNumber.name}
                                    type="text"
                                    label={form.SocialSecurityNumber.label}
                                    className={classes.textField}
                                    value={this.state[form.SocialSecurityNumber.name]}
                                    onChange={this.handleChangeTextField}
                                    margin="normal"
                                    variant="outlined"
                                    InputProps={{
                                        inputComponent: TextFieldSocialSecurityNumber
                                    }}
                                />
                                <MuiPickersUtilsProvider utils={MomentUtils} locale="fr">
                                    <DatePicker
                                        className={classes.datepicker}
                                        label={form.DateOfBirth.label}
                                        variant="outlined"
                                        cancelLabel="Annuler"
                                        value={this.state[form.DateOfBirth.name]}
                                        disableFuture
                                        openTo="year"
                                        format="DD/MM/YYYY"
                                        onFocus={() => this.handleDateFocused(form.DateOfBirth.name)}
                                        views={["year", "month", "day"]}
                                        onChange={(date) => this.handleDateChange(date, form.DateOfBirth.name)}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid xs={12} item className={classNames(classes.textAlignCenter, classes.spaceBetweenTitle)}>
                                <Typography variant="button" gutterBottom color="primary">
                                    Mutuelle
                        </Typography>
                            </Grid>
                            <Grid xs={12} item className={classes.textAlignCenter}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={this.state.HaveMutual}
                                            onChange={this.handleChangeHaveMutual}
                                            value="checkedA"
                                        />
                                    }
                                    label={(this.state.HaveMutual ? "Avec " : "Sans ") + "ma mutuelle"}
                                />
                            </Grid>
                            <Collapse in={this.state.HaveMutual}>
                                <Grid xs={12} item className={classes.textAlignCenter}>
                                    <AutocompleteHelper
                                        name={form.AmcSearch.name}
                                        label={form.AmcSearch.label}
                                        value={this.state[form.AmcSearch.name]}
                                        suggestions={this.state.SuggestionsAmcMutual}
                                        suggestionsLabel="Amc"
                                        renderLabel="Name"
                                        onChange={value => this.handleAmcSearchChange(value)}
                                        shouldRenderSuggestions={true}
                                        getSuggestionValue={this.getSuggestionValue}
                                    />
                                    <TextField
                                        name={form.SubscriberNumber.name}
                                        type="text"
                                        label={form.SubscriberNumber.label}
                                        className={classes.textField}
                                        value={this.state[form.SubscriberNumber.name]}
                                        onChange={this.handleChangeTextField}
                                        margin="normal"
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid xs={12} item className={classes.textAlignCenter}>
                                    <MuiPickersUtilsProvider utils={MomentUtils} locale="fr">
                                        <DatePicker
                                            className={classes.datepicker}
                                            label={form.EndDateValidity.label}
                                            cancelLabel="Annuler"
                                            variant="outlined"
                                            value={this.state[form.EndDateValidity.name]}
                                            disablePast
                                            openTo="month"
                                            format="DD/MM/YYYY"
                                            onFocus={() => this.handleDateFocused(form.EndDateValidity.name)}
                                            views={["year", "month", "day"]}
                                            onChange={(date) => this.handleDateChange(date, form.EndDateValidity.name)}
                                        />
                                    </MuiPickersUtilsProvider>
                                    <input
                                        id="pictureMutual"
                                        type="file"
                                        className={classes.inputTypeFile}
                                        accept="image/jpeg"
                                        onChange={() => this.handleChangePicture(form.MutualPicture.name, 'pictureMutual')}
                                    />
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        className={classes.pictureButton}
                                        onClick={() => this.handleBrowsePicture('pictureMutual')}
                                    >
                                        Mutuelle
                                <Camera className={classes.pictureButtonIcon} />
                                    </Button>
                                </Grid>
                            </Collapse>
                            <Grid xs={12} item className={classNames(classes.textAlignCenter, classes.spaceBetweenTitle)}>
                                <Typography variant="button" gutterBottom color="primary">
                                    Ordonnance
                        </Typography>
                            </Grid>
                            <Grid xs={12} item className={classes.textAlignCenter}>
                                <MuiPickersUtilsProvider utils={MomentUtils} locale="fr">
                                    <DatePicker
                                        className={classes.datepicker}
                                        label={form.DateOrder.label}
                                        variant="outlined"
                                        cancelLabel="Annuler"
                                        value={this.state[form.DateOrder.name]}
                                        disableFuture
                                        openTo="month"
                                        format="DD/MM/YYYY"
                                        onFocus={() => this.handleDateFocused(form.DateOrder.name)}
                                        views={["year", "month", "day"]}
                                        onChange={(date) => this.handleDateChange(date, form.DateOrder.name)}
                                    />
                                </MuiPickersUtilsProvider>
                                <input
                                    id="pictureOrder"
                                    type="file"
                                    className={classes.inputTypeFile}
                                    accept="image/jpeg"
                                    onChange={() => this.handleChangePicture(form.OrderPicture.name, 'pictureOrder')}
                                />
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.pictureButton}
                                    onClick={() => this.handleBrowsePicture('pictureOrder')}
                                >
                                    Ordonnance
                            <Camera className={classes.pictureButtonIcon} />
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid justify='center' container spacing={8}>
                            <Grid xs={12} item className={classes.textAlignCenter}>
                                <Fab
                                    variant="extended"
                                    color="secondary"
                                    onClick={this.handleSubmitInfo}
                                >
                                    VALIDER
                        </Fab>
                            </Grid>
                        </Grid>
                    </Collapse>
                    <SnackbarHelper
                        type={this.state.SnackbarType}
                        message={this.state.SnackbarMessage}
                        closeSnackbar={this.handleCloseSnackbar}
                    />
                </Paper>
                <Paper
                    elevation={12}
                    className={classes.paper}
                >
                    <Grid justify='center' container spacing={0}>
                        <Grid xs={12} item className={classNames(classes.textAlignCenter, classes.headContainer)}>
                            <Typography variant="h6" color="primary" className={classes.title}>
                                Mes renouvellements de lentilles
                            </Typography>
                            <IconButton
                                color="default"
                                className={classes.iconContainer}
                                onClick={() => this.handleExpendCollapse("MyLensCollapsed")}
                            >
                                {this.state.MyLensCollapsed ? <ChevronDown /> : <Close />}
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Collapse in={!this.state.MyLensCollapsed} className={classes.listRenewalLens}>
                        <Grid justify='center' container spacing={8} className={classNames(classes.spaceBetweenGrid, classes.subContainer)}>
                            <Grid xs={12} item>
                                {
                                    this.state.RenewalLens && this.state.RenewalLens.map((o, i) => {
                                        return (
                                            <Paper key={i} className={classes.paperlist} elevation={10}>
                                                <Avatar>
                                                    <Account />
                                                </Avatar>
                                                <div className={classes.typographyList}>
                                                    <Typography variant="button">
                                                        {moment(o.DateRequest).utc().format('DD/MM/YYYY')}
                                                    </Typography>
                                                    <Typography variant="overline">
                                                        {"Demande " + o.IdStatusNavigation.Name}
                                                    </Typography>
                                                </div>
                                                <IconButton
                                                    onClick={this.handleClick}>
                                                    <DotsVertical />
                                                </IconButton>
                                            </Paper>
                                        )
                                    })
                                }
                            </Grid>
                        </Grid>
                    </Collapse>
                </Paper>
                <SnackbarHelper
                    type={this.state.SnackbarType}
                    message={this.state.SnackbarMessage}
                    closeSnackbar={this.handleCloseSnackbar}
                />
            </div>
        )
    }
}


RenewalLens.displayName = 'RenewalLens';

RenewalLens.propTypes = {
    classes: PropTypes.object.isRequired,
};

RenewalLens.contextTypes = {
    router: PropTypes.object.isRequired,
};

export default withStyles(RenewalLensStyles)(RenewalLens);