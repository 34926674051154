import { textAlignCenter, displayFlex, hidden } from '../_MainStyles';
import logo from '../../Images/LogoBlancOpticSalomon.svg'
import { grey } from '@material-ui/core/colors';

const logoWidth = 50;

const HeaderStyles = theme => ({
    root: {
        flexGrow: 1,
    },
    title: {
        flexGrow: 1,
        ...textAlignCenter
    },
    hidden: {
        ...hidden
    },
    logo: {
        backgroundImage: 'url(' + logo + ')',
        backgroundRepeat: 'no-repeat',
        width: logoWidth,
        height: (logoWidth / 1.6),
        cursor: 'pointer',
    },
    fakeAppBar: {
        ...theme.mixins.toolbar
    },
    drawerdivlist: {
        width: 285,
        overflow: 'auto',
        [theme.breakpoints.only('xs')]: {
            width: 240,
        },
    },
    drawerlist: {
        minHeight: 450
    },
    drawerHeader: {
        ...displayFlex,
        ...theme.mixins.toolbar,
        position: 'fixed',
        alignItems: 'center',
        paddingRight: theme.spacing.unit * 2,
        paddingLeft: theme.spacing.unit * 2,
        justifyContent: 'flex-end',
    },
    paperDrawer: {
        overflow: 'hidden',
    },
    absoluteDivider: {
        position: 'absolute',
        right: 0,
        bottom: 55,
        width: '100%',
    },
    typoName: {
        textAlign: 'center',
        color: "#555",
        position: 'absolute',
        right: 0,
        bottom: 0,
        width: '100%',
        height: 48,
        backgroundColor: '#fff'
    },
    typoNameText: {
        marginTop: 10
    },
    listItemIcon: {
        [theme.breakpoints.only('xs')]: {
            marginRight: 0,
        },
    },
    listItemText: {
        [theme.breakpoints.only('xs')]: {
            padding: '0 0 0 16px'
        },
    },
    dividerDrawerHeader: {
        marginTop: 56,
        '@media (min-width:0px) and (orientation: landscape)': {
            marginTop: 48,
        },
        '@media (min-width:600px)': {
            marginTop: 64,
        },
    },
    textAlignCenter: {
        ...textAlignCenter
    },
    subTitle: {
        color: grey[400],
        padding: '0 20px',
        marginTop: 15,
        marginBottom: 5
    },
    submitButtonContent: {
        margin: '20px auto',
        width: '80%',
        height: 36
    },
    fullSubmitButtonContent: {
        margin: '20px auto',
        height: 36
    },
    submitButton: {
        float: 'right',
    },
    datepicker: {
        width: '80%',
        marginTop: 16,
        marginBottom: 8
    },
});

export default HeaderStyles;