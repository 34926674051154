import { Component } from 'react';
import CryptoJS from 'crypto-js';

class HomeController extends Component {
    constructor(props) {
        super(props);
        this.UrlApi = "https://api.opticsalomon.com/";
        this.UrlWS = "wss://ws.opticsalomon.com";
        this.MapboxglAccessToken = 'pk.eyJ1IjoiZmxhdmllbnBlcnJhaXMiLCJhIjoiY2pwY2w3YmJiMTdzMjN1c2I0bGo3MWx2eCJ9.6J2YqEUC0jgMCBdia7s3wg';

        this.EmailSended = false;
        this.AccountDeleted = false;
    }

    GetEncryptSHA256Key(value) {
        return CryptoJS.SHA256(value ? value.toString() : 0).toString()
    }
}

HomeController.displayName = 'HomeController';

export default new HomeController();