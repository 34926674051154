import $ from 'jquery';
import { Component } from 'react';
import HomeController from './HomeController';
import moment from 'moment';
import "moment/locale/fr";
moment.locale('fr');

let that;

class RenewalLensController extends Component {
    constructor(props) {
        super(props);
        that = this;
    }

    GetRenewalLensByDate(promise) {
        $.ajax({
            url: HomeController.UrlApi + "RenewalLens/GetRenewalLens",
            method: 'post',
            success: (renewalLens) => {
                promise(renewalLens);
            },
            error: () => {
                promise(null);
            }
        });
    }

    GetRenewalLensByIdUser(idUser, promise) {
        $.ajax({
            url: HomeController.UrlApi + "RenewalLens/GetRenewalLensByIdUser",
            method: 'post',
            data: {
                IdUser: idUser
            },
            success: (renewalLens) => {
                promise(renewalLens);
            },
            error: () => {
                promise(null);
            }
        });
    }

    AddInfoRenewalLens(component, promise) {
        $.ajax({
            url: HomeController.UrlApi + "RenewalLens/AddInfoRenewalLens",
            method: 'post',
            data: that.SetAjaxRenewalLens(component),
            success: (user) => {
                promise(user);
            },
            error: () => {
                promise(null);
            }
        });
    }

    AddRenewalLens(component, promise) {
        $.ajax({
            url: HomeController.UrlApi + "RenewalLens/AddRenewalLens",
            method: 'post',
            data: {
                RenewalLens: JSON.stringify(that.SetRenewalLens(component, "add"))
            },
            success: (renewalLens) => {
                promise(renewalLens);
            },
            error: () => {
                promise(null);
            }
        });
    }

    CancelRenewalLens(component, promise) {
        $.ajax({
            url: HomeController.UrlApi + "RenewalLens/CancelRenewalLens",
            method: 'post',
            data: {
                RenewalLens: JSON.stringify(that.SetRenewalLens(component, "cancel"))
            },
            success: () => {
                promise(true);
            },
            error: () => {
                promise(null);
            }
        });
    }

    SetAjaxRenewalLens(component) {
        if (component.state.HaveMutual) {
            return {
                IdUser: component.props.parent.state.User.Id,
                SocialSecurityNumber: component.state.SocialSecurityNumber,
                DateOfBirth: moment(component.state.DateOfBirth).utc().format('DD/MM/YYYY'),
                Mutuals: JSON.stringify(that.SetMutuals(component)),
                MutualPicture: component.state.MutualPicture,
                MutualPictureExtention: component.state.MutualPictureExtention,
                Orders: JSON.stringify(that.SetOrders(component)),
                OrderPicture: component.state.OrderPicture,
                OrderPictureExtention: component.state.OrderPictureExtention
            };
        } else {
            return {
                IdUser: component.props.parent.state.User.Id,
                SocialSecurityNumber: component.state.SocialSecurityNumber,
                DateOfBirth: moment(component.state.DateOfBirth).utc().format('DD/MM/YYYY'),
                Orders: JSON.stringify(that.SetOrders(component)),
                OrderPicture: component.state.OrderPicture,
                OrderPictureExtention: component.state.OrderPictureExtention
            };
        }
    }

    SetRenewalLens(component, type) {
        var now = moment.utc();

        if (type === "add") {
            return {
                IdUser: component.props.parent.state.User.Id,
                DateRequest: now,
                DateLastUpdate: now,
                IdMutuals: component.state.Mutual.Id,
                IdOrders: component.state.Order.Id,
                IdStatus: 1
            };
        }
        else if (type === "cancel") {
            return {
                Id: component.state.UpdateRenewalLens.Id,
                IdUser: component.props.parent.state.User.Id,
                DateRequest: component.state.UpdateRenewalLens.DateRequest,
                DateLastUpdate: now,
                IdMutuals: component.state.Mutual.Id,
                IdOrders: component.state.Order.Id,
                IdStatus: 3
            };
        }
    }

    SetMutuals(component) {
        return {
            IdUser: component.props.parent.state.User.Id,
            IdAmcMutual: component.state.AmcMutual.Id,
            SubscriberNumber: component.state.SubscriberNumber,
            EndDateValidity: component.state.EndDateValidity,
        };
    }

    SetOrders(component) {
        return {
            IdUser: component.props.parent.state.User.Id,
            DateOrder: component.state.DateOrder,
        };
    }
}

RenewalLensController.displayName = 'RenewalLensController';

export default (new RenewalLensController());