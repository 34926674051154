import {
    fullWidthpc, positionAbsolute,
    textAlignCenter, hidden, fullHeightpc
} from './_MainStyles';

import { grey } from '@material-ui/core/colors';

const AddUpdateStyles = theme => ({
    textAlignCenter: {
        ...textAlignCenter,
    },
    cardPapper: {
        boxShadow: 'none',
    },
    standardcontent: {
        ...textAlignCenter,
        marginTop: 20,
    },
    uploadInput: {
        cursor: 'pointer',
        ...positionAbsolute,
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        ...fullWidthpc,
        ...hidden,
        zIndex: -1
    },
    textFieldWidth: {
        width: '80%'
    },
    paperau: {
        padding: 20,
        minWidth: 200,
        margin: '80px auto 80px',
        [theme.breakpoints.up("xs")]: {
            width: 296,
            padding: 10,
        },
        [theme.breakpoints.up("sm")]: {
            width: 570,
        },
        [theme.breakpoints.up("md")]: {
            width: 600,
        },
    },
    cameraIcon: {
        width: 50,
        height: 50,
        color: grey[400],
        [theme.breakpoints.up("xs")]: {
            marginTop: 71.5,
        },
        [theme.breakpoints.up("sm")]: {
            marginTop: 196.5,
        },
        [theme.breakpoints.up("md")]: {
            marginTop: 208.5,
        },
    },
    pictureText: {
        color: grey[400],
        padding: '0 20px',
    },
    hidden: {
        ...hidden
    },
    fullHeightpc: {
        ...fullHeightpc,
    },
    submitButtonContent: {
        margin: '20px auto',
        width: '80%',
        height: 36
    },
    submitButton: {
        float: 'right',
    },
    pictureContentG: {
        width: '80%',
        margin: 'auto',
        border: '1px solid #eee',
        borderRadius: 4,
        backgroundColor: 'transparent',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        cursor: 'pointer'
    },
    pictureContentP: {
        width: '80%',
        margin: 'auto',
        border: '1px solid #eee',
        borderRadius: '50%',
        backgroundColor: 'transparent',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        cursor: 'pointer'
    },
    contentControls: {
        width: '80%',
        margin: '15px auto',
        display: 'flex'
    },
    spacer: {
        flex: 1
    },
    rootLabelSwitch: {
        marginLeft: 0,
    },
    labelSwitch: {
        width: 40
    }
});

export default AddUpdateStyles;
