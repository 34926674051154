import React, { Component } from 'react';
import Profil from './Profil';

class Registration extends Component {
	render() {
		return (
			<Profil parent={this.props.parent} User={null} />
		);
	}
}


Registration.displayName = 'Registration';

export default Registration;