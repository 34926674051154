import React, { Component } from 'react';
import AddUpdate from './AddUpdate';
import GlassesController from '../../Controllers/GlassesController';

let that;
class Update extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Glasses: null,
        };
        that = this;
    }

    componentWillMount() {
        var matchParams = GlassesController.GetMatchParams(this);
        if (matchParams) {
            GlassesController.GetGlassesById(matchParams,
                (glasses) => {
                    that.setState({
                        Glasses: glasses
                    });
                });
        }
    }

    render() {
        return (
            <div>
                {
                    this.state.Glasses &&
                    <AddUpdate parent={this.props.parent} Glasses={this.state.Glasses} />
                }
            </div>
        );
    }
}

Update.displayName = "Update";

export default Update;
