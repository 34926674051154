import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Router } from 'react-router-dom';
import { createHashHistory } from 'history';
import Header from './Views/_Shared/Header';
import Home from './Views/Home';
import Collection from './Views/Collection';
import NotFound from './Views/_Shared/NotFound';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Registration from './Views/_Profil/Registration';
import Account from './Views/_Profil/Account';
// eslint-disable-next-line no-unused-vars
import RenewalLens from './Views/_Profil/RenewalLens';
import LegalNotice from './Views/LegalNotice';
import PrivacyPolicy from './Views/PrivacyPolicy';
import Connection from './Views/Connection';
import AddGlasses from './Views/_Glasses/Add';
import UpdateGlasses from './Views/_Glasses/Update';
import AddPresentations from './Views/_Presentations/Add';
import UpdatePresentations from './Views/_Presentations/Update';
import AdministrationLens from './Views/AdministrationLens';
import SessionController from './Controllers/SessionController';
import Appointments from './Views/Appointments';
import $ from 'jquery';
import { getWSService } from './Controllers/WebSocketService';

const theme = createMuiTheme({
    palette: {
        primary: {
            light: '#5c6483',
            main: '#343e64',
            dark: '#242b46',
            contrastText: '#fff',
        },
        secondary: {
            light: '#fe5661',
            main: '#fe2c3a',
            dark: '#b11e28',
            contrastText: '#fff',
        },
    },
    typography: {
        useNextVariants: true
    }
});

class MainRouter extends Component {
    constructor(props) {
        super(props);
        this.socketConnection = null;
        this.history = createHashHistory();
        this.state = {
            User: null,
        }
    }

    componentWillMount() {
        var jwt = SessionController.GetJwt();
        if (jwt) {
            $.ajaxSetup({
                headers: { 'Authorization': `Bearer ${jwt}` }
            });
        }

        this.socketConnection = getWSService();

        this.setState({
            User: SessionController.GetUser()
        });

        this.history.listen((location, action) => {
            this.socketConnection = getWSService();
            this.setState({
                User: SessionController.GetUser()
            });

            window.scrollTo(0, 0);
        });
    }

    UpdateUser = () => {
        this.setState({
            User: SessionController.GetUser()
        });
    }


    render() {
        const routes = [
            {
                exact: true,
                protected: false,
                user: false,
                admin: false,
                path: "/",
                Component: (props) => <Home {...props} />
            },
            {
                exact: true,
                protected: true,
                user: false,
                admin: false,
                path: "/connection",
                Component: (props) => <Connection {...props} />
            },
            {
                exact: true,
                protected: true,
                user: false,
                admin: false,
                path: "/registration",
                Component: (props) => <Registration {...props} />
            },
            {
                exact: true,
                protected: false,
                user: false,
                admin: false,
                path: "/collection",
                Component: (props) => <Collection {...props} />
            },
            {
                exact: true,
                protected: false,
                user: false,
                admin: false,
                path: "/legalnotice",
                Component: (props) => <LegalNotice {...props} />
            },
            {
                exact: true,
                protected: false,
                user: false,
                admin: false,
                path: "/privacypolicy",
                Component: (props) => <PrivacyPolicy {...props} />
            },
            {
                exact: true,
                protected: true,
                user: true,
                admin: true,
                path: "/account",
                Component: (props) => <Account {...props} />
            },
            {
                exact: true,
                protected: true,
                user: true,
                admin: true,
                path: "/appointments",
                Component: (props) => <Appointments make={false} {...props} />
            },
            {
                exact: true,
                protected: true,
                user: true,
                admin: false,
                path: "/makeappointments",
                Component: (props) => <Appointments make={true} {...props} />
            },
            {
                exact: true,
                protected: true,
                user: false,
                admin: true,
                path: "/administrationlens",
                Component: (props) => <AdministrationLens {...props} />
            },
            {
                exact: true,
                protected: true,
                user: false,
                admin: true,
                path: "/addglasses",
                Component: (props) => <AddGlasses {...props} />
            },
            {
                exact: false,
                protected: true,
                user: false,
                admin: true,
                path: "/updateglasses/:glassesId",
                Component: (props) => <UpdateGlasses {...props} />
            },
            {
                exact: true,
                protected: true,
                user: false,
                admin: true,
                path: "/addpresentations",
                Component: (props) => <AddPresentations {...props} />
            },
            {
                exact: false,
                protected: true,
                user: false,
                admin: true,
                path: "/updatepresentations/:presentationsId",
                Component: (props) => <UpdatePresentations {...props} />
            }
        ]

        return (
            <MuiThemeProvider theme={theme}>
                <Router history={this.history}>
                    <Switch>
                        {
                            routes
                                .filter(f => !f.protected ||
                                    (!this.state.User && !f.user && !f.admin) ||
                                    (this.state.User && this.state.User.IsAdministrator && f.admin) ||
                                    (this.state.User && !this.state.User.IsAdministrator && f.user))
                                .map((route, i) => (
                                    <Route key={i} exact={route.exact} path={route.path} render={props => (
                                        <Header UpdateUser={this.UpdateUser}>
                                            {route.Component(props)}
                                        </Header>
                                    )} />
                                ))
                        }
                        <Route component={NotFound} />
                    </Switch>
                </Router>
            </MuiThemeProvider>
        )
    }
}

MainRouter.displayName = "MainRouter";

MainRouter.propTypes = {
    children: PropTypes.node
};

export default MainRouter;