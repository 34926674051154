import $ from 'jquery';
import { Component } from 'react';
import HomeController from './HomeController';

let that;

class GlassesController extends Component {
    constructor(props) {
        super(props);

        that = this;
    }

    GetCategories(promise) {
        $.ajax({
            url: HomeController.UrlApi + "Glasses/GetCategories",
            method: 'post',
            success: (categories) => {
                promise(categories);
            },
            error: () => {
                promise(null);
            }
        });
    }

    GetLatestGlasses(promise) {
        $.ajax({
            url: HomeController.UrlApi + "Glasses/GetLatestGlasses",
            method: 'post',
            success: (glasses) => {
                promise(glasses);
            },
            error: () => {
                promise(null);
            }
        });
    }

    GetLastGlasses(promise) {
        $.ajax({
            url: HomeController.UrlApi + "Glasses/GetLastGlasses",
            method: 'post',
            success: (glasses) => {
                promise(glasses);
            },
            error: () => {
                promise(null);
            }
        });
    }

    GetNextGlasses(component, promise) {
        $.ajax({
            url: HomeController.UrlApi + "Glasses/GetNextGlasses",
            method: 'post',
            data: {
                Category: component.state.FilterIsActive ?
                    JSON.stringify(component.categoriesSelected) : null,
                Preloaded: component.state.Preloaded
            },
            success: (glasses) => {
                promise(glasses);
            },
            error: () => {
                promise(null);
            }
        });
    }

    GetGlassesById(idGlasses, promise) {
        $.ajax({
            url: HomeController.UrlApi + "Glasses/GetGlassesById",
            method: 'post',
            data: {
                IdGlasses: idGlasses
            },
            success: (glasses) => {
                promise(glasses);
            },
            error: () => {
                promise(null);
            }
        });
    }

    GetGlassesByCategory(categoriesSelected, promise) {
        $.ajax({
            url: HomeController.UrlApi + "Glasses/GetGlassesByCategory",
            method: 'post',
            data: {
                Category: JSON.stringify(categoriesSelected)
            },
            success: (glasses) => {
                promise(glasses);
            },
            error: () => {
                promise(null);
            }
        });
    }

    AddUpdateGlasses(component, promise) {
        $.ajax({
            url: HomeController.UrlApi + "Glasses/AddUpdateGlasses",
            method: 'post',
            data: that.SetAjaxGlasses(component),
            success: () => {
                promise(true);
            },
            error: () => {
                promise(null);
            }
        });
    }

    DeleteGlasses(idGlasses, promise) {
        $.ajax({
            url: HomeController.UrlApi + "Glasses/DeleteGlasses",
            method: 'post',
            data: {
                IdGlasses: idGlasses
            },
            success: () => {
                promise(true);
            },
            error: () => {
                promise(null);
            }
        });
    }

    SetAjaxGlasses(component) {
        return {
            Type: !component.state.IdGlasses ? "Add" : "Update",
            Glasses: JSON.stringify(that.SetGlasses(component)),
            Category: JSON.stringify(component.categoriesSelected),
            GlassesPicture: component.state.Picture,
            GlassesPictureExtension: component.state.PictureExtension
        };
    }

    CheckGlasses(component) {
        var error = null;

        if (!error && !component.state.IdGlasses && !component.state.Picture) {
            error = 'Oops, où est passé la photo ?';
        }

        if (!error && component.categoriesSelected.length === 0) {
            error = 'Oops, où est passé la catégorie ?';
        }

        if (!error && !component.state.Title) {
            error = 'Oops, où est passé le titre ?';
        }

        return error;
    }

    SetGlasses(component) {
        if (!component.state.IdGlasses) {
            return {
                Title: component.state.Title,
                PicturePath: component.state.PicturePath,
            };
        }
        else {
            return {
                Id: component.state.IdGlasses,
                Title: component.state.Title,
                PicturePath: !component.state.Picture ? component.props.Glasses.PicturePath : component.state.PicturePath,
                DateTimePost: component.props.Glasses.DateTimePost,
            };
        }
    }

    GetMatchParams(component) {
        if (component.props.match &&
            component.props.match.params &&
            component.props.match.params.glassesId) {
            return component.props.match.params.glassesId
        }

        return null;
    }
}

GlassesController.displayName = 'GlassesController';

export default (new GlassesController());