import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import HomeStyles from './Styles/HomeStyles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import InfoIcon from 'mdi-material-ui/Information';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { sliderSettings } from '../Tools/SliderSettingsTool';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import './Styles/vertical-timeline.css';
import { Link, Element } from 'react-scroll';
import HomeIcon from 'mdi-material-ui/Home';
import StoreIcon from 'mdi-material-ui/Store';
import defaultBanner from './Images/home.jpg';
import { formatPicture } from '../Tools/ImageTool';
import HomeController from '../Controllers/HomeController';
import CardHelper from '../Helpers/CardHelper';
import mapboxgl from 'mapbox-gl/dist/mapbox-gl.js';
import GlassesController from '../Controllers/GlassesController';
import PresentationsController from '../Controllers/PresentationsController';
import Grid from '@material-ui/core/Grid';
import { IconButton } from '@material-ui/core';
import Pencil from 'mdi-material-ui/PencilOutline';
import Delete from 'mdi-material-ui/DeleteOutline';
import Close from 'mdi-material-ui/Close';
import Plus from 'mdi-material-ui/Plus';
import Popover from '@material-ui/core/Popover';
import IconPhotoCameraOutlined from 'mdi-material-ui/CameraOutline';
import ChevronUp from 'mdi-material-ui/ChevronUp';
import SnackbarHelper from '../Helpers/SnackbarHelper';
import ChevronDown from 'mdi-material-ui/ChevronDown';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import OpeningTimeController from '../Controllers/OpeningTimeController';
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import TextField from '@material-ui/core/TextField';
import { MuiPickersUtilsProvider, DatePicker, TimePicker } from "material-ui-pickers";
import MomentUtils from '@date-io/moment';
import $ from 'jquery';
import WelcomePictureController from '../Controllers/WelcomePictureController';
import AlertWelcomeController from '../Controllers/AlertWelcomeController';
import moment from 'moment';
import { getWSService } from '../Controllers/WebSocketService';
import "moment/locale/fr";
moment.locale('fr');
require('mapbox-gl/dist/mapbox-gl.css');


var map;
var marker;
let that;
var inputFilePicture;
var fileSize = 640;

class Home extends Component {
	constructor(props) {
		super(props);

		this.socketConnection = null;

		this.state = {
			WelcomePicture: null,
			WelcomePicturePath: null,
			WelcomePictureThumbnail: null,
			WelcomePictureExtension: null,
			Glasses: [],
			Presentations: [],
			OpeningTimes: [],
			DeletePresentations: null,
			ShowOpeningTime: false,
			UpdateOpeningTime: null,
			AnchorEl: null,
			HomeAlert: null,
			AlertMessage: '',
			AlertStartDate: null,
			AlertEndDate: null,
			DialogHomePictureIsOpen: false,
			DialogHomeAlertIsOpen: false,
			SnackbarType: null,
			SnackbarMessage: null
		};
		that = this;
	}

	componentWillMount() {
		this.props.parent.handleLoadMainLoader(true);
	}

	componentDidMount() {
		this.handleLoadWelcomePicture();
		this.handleLoadGlasses();
		this.handleLoadOpeningTime();
		this.handleLoadPresentations();

		$("#choosePicture").off();
		$(window).off();
		$("#choosePicture").on('change', this.handleChangePicture);
		$(window).on('resize', this.handleMakeASquare);
		this.handleMakeASquare();

		mapboxgl.accessToken = HomeController.MapboxglAccessToken;

		marker = {
			coordinates: [-2.504066, 47.283246],
			className: 'marker',
		};

		map = new mapboxgl.Map({
			container: 'map',
			style: 'mapbox://styles/mapbox/streets-v11',
			center: marker.coordinates,
			zoom: 12
		}).on('load', this.handleMapIsLoaded);

		if (HomeController.AccountDeleted) {
			this.setState({
				SnackbarMessage: "Votre compte à été supprimé",
				SnackbarType: 'success'
			});
		}

		this.socketConnection = getWSService();

		getWSService().addMessageListener("home",
			() => {
				this.handleLoadWelcomePicture();
				this.handleLoadGlasses();
				this.handleLoadOpeningTime();
				this.handleLoadPresentations();
				this.handleLoadHomeAlert();
			}
		);
	}

	handleLoadWelcomePicture = () => {
		WelcomePictureController.GetWelcomePicture((welcomePicture) => {
			that.setState({
				WelcomePicturePath: welcomePicture ? `${welcomePicture.PicturePath}?${Math.random()}` : defaultBanner,
				WelcomePictureThumbnail: welcomePicture ? `${welcomePicture.PicturePath}?${Math.random()}` : defaultBanner,
			});
		});
	}

	handleLoadHomeAlert = () => {
		AlertWelcomeController.GetAlertWelcome((alertWelcome) => {
			that.setState({
				HomeAlert: alertWelcome,
				AlertMessage: alertWelcome ? alertWelcome.Message : null,
				AlertStartDate: alertWelcome ? moment.utc(alertWelcome.StartDate) : null,
				AlertEndDate: alertWelcome ? moment.utc(alertWelcome.EndDate).subtract(1, "days") : null,
				DialogHomeAlertIsOpen: false,
			});
		});
	}

	handleLoadGlasses = () => {
		GlassesController.GetLastGlasses((glasses) => {
			that.setState({
				Glasses: glasses
			});
		});
	};

	handleFormatPicture(file, size) {
		formatPicture(file, size,
			(picture, thumbnail, extension, error) => {
				if (!error) {
					that.setState({
						WelcomePictureExtension: extension,
						WelcomePicture: picture,
						WelcomePictureThumbnail: thumbnail,
					});
				}
				else {
					this.setState({
						SnackbarMessage: error,
						SnackbarType: 'warning'
					});
				}
			});
	}

	handleMakeASquare = () => {
		$("#picturecontent").height($("#picturecontent").innerWidth());
	}

	handleChoosePicture = () => {
		$(inputFilePicture).off('change');
		inputFilePicture = null;
		$('#choosePicture').click();
		inputFilePicture = $('#choosePicture');
		$(inputFilePicture).on("change", function (e) {
			that.handleChangePicture(e);
		});
	}

	handleChangePicture = (event) => {
		this.handleFormatPicture(event.target.files[0], fileSize);
	}

	handleLoadPresentations = () => {
		PresentationsController.GetPresentations((presentations) => {
			that.setState({
				Presentations: presentations
			});

			this.props.parent.handleUnloadMainLoader();
		});
	}

	handleLoadOpeningTime = () => {
		OpeningTimeController.GetOpeningTime((openingTimes) => {
			that.setState({
				OpeningTimes: openingTimes
			});
			this.props.parent.handleUnloadMainLoader();
		});
	}

	handleMapIsLoaded = () => {
		map.scrollZoom.disable();
		map.addControl(new mapboxgl.NavigationControl());
		new mapboxgl.Marker()
			.setLngLat(marker.coordinates)
			.addTo(map);

		this.handleAfterMapIsLoaded();
	}

	handleAfterMapIsLoaded = () => {
		setTimeout(() => {
			this.handleLoadHomeAlert();
		}, 1000);
	}

	handleToogleDeleteDialog = (presentations) => {
		this.setState({
			DeletePresentations: presentations
		});
	}

	handleDeletePresentations = () => {
		PresentationsController.DeletePresentations(this.state.DeletePresentations.Id, (success) => {
			if (success) {
				getWSService().sendMessage("home",
					{
						promise: () => {
							this.handleLoadPresentations();
						}
					}
				);
			}

			this.setState({
				DeletePresentations: null
			});
		})
	}

	handleDateFocused = (name) => {
		if (!this.state[name]) {
			this.setState({
				[name]: moment.utc()
			});
		}
	}

	handleDateChange = (date, name) => {
		this.setState({
			[name]: date
		});
	}

	handleChangeTextField = (event) => {
		this.setState({
			[event.target.name]: event.target.value,
		});
	}

	handleUpdateOpeningTime = (updateOpeningTime) => {
		this.setState({
			UpdateOpeningTime: updateOpeningTime ? OpeningTimeController.SetOpeningTime(updateOpeningTime) : null
		});
	}

	handleShowOpeningTime = (value) => {
		this.setState({
			ShowOpeningTime: value
		});
	}

	handleSubmitUpdateOpeningTime = () => {
		this.props.parent.handleLoadMainLoader();
		var error = OpeningTimeController.CheckOpeningTime(this.state.UpdateOpeningTime);
		if (!error) {
			OpeningTimeController.UpdateOpeningTime(this.state.UpdateOpeningTime, (success) => {
				if (success) {
					this.setState({
						UpdateOpeningTime: null,
						SnackbarMessage: 'Horaires d\'ouverture mises à jour',
						SnackbarType: 'success'
					});

					getWSService().sendMessage("home",
						{
							promise: () => {
								this.handleLoadOpeningTime();
							}
						}
					);
				}
				else {
					this.setState({
						SnackbarMessage: 'Erreur lors de la mise à jour des horaires d\'ouverture',
						SnackbarType: 'error'
					});
					this.props.parent.handleUnloadMainLoader();
				}
			})
		}
		else {
			this.setState({
				SnackbarMessage: error,
				SnackbarType: 'error'
			});
		}
	}

	handleCloseSnackbar = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		this.setState({
			SnackbarMessage: null
		});
	};

	handleChangeOpeningTime = (e, type) => {
		var uot = this.state.UpdateOpeningTime;
		uot[type] = e ? e.utc().format('HH:mm') : null;
		this.setState({
			UpdateOpeningTime: uot
		})
	}

	handleMovePresentations = (presentations, type) => {
		PresentationsController.MovePresentations(presentations, type, (success) => {
			if (success) {
				getWSService().sendMessage("home",
					{
						promise: () => {
							this.handleLoadPresentations();
						}
					}
				);
			}
		})
	}

	handleUpdateHomePicture = (value) => {
		this.setState({
			DialogHomePictureIsOpen: value,
			AnchorEl: null
		});
	}

	handleUpdateHomeAlert = (value) => {
		this.setState({
			DialogHomeAlertIsOpen: value,
			AnchorEl: null
		});
	}

	handleUpdateAnchorEl = (e) => {
		this.setState({
			AnchorEl: e
		});
	}

	handleSubmitChangePicture = () => {
		if (this.state.WelcomePicture) {
			this.props.parent.handleLoadMainLoader();
			WelcomePictureController.SaveWelcomePicture(this, (welcomePicture) => {
				getWSService().sendMessage("home",
					{
						promise: () => {
							that.setState({
								WelcomePicture: null,
								WelcomePicturePath: welcomePicture ? `${welcomePicture.PicturePath}?${Math.random()}` : defaultBanner,
								WelcomePictureThumbnail: welcomePicture ? `${welcomePicture.PicturePath}?${Math.random()}` : defaultBanner,
								DialogHomePictureIsOpen: false,
							});
							this.props.parent.handleUnloadMainLoader();
						}
					}
				);
			})
		}
		else {
			that.setState({
				WelcomePicture: null,
				DialogHomePictureIsOpen: false,
			});
		}
	}


	handleCloseSnackBarHomeAlert = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		this.setState({
			HomeAlert: null
		});
	}

	handleSubmitChangeAlert = (isdelete) => {
		var error = isdelete ? null : AlertWelcomeController.CheckAlertWelcome(this);

		if (!error) {
			this.props.parent.handleLoadMainLoader();
			AlertWelcomeController.UpdateAlertWelcome(this, isdelete, (alertWelcome) => {
				if (alertWelcome === 'error') {
					this.setState({
						SnackbarMessage: `Erreur lors de ${isdelete ? 'la suppression' : 'l\'enregistrement'} du message d'acceuil`,
						SnackbarType: 'error'
					});
				}
				else {
					getWSService().sendMessage("home",
						{
							promise: () => {
								that.setState({
									HomeAlert: alertWelcome,
									AlertMessage: alertWelcome ? alertWelcome.Message : null,
									AlertStartDate: alertWelcome ? moment.utc(alertWelcome.StartDate) : null,
									AlertEndDate: alertWelcome ? moment.utc(alertWelcome.EndDate).subtract(1, "days") : null,
									DialogHomeAlertIsOpen: false,
									SnackbarMessage: `Le message d'acceuil est ${isdelete ? 'supprimé' : 'enregistré'}`,
									SnackbarType: 'success'
								});
							}
						}
					);
				}
				this.props.parent.handleUnloadMainLoader();
			})
		}
		else {
			this.setState({
				SnackbarMessage: error,
				SnackbarType: 'error'
			});
		}
	}

	render() {
		const { classes } = this.props;

		return (
			<div>
				<SnackbarHelper
					type={this.state.SnackbarType}
					message={this.state.SnackbarMessage}
					closeSnackbar={this.handleCloseSnackbar}
				/>
				<div
					className={classes.banner}
					style={{
						backgroundImage: 'url(' + this.state.WelcomePicturePath + ')'
					}}
				>
					{
						this.state.HomeAlert &&
						this.state.HomeAlert.Message &&
						<Snackbar
							anchorOrigin={{
								vertical: 'top',
								horizontal: 'right',
							}}
							classes={{
								anchorOriginTopRight: classes.anchorOriginTopRight
							}}
							open={this.state.HomeAlert && this.state.HomeAlert.Message}
							onClose={this.handleCloseSnackBarHomeAlert}
						>
							<SnackbarContent
								className={classNames(classes.rootSnackBarContent, classes.info)}
								message={
									<span className={classes.message}>
										<InfoIcon className={classNames(classes.icon, classes.iconVariant)} />
										<div className={classes.snackBarText}>{this.state.HomeAlert.Message}</div>
									</span>
								}
								action={[
									<IconButton
										key="close"
										color="inherit"
										onClick={this.handleCloseSnackBarHomeAlert}
									>
										<Close
											className={classes.icon}
										/>
									</IconButton>,
								]}
							/>
						</Snackbar>
					}
					<Paper className={classNames(classes.paperBanner,
						{ [classes.largePadding]: !this.state.ShowOpeningTime },
						{ [classes.smallPadding]: this.state.ShowOpeningTime },
						{ [classes.paperBannerMaxWidth]: this.state.ShowOpeningTime })} elevation={12}>
						<Collapse in={!this.state.ShowOpeningTime} className={classNames({ [classes.noOpacity]: this.state.ShowOpeningTime })}>
							<Typography variant="h4" component="h1" className={classes.paperBannerTitle}>
								OPTIC SALOMON
							</Typography>
							<Typography variant="h5" component="h2" className={classes.whiteColor}>
								57 Avenue Henri Becquerel
							</Typography>
							<Typography variant="h5" component="h2" className={classes.whiteColor}>
								44490 Le Croisic
							</Typography>
							<Grid justify='center' container spacing={0}>
								<Grid xs={12} item className={classes.textAlignRight}>
									<Button
										className={classNames(classes.showOpeningTime, classes.whiteColor)}
										onClick={() => this.handleShowOpeningTime(true)}
									>
										voir les horaires
									</Button>
								</Grid>
							</Grid>
						</Collapse>
						<Collapse
							in={this.state.ShowOpeningTime}
							className={classNames({ [classes.noOpacity]: !this.state.ShowOpeningTime })}
						>
							<Grid justify='center' container spacing={0}>
								<Grid xs={12} item className={classes.textAlignLeft}>
									<List dense={true} className={classes.notShowOpeningTime}>
										<ListItem>
											<ListItemText
												classes={{
													textDense: classNames(classes.whiteColor, classes.labelTitleOpeningTime)
												}}
												primary={'LES HORAIRES'}
											/>
											<ListItemSecondaryAction>
												<IconButton
													className={classes.whiteColor}
													onClick={() => this.handleShowOpeningTime(false)}
												>
													<Close />
												</IconButton>
											</ListItemSecondaryAction>
										</ListItem>
									</List>
								</Grid>
							</Grid>
							<List dense={true} className={classes.listOpeningTime}>
								{
									this.state.OpeningTimes && this.state.OpeningTimes.map((o, i) => (
										<ListItem key={i}>
											<ListItemAvatar>
												<Avatar>
													{o.Day.substring(0, 1).toUpperCase()}
												</Avatar>
											</ListItemAvatar>
											<ListItemText
												classes={{
													textDense: classNames(classes.whiteColor, classes.labelOpeningTime)
												}}
												primary={o.StartTimeMorning && o.EndTimeMorning ? o.StartTimeMorning + ' - ' + o.EndTimeMorning : 'fermé'}
												secondary={!o.StartTimeAfternoon && !o.EndTimeAfternoon && !o.StartTimeMorning && !o.EndTimeMorning ? "" :
													o.StartTimeAfternoon && o.EndTimeAfternoon ? o.StartTimeAfternoon + ' - ' + o.EndTimeAfternoon : 'fermé'}
											/>
											{
												this.props.parent.state.User &&
												this.props.parent.state.User.IsAdministrator &&
												<ListItemSecondaryAction>
													<IconButton
														className={classes.whiteColor}
														onClick={() => this.handleUpdateOpeningTime(o)}
													>
														<Pencil />
													</IconButton>
												</ListItemSecondaryAction>
											}
										</ListItem>
									))
								}
							</List>
							{
								this.props.parent.state.User &&
								this.props.parent.state.User.IsAdministrator &&
								this.state.UpdateOpeningTime &&
								<Dialog
									open={Boolean(this.state.UpdateOpeningTime)}
									onClose={() => this.handleUpdateOpeningTime(null)}
									fullWidth={true}
									maxWidth="sm"
								>
									<DialogTitle>
										{this.state.UpdateOpeningTime.Day}
									</DialogTitle>
									<DialogContent>
										<Grid justify='center' container spacing={0}>
											<Grid xs={12} item className={classes.textAlignCenter}>
												<Typography
													component="p"
													className={classes.subTitle}
												>
													matinée
													</Typography>
											</Grid>
										</Grid>
										<Grid justify='center' container spacing={0}>
											<Grid xs={12} sm={6} item className={classes.textAlignCenter}>
												<MuiPickersUtilsProvider utils={MomentUtils} locale="fr">
													<TimePicker
														clearable
														clearLabel="Effacer"
														cancelLabel="Annuler"
														ampm={false}
														label="Ouverture"
														value={this.state.UpdateOpeningTime.StartTimeMorning ?
															moment.utc(this.state.UpdateOpeningTime.StartTimeMorning, 'HH:mm') : null}
														onChange={e => this.handleChangeOpeningTime(e, 'StartTimeMorning')}
														variant="outlined"
														className={classes.timepicker}
													/>
												</MuiPickersUtilsProvider>
											</Grid>
											<Grid xs={12} sm={6} item className={classes.textAlignCenter}>
												<MuiPickersUtilsProvider utils={MomentUtils} locale="fr">
													<TimePicker
														clearable
														clearLabel="Effacer"
														cancelLabel="Annuler"
														ampm={false}
														label="Fermeture"
														value={this.state.UpdateOpeningTime.EndTimeMorning ?
															moment.utc(this.state.UpdateOpeningTime.EndTimeMorning, 'HH:mm') : null}
														onChange={e => this.handleChangeOpeningTime(e, 'EndTimeMorning')}
														variant="outlined"
														className={classes.timepicker}
													/>
												</MuiPickersUtilsProvider>
											</Grid>
										</Grid>
										<Grid justify='center' container spacing={0}>
											<Grid xs={12} item className={classes.textAlignCenter}>
												<Typography
													component="p"
													className={classes.subTitle}
												>
													après-midi
													</Typography>
											</Grid>
										</Grid>
										<Grid justify='center' container spacing={0}>
											<Grid xs={12} sm={6} item className={classes.textAlignCenter}>
												<MuiPickersUtilsProvider utils={MomentUtils} locale="fr">
													<TimePicker
														clearable
														clearLabel="Effacer"
														cancelLabel="Annuler"
														ampm={false}
														label="Ouverture"
														value={this.state.UpdateOpeningTime.StartTimeAfternoon ?
															moment.utc(this.state.UpdateOpeningTime.StartTimeAfternoon, 'HH:mm') : null}
														onChange={e => this.handleChangeOpeningTime(e, 'StartTimeAfternoon')}
														variant="outlined"
														className={classes.timepicker}
													/>
												</MuiPickersUtilsProvider>
											</Grid>
											<Grid xs={12} sm={6} item className={classes.textAlignCenter}>
												<MuiPickersUtilsProvider utils={MomentUtils} locale="fr">
													<TimePicker
														clearable
														clearLabel="Effacer"
														cancelLabel="Annuler"
														ampm={false}
														label="Fermeture"
														value={this.state.UpdateOpeningTime.EndTimeAfternoon ?
															moment.utc(this.state.UpdateOpeningTime.EndTimeAfternoon, 'HH:mm') : null}
														onChange={e => this.handleChangeOpeningTime(e, 'EndTimeAfternoon')}
														variant="outlined"
														className={classes.timepicker}
													/>
												</MuiPickersUtilsProvider>
											</Grid>
										</Grid>
										<Grid justify='center' container spacing={0}>
											<Grid xs={12} sm={6} item />
											<Grid xs={12} sm={6} item className={classes.textAlignCenter}>
												<div className={classNames(classes.submitButtonContent)}>
													<Button
														variant="contained"
														tabIndex="-1"
														color="primary"
														onClick={this.handleSubmitUpdateOpeningTime}
														className={classNames(classes.submitButton)}
													>
														Valider
													</Button>
												</div>
											</Grid>
										</Grid>
									</DialogContent>
								</Dialog>
							}
						</Collapse>
					</Paper>
					<Link activeClass="active" to="slider" spy={true} smooth={true} duration={500}>
						<Fab variant="extended" color="secondary" className={classes.paperBannerButton}>
							EN SAVOIR PLUS
						</Fab>
					</Link>
					{
						this.props.parent.state.User &&
						this.props.parent.state.User.IsAdministrator &&
						<div>
							<Popover
								open={Boolean(this.state.AnchorEl)}
								anchorEl={this.state.AnchorEl}
								onClose={() => {
									this.handleUpdateAnchorEl(null);
								}}
								anchorOrigin={{
									vertical: 'top',
									horizontal: 'left',
								}}
								transformOrigin={{
									vertical: 'bottom',
									horizontal: 'left',
								}}
							>
								<List>
									<ListItem button onClick={() => this.handleUpdateHomePicture(true)}>
										<ListItemText
											primary="Modifier l'image d'accueil"
										/>
									</ListItem>
									<ListItem button onClick={() => this.handleUpdateHomeAlert(true)}>
										<ListItemText
											primary="Modifier le message d'accueil"
										/>
									</ListItem>
								</List>
							</Popover>
							<Button
								variant="contained"
								color="primary"
								size="large"
								className={classes.editHomeButton}
								onClick={event => {
									this.handleUpdateAnchorEl(event.currentTarget);
								}}
							>
								<Pencil />
							</Button>
							<Dialog
								open={this.state.DialogHomePictureIsOpen}
								onClose={() => this.handleUpdateHomePicture(false)}
								fullWidth={true}
								maxWidth="sm"
								onEntered={this.handleMakeASquare}
							>
								<DialogTitle>
									<div>
										Modifier l'image d'accueil
									</div>
								</DialogTitle>
								<DialogContent>
									<Grid justify='center' container spacing={0} className={classes.subContainer}>
										<Grid xs={12} item className={classes.textAlignCenter}>
											<input id="choosePicture" className={classes.uploadInput} type="file" accept="image/*" />
											<div
												id="picturecontent"
												className={classNames(classes.pictureContent)}
												style={this.state.WelcomePictureThumbnail && { backgroundImage: "url('" + this.state.WelcomePictureThumbnail + "')" }}
												onClick={event => this.handleChoosePicture(event, 'choosePicture')}
											>
												{!this.state.WelcomePictureThumbnail &&
													<div>
														<IconPhotoCameraOutlined className={classes.cameraIcon} />
														<Typography
															component="p"
															className={classes.pictureText}
														>
															Cliquer pour ajouter ou modifier une photo
													</Typography>
													</div>
												}
											</div>
										</Grid>
									</Grid>
									<Grid justify='center' container spacing={0}>
										<Grid xs={12} item className={classes.textAlignCenter}>
											<div className={classNames(classes.submitButtonContent)}>
												<Button
													variant="contained"
													tabIndex="-1"
													color="primary"
													onClick={this.handleSubmitChangePicture}
													className={classNames(classes.submitButton)}
												>
													Valider
											</Button>
											</div>
										</Grid>
									</Grid>
								</DialogContent>
							</Dialog>
							<Dialog
								open={this.state.DialogHomeAlertIsOpen}
								onClose={() => this.handleUpdateHomeAlert(false)}
								fullWidth={true}
								maxWidth="sm"
								classes={{
									paper: classes.nooverflowy
								}}
							>
								<DialogTitle>
									<div>
										Modifier le message d'accueil
									</div>
								</DialogTitle>
								<DialogContent className={classes.nooverflowy}>
									<Grid justify='center' container spacing={0}>
										<Grid xs={12} item className={classes.textAlignCenter}>
											<TextField
												variant="outlined"
												margin="normal"
												label="Message"
												type="text"
												rowsMax="5"
												multiline={true}
												value={this.state.AlertMessage}
												onChange={this.handleChangeTextField}
												name="AlertMessage"
												className={classes.textFieldWidth}
											/>
										</Grid>
									</Grid>
									<Grid justify='center' container spacing={0}>
										<Grid xs={12} item className={classes.textAlignCenter}>
											<MuiPickersUtilsProvider utils={MomentUtils} locale="fr">
												<DatePicker
													className={classes.datepicker}
													label="Date de début"
													cancelLabel="Annuler"
													variant="outlined"
													value={this.state.AlertStartDate}
													openTo="day"
													format="DD/MM/YYYY"
													orientation="landscape"
													onFocus={() => this.handleDateFocused('AlertStartDate')}
													views={["year", "month", "day"]}
													onChange={(date) => this.handleDateChange(date, 'AlertStartDate')}
												/>
											</MuiPickersUtilsProvider>
										</Grid>
									</Grid>
									<Grid justify='center' container spacing={0}>
										<Grid xs={12} item className={classes.textAlignCenter}>
											<MuiPickersUtilsProvider utils={MomentUtils} locale="fr">
												<DatePicker
													className={classes.datepicker}
													label="Date de fin"
													variant="outlined"
													cancelLabel="Annuler"
													value={this.state.AlertEndDate}
													openTo="day"
													orientation="landscape"
													format="DD/MM/YYYY"
													onFocus={() => this.handleDateFocused('AlertEndDate')}
													views={["year", "month", "day"]}
													onChange={(date) => this.handleDateChange(date, 'AlertEndDate')}
												/>
											</MuiPickersUtilsProvider>
										</Grid>
									</Grid>
									<Grid justify='center' container spacing={0}>
										<Grid xs={12} item className={classes.textAlignCenter}>
											<div className={classNames(classes.submitButtonContent)}>
												<Button
													variant="contained"
													tabIndex="-1"
													color="secondary"
													onClick={() => this.handleSubmitChangeAlert(true)}
													className={classNames(classes.submitButtonLeft)}
												>
													Supprimer
												</Button>
												<Button
													variant="contained"
													tabIndex="-1"
													color="primary"
													onClick={() => this.handleSubmitChangeAlert(false)}
													className={classNames(classes.submitButton)}
												>
													Valider
												</Button>
											</div>
										</Grid>
									</Grid>
								</DialogContent>
							</Dialog>
						</div>
					}
				</div>
				<Element name="slider" />
				<Paper
					className={classes.homepaper}
					elevation={12}
				>
					<div className={classes.showCollection}>
						<Fab
							variant="extended"
							color="secondary"
							onClick={() => this.props.parent.handleMoveTo('/collection')}
						>
							VOIR TOUTE LA COLLECTION
						</Fab>
					</div>
					<Slider {...sliderSettings} className={classes.rootSlider}>
						{that.state.Glasses && that.state.Glasses.map((o, i) => (
							<CardHelper
								key={i}
								title={o.Title}
								glasses={o}
								image={o.PicturePath + "?" + i}
								elevation={1}
							/>
						))}
					</Slider>
				</Paper>
				<Paper
					className={classes.homepaper}
					elevation={12}
				>
					<VerticalTimeline className={classes.rootVerticalTimeline}>
						{this.state.Presentations && this.state.Presentations.map((o, i) => {

							const iconVerticalTimeline = {
								backgroundRepeat: 'no-repeat',
								backgroundPosition: 'center',
								backgroundSize: 'cover',
								backgroundColor: '#fff',
								backgroundImage: 'url("' + o.PicturePath + "?" + i + '")'
							}

							return (
								<VerticalTimelineElement
									className="vertical-timeline-element--work"
									iconStyle={iconVerticalTimeline}
									key={i}
								>
									{
										this.props.parent.state.User &&
										this.props.parent.state.User.IsAdministrator &&
										<Grid justify='center' container spacing={0}>
											<Grid xs={12} item className={classes.textAlignRight}>
												{
													o.Position !== 0 &&
													<IconButton
														onClick={() => this.handleMovePresentations(o, "Up")}
													>
														<ChevronUp />
													</IconButton>
												}
												{
													this.state.Presentations.length - 1 !== o.Position &&
													<IconButton
														onClick={() => this.handleMovePresentations(o, "Down")}
													>
														<ChevronDown />
													</IconButton>
												}
												<IconButton onClick={() => this.props.parent.handleMoveTo('/updatepresentations/' + o.Id)}>
													<Pencil />
												</IconButton>
												<IconButton color="secondary" onClick={() => this.handleToogleDeleteDialog(o)}>
													<Delete />
												</IconButton>
											</Grid>
										</Grid>
									}
									<Typography component="h3" variant="h6" gutterBottom>
										{o.Title}
									</Typography>
									<Typography
										component="h4"
										variant="subtitle1"
										className={classes.paragraphVerticalTimeline}
									>
										{o.Description}
									</Typography>
									{o.Home && <HomeIcon color="primary" className={classes.iconVerticalTimeline} />}
									{o.Store && <StoreIcon color="primary" className={classes.iconVerticalTimeline} />}
								</VerticalTimelineElement>
							);
						})}
					</VerticalTimeline>
					{
						this.props.parent.state.User &&
						this.props.parent.state.User.IsAdministrator &&
						<Grid justify='center' container spacing={0}>
							<Grid xs={12} item className={classes.textAlignRight}>
								<Fab
									color="secondary"
									className={classes.addPresentations}
									onClick={() => this.props.parent.handleMoveTo('addpresentations')}
								>
									<Plus />
								</Fab>
								<Dialog
									open={Boolean(this.state.DeletePresentations)}
									onClose={() => this.handleToogleDeleteDialog(null)}
								>
									<DialogTitle>Suppression</DialogTitle>
									<DialogContent>
										<DialogContentText>
											Voulez-vous vraiment supprimer cette présentation ?
            							</DialogContentText>
									</DialogContent>
									<DialogActions>
										<Button onClick={() => this.handleToogleDeleteDialog(null)} color="default">
											Non
										</Button>
										<Button onClick={() => this.handleDeletePresentations()} color="primary">
											Oui
										</Button>
									</DialogActions>
								</Dialog>
							</Grid>
						</Grid>
					}
				</Paper>
				<Paper
					className={classes.homepaper}
					elevation={12}
				>
					<div id="map" className={classes.map}></div>
				</Paper>
				<Paper square className={classes.footer}>
					<Typography variant="h5" component="p" className={classes.paperBannerTitle}>
						OPTIC SALOMON
					</Typography>
					<Typography variant="h6" component="p" className={classes.whiteColor}>
						57 Avenue Henri Becquerel
					</Typography>
					<Typography variant="h6" component="p" className={classes.whiteColor}>
						44490 Le Croisic
					</Typography>
				</Paper>
			</div>
		)
	}
}

Home.displayName = 'Home';

Home.propTypes = {
	classes: PropTypes.object.isRequired,
};

Home.contextTypes = {
	router: PropTypes.object.isRequired,
};


export default withStyles(HomeStyles)(Home);