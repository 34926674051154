import { textAlignCenter, justifyContentCenter, textAlignRight } from '../_MainStyles';

const ProfilStyles = theme => ({
	container: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	textField: {
		marginLeft: theme.spacing.unit,
		marginRight: theme.spacing.unit,
	},
	dense: {
		marginTop: 16,
	},
	menu: {
		width: 200,
	},
	root: {
		flexGrow: 1,
	},
	demo: {
		height: 240,
	},
	control: {
		padding: theme.spacing.unit * 2,
	},
	paper: {
		width: '80%',
		minWidth: 256,
		maxWidth: 300,
		padding: '30px 20px',
		margin: '80px auto 80px',
		[theme.breakpoints.up('sm')]: {
			width: '80%',
			minWidth: 556,
			maxWidth: 600,
		},
	},
	paperWithButtonLens: {
		margin: '80px auto 80px',
	},
	spaceBetweenGrid: {
		marginBottom: 20,
	},
	textAlignCenter: {
		...textAlignCenter
	},
	textAlignRight: {
		...textAlignRight
	},
	justifyContentCenter: {
		...justifyContentCenter
	},
	lensRenewalContent: {
		...textAlignRight,
		...theme.mixins.gutters(),
		marginTop: 30,
		marginBottom: 40
	},
	passwordDelete: {
		marginTop: 30
	}
});

export default ProfilStyles