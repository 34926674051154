import $ from 'jquery';
import { Component } from 'react';
import HomeController from './HomeController';

let that;

class WelcomePictureController extends Component {
    constructor(props) {
        super(props);
        that = this;
    }

    GetWelcomePicture(promise) {
        $.ajax({
            url: HomeController.UrlApi + "WelcomePicture/GetWelcomePicture",
            method: 'post',
            success: (welcomePicture) => {
                promise(welcomePicture);
            },
            error: () => {
                promise(null);
            }
        });
    }

    SaveWelcomePicture(component, promise) {
        $.ajax({
            url: HomeController.UrlApi + "WelcomePicture/UpdateWelcomePicture",
            method: 'post',
            data: that.SetAjaxWelcomePicture(component),
            success: (welcomePicture) => {
                promise(welcomePicture);
            },
            error: () => {
                promise(null);
            }
        });
    }

    SetAjaxWelcomePicture(component) {
        return {
            WelcomePicture: component.state.WelcomePicture,
            WelcomePictureExtension: component.state.WelcomePictureExtension
        };
    }
}

WelcomePictureController.displayName = 'WelcomePictureController';

export default (new WelcomePictureController());