import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CollectionStyles from './Styles/CollectionStyles';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Popover from '@material-ui/core/Popover';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Switch from '@material-ui/core/Switch';
import Plus from 'mdi-material-ui/Plus';
import Information from 'mdi-material-ui/Information';
import CardHelper from '../Helpers/CardHelper';
import GlassesController from '../Controllers/GlassesController';
import Fab from '@material-ui/core/Fab';
import { Slide } from '@material-ui/core';
import { getWSService } from '../Controllers/WebSocketService';
import moment from 'moment';
import "moment/locale/fr";
moment.locale('fr');

let that;
const load = 12;


class Collection extends Component {
	constructor(props) {
		super(props);

		this.socketConnection = null;

		this.state = {
			DeleteGlasses: null,
			Preloaded: 0,
			HaveNext: false,
			Glasses: [],
			FilterElement: null,
			Categories: [],
			CategoriesSelected: [],
			HaveLatest: false,
			UpdateKey: moment().utc().format("YYYYMMDD")
		};

		this.categoriesSelected = [];

		that = this;
	}

	componentDidMount() {
		this.props.parent.handleLoadMainLoader();
		this.handleLoad();

		this.socketConnection = getWSService();

		getWSService().addMessageListener("home", () => { });

		getWSService().addMessageListener("glasses",
			(data) => {
				console.log(data.msg)
				that.handleLoadUpdated(data.msg);
			}
		);
	}

	handleLoad = () => {
		GlassesController.GetCategories((categories) => {
			if (categories) {
				that.setState({
					Categories: categories
				});
			}
		});

		GlassesController.GetLastGlasses((glasses) => {
			if (glasses) {
				that.setState({
					Glasses: glasses,
					Preloaded: glasses.length,
					HaveNext: glasses.length % load === 0 &&
						glasses.length !== 0
				});
			}

			this.props.parent.handleUnloadMainLoader();
		});
	}

	handleLoadUpdated = (idGlasses) => {
		if (!idGlasses) {
			GlassesController.GetLatestGlasses((glasses) => {
				if (glasses && !that.state.Glasses.find(f => f.Id === glasses[0].Id)) {
					var tGlasses = [...glasses, ...that.state.Glasses];

					that.setState({
						Glasses: tGlasses,
						Preloaded: tGlasses.length,
						HaveLatest: window.scrollY >= 300
					});
				}
			})
		}
		else {
			GlassesController.GetGlassesById(idGlasses, (glasses) => {
				var tGlasses = this.state.Glasses;
				var index = tGlasses.findIndex(fi => fi.Id === idGlasses);
				var keep = glasses && (this.categoriesSelected.length === 0 || this.categoriesSelected.some(r => tGlasses.includes(r.Categories)));

				if (keep) {
					tGlasses.splice(index, 1, glasses);
				}
				else {
					tGlasses.splice(index, 1);
				}

				that.setState({
					Glasses: tGlasses,
					Preloaded: tGlasses.length,
				});
			})
		}
	}

	handleFilterChange = (category) => {
		this.categoriesSelected = this.state.CategoriesSelected;
		var currentCategory = this.categoriesSelected.find(f => f.Name === category.Name);

		if (currentCategory) {
			this.categoriesSelected = this.categoriesSelected.filter(f => f.Id !== currentCategory.Id);
		}
		else {
			this.categoriesSelected.push(category);
		}

		this.setState({
			CategoriesSelected: this.categoriesSelected,
		});

		this.handleLoadData();
	};

	handleLoadData = () => {
		if (this.categoriesSelected.length === 0) {
			this.handleLoad();
		}
		else {
			GlassesController.GetGlassesByCategory(this.categoriesSelected, (glasses) => {
				if (glasses) {
					that.setState({
						Glasses: glasses,
						Preloaded: 0,
						HaveNext: glasses.length % load === 0 &&
							glasses.length !== 0
					});
				}
			})
		}
	}

	handleToogleFilter = el => {
		this.setState({
			FilterElement: el,
		});
	}

	handleRemoveAllFilters = () => {
		this.setState({
			CategoriesSelected: [],
		});

		this.handleLoad();
	}

	handleShowLatest = () => {
		that.setState({
			CategoriesSelected: [],
			HaveLatest: false
		});

		window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
	}

	handleLoadNext = () => {
		GlassesController.GetNextGlasses(this, (glasses) => {
			if (glasses) {
				var tGlasses = [...that.state.Glasses, ...glasses];

				that.setState({
					Glasses: tGlasses,
					Preloaded: tGlasses.length,
					HaveNext: tGlasses.length !== 0 &&
						tGlasses.length % load === 0 &&
						glasses.length !== 0
				});
			}
		})
	}

	handleDeleteGlasses = (glasses) => {
		this.props.parent.handleLoadMainLoader();
		GlassesController.DeleteGlasses(glasses.Id, (success) => {
			if (success) {
				getWSService().sendMessage("home");
				getWSService().sendMessage("glasses",
					{
						message: glasses.Id,
						promise: () => {
							this.handleLoadData();
						}
					});
			}
			this.props.parent.handleUnloadMainLoader();
		})
	}

	render() {
		const { classes } = this.props;

		return (
			<div>
				<Slide direction="down" in={this.state.HaveLatest} mountOnEnter unmountOnExit>
					<Fab
						variant="extended"
						size="large"
						color="primary"
						onClick={this.handleShowLatest}
						style={{
							position: 'fixed', top: 90,
							width: 256,
							left: `calc(50% - 128px)`
						}}
					>
						<Information className={classes.extendedIcon} />
						Nouvelles lunettes
					</Fab>
				</Slide>
				<div className={classes.filterContent}>
					<Button
						variant="contained"
						color="secondary"
						onClick={event => this.handleToogleFilter(event.currentTarget)}
					>
						Filtre
					</Button>
				</div>
				<Popover
					open={Boolean(this.state.FilterElement)}
					anchorEl={this.state.FilterElement}
					onClose={() => this.handleToogleFilter(null)}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'right',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'right',
					}}
				>
					<List
						subheader={
							<ListSubheader>
								Filtre
							<Typography
									component="p"
									className={classes.clearFilter}
									onClick={this.handleRemoveAllFilters}
								>
									effacer
							</Typography>
							</ListSubheader>
						}
						className={classes.rootFilter}
					>
						{
							this.state.Categories.map((o, i) => (
								<ListItem
									button
									onClick={() => that.handleFilterChange(o)}
									key={i}
								>
									<ListItemText primary={o.Label} />
									<ListItemSecondaryAction>
										<Switch
											onChange={() => that.handleFilterChange(o)}
											checked={Boolean(this.state.CategoriesSelected.find(f => f.Name === o.Name))}
										/>
									</ListItemSecondaryAction>
								</ListItem>
							))
						}
					</List>
				</Popover>
				<Grid container className={classes.grid} spacing={0}>
					{this.state.Glasses.map((o, i) => (
						<Grid key={i} item xs={12} md={6} lg={4} xl={3}>
							<CardHelper
								title={o.Title}
								description={o.Description}
								image={`${o.PicturePath}?${this.state.UpdateKey}`}
								parent={that.props.parent}
								glasses={o}
								elevation={12}
								handleDeleteGlasses={this.handleDeleteGlasses}
							/>
						</Grid>
					))}
				</Grid>
				{
					this.state.HaveNext &&
					<Grid container className={classes.grid} spacing={0}>
						<Grid item xs={12} className={classes.textAlignCenter}>
							<Fab
								variant="extended"
								color="secondary"
								className={classes.loadMoreButton}
								onClick={this.handleLoadNext}
							>
								Voir plus de lunettes
							</Fab>
						</Grid>
					</Grid>
				}
				{
					this.state.Glasses.length === 0 &&
					<Grid container className={classes.grid} spacing={0}>
						<Grid item xs={12} className={classes.textAlignCenter}>
							<Fab
								disabled
								variant="extended"
								color="secondary"
								className={classes.loadMoreButton}
							>
								Aucune lunettes trouvées
							</Fab>
						</Grid>
					</Grid>
				}
				{
					this.props.parent.state.User &&
					this.props.parent.state.User.IsAdministrator &&
					<Fab
						color="secondary"
						className={classes.addGlasses}
						onClick={() => this.props.parent.handleMoveTo('addglasses')}
					>
						<Plus />
					</Fab>
				}
			</div>
		);
	}
}

Collection.displayName = 'Collection';

Collection.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(CollectionStyles)(Collection);