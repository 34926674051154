import { Component } from 'react';
import HomeController from './HomeController';
import $ from 'jquery';

class MutualsController extends Component {
	GetAmcMutuals(promise) {
		$.ajax({
			method: 'POST',
			url: HomeController.UrlApi + 'Mutuals/GetAmcMutuals',
			success: (mutuals) => {
				promise(mutuals);
			},
			error: () => {
				promise(null);
			}
		});
	}
}


MutualsController.displayName = 'MutualsController';

export default new MutualsController();