import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Autosuggest from 'react-autosuggest';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import AutocompleteHelperStyles from '../Views/Styles/HelperStyles/AutocompleteHelperStyles';

let that;
class AutocompleteHelper extends Component {
  constructor(props) {
    super(props);

    this.state = {
      Suggestions: [],
      Search: '',
    };

    that = this;
}
  componentWillMount(){
    that.setState({
      Suggestions: that.props.suggestions
    });
  }

  componentWillReceiveProps(props){
    that.setState({
      Search: props.value,
    });
  }

  handleSuggestionsFetchRequested = ({ value }) => {
    that.setState({
      Suggestions: that.getSuggestions(value),
    });
  };

  handleSuggestionsClearRequested = () => {
    that.setState({
      Suggestions: that.props.suggestions,
    });
  };

  handleChange = (event, { newValue }) => {
      that.props.onChange(newValue);
  };

  getSuggestions(value) {
    return value.length === 0 || !that.props.suggestions || that.props.suggestions.length < 1
      ? []
      : that.props.suggestions.filter(suggestion => {
          return suggestion[that.props.suggestionsLabel].toLowerCase().includes(value.toLowerCase());
      });
  }

  shouldRenderSuggestions() {
    return that.props.shouldRenderSuggestions;
  }

  renderSuggestion(suggestion, { isHighlighted }) {
    return (
      <MenuItem selected={isHighlighted} component="div" title={suggestion[that.props.renderLabel]}>
        <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
          <strong style={{ fontWeight: 300 }}>
            {suggestion[that.props.renderLabel]}
          </strong>
        </div>
      </MenuItem>
    );
  }

  renderInputComponent(inputProps) {
    const { classes, inputRef = () => { }, ref, ...other } = inputProps;
  
    return (
      <TextField
        className={classes.textfield}
        onFocus={that.handleChange}
        variant="outlined"
        InputProps={{
          inputRef: node => {
            ref(node);
            inputRef(node);
          },
          classes: {
            input: classes.input,
          },
        }}
        {...other}
      />
    );
  }

  render() {
    const { classes } = that.props;

    const autosuggestProps = {
      shouldRenderSuggestions: that.shouldRenderSuggestions,
      renderInputComponent: that.renderInputComponent,
      suggestions: that.state.Suggestions,
      onSuggestionsFetchRequested: that.handleSuggestionsFetchRequested,
      onSuggestionsClearRequested: that.handleSuggestionsClearRequested,
      getSuggestionValue: that.props.getSuggestionValue,
      renderSuggestion: that.renderSuggestion,
    };

    return (
      <div className={classNames(classes.root, classes.textAlignCenter)}>
        <Autosuggest
          {...autosuggestProps}
          inputProps={{
            classes,
            name: that.props.name,
            label: that.props.label,
            value: that.props.value,
            onChange: that.handleChange,
            disabled: that.props.disabled
          }}
          theme={{
            container: classes.container,
            suggestionsContainerOpen: classes.suggestionsContainerOpen,
            suggestionsList: classes.suggestionsList,
            suggestion: classes.suggestion,
          }}
          renderSuggestionsContainer={options => (
            <Paper {...options.containerProps}>
              {options.children}
            </Paper>
          )}
        />
      </div>
    );
  }
}

AutocompleteHelper.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  suggestions: PropTypes.array.isRequired,
  suggestionsLabel: PropTypes.string.isRequired,
  renderLabel: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  shouldRenderSuggestions: PropTypes.bool.isRequired,
  getSuggestionValue: PropTypes.func.isRequired,
};

export default withStyles(AutocompleteHelperStyles)(AutocompleteHelper);