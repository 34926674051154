import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import withWidth from '@material-ui/core/withWidth';
import { withStyles } from '@material-ui/core/styles';
import AddUpdateStyles from '../Styles/AddUpdateStyles';
import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import GlassesController from '../../Controllers/GlassesController';
import IconPhotoCameraOutlined from 'mdi-material-ui/CameraOutline';
import $ from 'jquery';
import { formatPicture } from '../../Tools/ImageTool';
import BackPageHelper from '../../Helpers/BackPageHelper';
import SnackbarHelper from '../../Helpers/SnackbarHelper';
import { getWSService } from '../../Controllers/WebSocketService';

let that;
var inputFilePicture;
var fileSize = 640;

class AddUpdate extends Component {
    constructor(props) {
        super(props);
        this.categoriesSelected = [];
        this.state = this.handleInitStates();

        that = this;
    }

    componentDidMount() {
        this.props.parent.handleLoadMainLoader();
        GlassesController.GetCategories((categories) => {
            if (categories) {
                this.setState({
                    Categories: categories
                });
                this.props.parent.handleUnloadMainLoader();
            }
        });

        $(window).off();
        $(window).on('resize', this.handleMakeASquare);
        this.handleMakeASquare();
    }

    handleFormatPicture(file, size) {
        formatPicture(file, size,
            (picture, thumbnail, extension, error) => {
                if (!error) {
                    this.setState({
                        PictureExtension: extension,
                        Picture: picture,
                        Thumbnail: thumbnail,
                    });
                }
                else {
                    this.setState({
                        SnackbarMessage: error,
                        SnackbarType: 'warning'
                    });
                }
            });
    }

    handleInitStates() {
        var isAnUpdate = Boolean(this.props.Glasses);

        return {
            IdGlasses: isAnUpdate ? this.props.Glasses.Id : null,
            Title: isAnUpdate ? this.props.Glasses.Title : '',
            DateTimePost: isAnUpdate ? this.props.Glasses.DateTimePost : null,
            PicturePath: isAnUpdate ? this.props.Glasses.PicturePath : null,
            Picture: null,
            PictureExtension: isAnUpdate ? this.props.Glasses.PicturePath.split('.').pop().toLowerCase() : null,
            Thumbnail: isAnUpdate ? this.props.Glasses.PicturePath : null,
            Categories: [],
            CategoriesSelected: isAnUpdate ? this.handleInitCategoriesSelected() : [],
            SnackbarType: null,
            SnackbarMessage: null
        };
    }


    handleInitCategoriesSelected = () => {
        // eslint-disable-next-line
        this.props.Glasses.Categories.map((f) => {
            this.categoriesSelected.push(f);
        });

        return this.categoriesSelected;
    }

    handleChoosePicture = () => {
        $(inputFilePicture).val('');
        $(inputFilePicture).off('change');
        inputFilePicture = null;
        $('#choosePicture').click();
        inputFilePicture = $('#choosePicture');
        $(inputFilePicture).on("change", function (e) {
            that.handleChangePicture(e);
        });
    }

    handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({
            SnackbarMessage: null
        });
    };

    handleChangePicture = (event) => {
        this.handleFormatPicture(event.target.files[0], fileSize);
    }


    handleChangeTextField = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    }

    handleChangeFilter = (category) => {
        this.categoriesSelected = this.state.CategoriesSelected;
        var currentCategory = this.categoriesSelected.find(f => f.Name === category.Name);

        if (currentCategory) {
            this.categoriesSelected = this.categoriesSelected.filter(f => f.Id !== currentCategory.Id);
        }
        else {
            this.categoriesSelected.push(category);
        }

        this.setState({
            CategoriesSelected: this.categoriesSelected,
        });
    }

    handleSubmit = () => {
        var error = GlassesController.CheckGlasses(this);

        if (!error) {
            this.props.parent.handleLoadMainLoader();
            GlassesController.AddUpdateGlasses(this, (success) => {
                if (success) {
                    getWSService().sendMessage("home",
                        {
                            promise: getWSService().sendMessage("glasses",
                                {
                                    message: this.state.IdGlasses,
                                    promise: () => {
                                        this.context.router.history.push('/collection');
                                    }
                                })
                        }
                    );
                }
                else {
                    this.setState({
                        SnackbarMessage: `Oops! Erreur lors de ${!this.state.IdGlasses ? "l'ajout" : "la modification"} du post`,
                        SnackbarType: 'error'
                    });
                    this.props.parent.handleUnloadMainLoader();
                }
            });
        }
        else {
            this.setState({
                SnackbarMessage: error,
                SnackbarType: 'error'
            });
        }
    }

    handleMakeASquare = () => {
        $("#picturecontent").height($("#picturecontent").innerWidth());
    }


    render() {
        const { classes } = this.props;

        return (
            <div>
                <SnackbarHelper
                    type={this.state.SnackbarType}
                    message={this.state.SnackbarMessage}
                    closeSnackbar={this.handleCloseSnackbar}
                />
                <BackPageHelper path="/collection" />
                <Paper elevation={1} className={classNames(classes.paperau)} onKeyPress={this.handleKeyPress}>
                    <Grid justify='center' container spacing={0}>
                        <Grid xs={12} item className={classes.textAlignCenter}>
                            <input id="choosePicture" className={classes.uploadInput} type="file" accept="image/*" />
                            <div
                                id="picturecontent"
                                className={classNames(classes.pictureContentG)}
                                style={this.state.Thumbnail && { backgroundImage: "url('" + this.state.Thumbnail + "')" }}
                                onClick={event => this.handleChoosePicture(event, 'choosePicture')}
                            >
                                {!this.state.Thumbnail &&
                                    <div>
                                        <IconPhotoCameraOutlined className={classes.cameraIcon} />
                                        <Typography
                                            component="p"
                                            className={classes.pictureText}
                                        >
                                            Cliquer pour ajouter ou modifier une photo
                                        </Typography>
                                    </div>
                                }
                            </div>
                        </Grid>
                    </Grid>
                    <Grid justify='center' container spacing={0}>
                        <div className={classes.contentControls}>
                            {
                                [0, 3].map((n) => (
                                    <Grid xs={6} key={n} item className={classes.textAlignCenter}>
                                        {
                                            this.state.Categories.slice(n, n + 3).map((o, i) => (
                                                <FormGroup key={i}>
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                checked={Boolean(this.state.CategoriesSelected.find(f => f.Name === o.Name))}
                                                                onChange={() => this.handleChangeFilter(o)}
                                                            />
                                                        }
                                                        label={o.Label}
                                                    />
                                                </FormGroup>
                                            ))
                                        }
                                    </Grid>
                                ))
                            }
                        </div>
                    </Grid>
                    <Grid justify='center' container spacing={0}>
                        <Grid xs={12} item className={classes.textAlignCenter}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                label="Title"
                                type="text"
                                value={this.state.Title}
                                onChange={this.handleChangeTextField}
                                name="Title"
                                className={classes.textFieldWidth}
                            />
                        </Grid>
                    </Grid>
                    <Grid justify='center' container spacing={0}>
                        <Grid xs={12} item className={classes.textAlignCenter}>
                            <div className={classNames(classes.submitButtonContent)}>
                                <Button
                                    variant="contained"
                                    tabIndex="-1"
                                    color="primary"
                                    onClick={this.handleSubmit}
                                    className={classNames(classes.submitButton)}
                                >
                                    Valider
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                </Paper>
            </div>
        );
    }
}

AddUpdate.displayName = 'AddUpdate';

AddUpdate.contextTypes = {
    router: PropTypes.object.isRequired,
};

AddUpdate.propTypes = {
    classes: PropTypes.object.isRequired,
    width: PropTypes.string.isRequired,
};

export default withWidth()(withStyles(AddUpdateStyles)(AddUpdate));
