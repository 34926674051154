import { textAlignCenter } from '../_MainStyles';

const AutocompleteStyles = theme => ({
    root: {
        display:'inline-flex'
    },
    container: {
        position: 'relative',
    },
    suggestionsContainerOpen: {
        maxHeight: 185,
        overflow: 'auto',
        position: 'absolute',
        width:256,
        zIndex: 50,
        margin: '-8px auto',
        left: 0,
        right: 0,
    },
    suggestion: {
        display: 'block',
    },
    suggestionsList: {
        margin: 0,
        padding: 0,
        listStyleType: 'none',
    },
    divider: {
        height: theme.spacing.unit * 2,
    },
    textAlignCenter:{
        ...textAlignCenter
    },
    textfield:{
        marginTop: 16,
        marginBottom: 8,
        marginLeft: theme.spacing.unit,
		marginRight: theme.spacing.unit,
    },
});

export default AutocompleteStyles;