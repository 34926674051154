import $ from 'jquery';
import { Component } from 'react';
import HomeController from './HomeController';
import moment from 'moment';
import "moment/locale/fr";
moment.locale('fr');

let that;

class OpeningTimeController extends Component {
    constructor(props) {
        super(props);
        that = this;
    }

    GetOpeningTime(promise) {
        $.ajax({
            url: HomeController.UrlApi + "OpeningTime/GetOpeningTime",
            method: 'post',
            success: (openingTimes) => {
                promise(openingTimes);
            },
            error: () => {
                promise(null);
            }
        });
    }

    UpdateOpeningTime(openingTime, promise) {
        $.ajax({
            url: HomeController.UrlApi + "OpeningTime/UpdateOpeningTime",
            method: 'post',
            data: that.SetAjaxOpeningTime(openingTime),
            success: () => {
                promise(true);
            },
            error: () => {
                promise(null);
            }
        });
    }

    SetAjaxOpeningTime(openingTime) {
        return {
            OpeningTime: JSON.stringify(that.SetOpeningTime(openingTime))
        };
    }

    CheckOpeningTime(openingTime) {
        var error = null;

        if (!error && Boolean(openingTime.StartTimeMorning) !== Boolean(openingTime.EndTimeMorning)) {
            error = "L'horaire d'ouverture et l'horaire de fermeture doivent être renseignées pour la matinée";
        }

        if (!error && moment.utc(openingTime.StartTimeMorning, 'HH:mm') >= moment.utc(openingTime.EndTimeMorning, 'HH:mm')) {
            error = "L'horaire d'ouverture ne peut pas être supérieure ou égale à l'horaire de fermeture (matinée)";
        }

        if (!error && Boolean(openingTime.StartTimeAfternoon) !== Boolean(openingTime.EndTimeAfternoon)) {
            error = "L'horaire d'ouverture et l'horaire de fermeture doivent être renseignées pour l'après-midi";
        }

        if (!error && moment.utc(openingTime.StartTimeAfternoon, 'HH:mm') >= moment.utc(openingTime.EndTimeAfternoon, 'HH:mm')) {
            error = "L'horaire d'ouverture ne peut pas être supérieure ou égale à l'horaire de fermeture (après-midi)";
        }

        if (!error && moment.utc(openingTime.EndTimeMorning, 'HH:mm') >= moment.utc(openingTime.StartTimeAfternoon, 'HH:mm')) {
            error = "L'horaire de fermeture de la matinée ne peut pas être supérieure ou égale à l'horaire d'ouverture de l'après-midi";
        }

        return error;
    }

    SetOpeningTime(openingTime) {
        return {
            Id: openingTime.Id,
            StartTimeMorning: openingTime.StartTimeMorning,
            EndTimeMorning: openingTime.EndTimeMorning,
            StartTimeAfternoon: openingTime.StartTimeAfternoon,
            EndTimeAfternoon: openingTime.EndTimeAfternoon,
            Day: openingTime.Day,
            Order: openingTime.Order
        };
    }
}

OpeningTimeController.displayName = 'OpeningTimeController';

export default (new OpeningTimeController());