import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles'
import BackPageHelperStyles from '../Views/Styles/HelperStyles/BackPageHelperStyles';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeft from 'mdi-material-ui/ChevronLeft';


class BackPageHelper extends Component {

    handleConnectionProblem = (path) => {
        this.context.router.history.push(path);
    }

    render() {

        const { classes, path } = this.props;

        return (
            <IconButton 
                color="default" 
                className={classes.backButton}
                onClick={() => this.handleConnectionProblem(path)}
            >
                <ChevronLeft />
            </IconButton>
        );
    }
}

BackPageHelper.propTypes = {
    classes: PropTypes.object.isRequired,
    path: PropTypes.string.isRequired,
};

BackPageHelper.contextTypes = {
    router: PropTypes.object.isRequired,
};

export default withStyles(BackPageHelperStyles)(BackPageHelper);