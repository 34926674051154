import { Component } from 'react';
import CryptoJS from 'crypto-js';
import $ from 'jquery';
import HomeController from './HomeController';
import UsersController from './UsersController';

class SessionController extends Component {
    SetUser(user) {
        localStorage.setItem('i',
            CryptoJS.AES.encrypt(user.Id.toString(),
                HomeController.GetEncryptSHA256Key()).toString()
        );

        localStorage.setItem('u',
            CryptoJS.AES.encrypt(JSON.stringify(user),
                HomeController.GetEncryptSHA256Key(user.Id)).toString()
        );

        UsersController.GetToken();
    }

    SetJwt(jwt) {
        if (jwt) {
            localStorage.setItem('j',
                CryptoJS.AES.encrypt(jwt,
                    HomeController.GetEncryptSHA256Key()).toString()
            );

            $.ajaxSetup({
                headers: { 'Authorization': `Bearer ${jwt}` }
            });
        }
    }

    GetUser() {
        var currentUserId = localStorage.getItem('i') ?
            CryptoJS.AES.decrypt(
                localStorage.getItem('i'),
                HomeController.GetEncryptSHA256Key()
            ).toString(CryptoJS.enc.Utf8) : null;

        return currentUserId ?
            JSON.parse(
                CryptoJS.AES.decrypt(
                    localStorage.getItem('u'),
                    HomeController.GetEncryptSHA256Key(currentUserId)
                ).toString(CryptoJS.enc.Utf8)
            ) : null;
    }

    GetJwt() {
        var j = localStorage.getItem('j');
        return j ? CryptoJS.AES.decrypt(
            localStorage.getItem('j'),
            HomeController.GetEncryptSHA256Key()
        ).toString(CryptoJS.enc.Utf8) : null;
    }

    Logout(promise) {
        $.ajaxSetup({
            headers: { 'Authorization': '' }
        });
        localStorage.clear();
        promise();
    }
}


SessionController.displayName = 'SessionController';

export default new SessionController();