import { textAlignJustify } from './_MainStyles';

const LegalNoticeStyles = theme => ({
    root: {
        width: '90%',
        margin: '120px auto 40px auto',
        maxWidth: 800,
        ...textAlignJustify
    },
    margint10: {
        marginTop: 10,
    },
    margint20: {
        marginTop: 20,
    },
    margint30: {
        marginTop: 30,
    },
});

export default LegalNoticeStyles;