import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles'
import AppointmentsStyles from './Styles/AppointmentsStyles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import Typography from '@material-ui/core/Typography';
import SnackbarHelper from '../Helpers/SnackbarHelper';
import AppointmentsController from '../Controllers/AppointmentsController';
import {
    Collapse, FormControl, InputLabel, Select, OutlinedInput,
    MenuItem, Menu, Dialog, DialogContent, DialogActions, Button, DialogTitle, DialogContentText
} from '@material-ui/core';
import classNames from 'classnames';
import { MuiPickersUtilsProvider, DatePicker } from "material-ui-pickers";
import MomentUtils from '@date-io/moment';
import ChevronDown from 'mdi-material-ui/ChevronDown';
import Delete from 'mdi-material-ui/Delete';
import Information from 'mdi-material-ui/Information';
import IconButton from '@material-ui/core/IconButton';
import Close from 'mdi-material-ui/Close';
import Avatar from '@material-ui/core/Avatar';
import CalendarClock from 'mdi-material-ui/CalendarClock';
import DotsVertical from 'mdi-material-ui/DotsVertical';
import { getWSService } from '../Controllers/WebSocketService';
import moment from 'moment';
import "moment/locale/fr";
moment.locale('fr');

const form = {
    DateAppointment: {
        name: 'DateAppointment',
        label: 'Date du rendez-vous'
    },
    HourAppointment: {
        name: 'HourAppointment',
        label: 'Choisissez un rendez-vous'
    },
};

const m = moment();

let that;

class Appointments extends Component {
    constructor(props) {
        super(props);

        this.socketConnection = null;

        this.state = {
            DateAppointment: m.hour() >= 18 ? m.add(1, 'days') : m,
            UserAppointments: [],
            AvailableAppointments: [],
            SelectedAvailableAppointment: null,
            SelectedValue: '',
            SelectedUserAppointment: null,
            labelWidth: 0,
            SnackbarMessage: null,
            SnackbarType: null,
            AppointmentsCollapsed: !this.props.make,
            MyAppointmentsCollapsed: this.props.make,
            ShowInfo: false,
            DialogDeleteIsOpen: false
        }

        that = this;
    }

    componentDidMount() {
        this.props.parent.handleLoadMainLoader();
        this.setState({
            labelWidth: this.InputLabelRef && ReactDOM.findDOMNode(this.InputLabelRef).offsetWidth,
        });
        this.handleLoadAvailableAppointments();
        this.handleLoadUserAppointments();

        this.socketConnection = getWSService();

        getWSService().addMessageListener("appointments",
            () => {
                that.handleLoadAvailableAppointments();
                that.handleLoadUserAppointments();
            }
        );
    }

    handleLoadAvailableAppointments = (date) => {
        var selectedDate = date || this.state.DateAppointment;
        AppointmentsController.GetAvailableAppointments(
            selectedDate.format("YYYYMMDD"),
            (appointments) => {
                this.setState({
                    AvailableAppointments: appointments
                });

                if (this.state.SelectedAvailableAppointment !== null &&
                    appointments.find(f => f.Id === this.state.SelectedAvailableAppointment.Id) === null) {
                    this.setState({
                        SelectedAvailableAppointment: null,
                        SelectedValue: ''
                    });
                }
            }
        )
    }

    handleLoadUserAppointments = () => {
        AppointmentsController.GetAppointmentsByUser(
            this.props.parent.state.User,
            (appointments) => {
                this.setState({
                    UserAppointments: appointments
                });
                this.props.parent.handleUnloadMainLoader();
            }
        )
    }

    handleExpendCollapse = (name) => {
        this.setState({
            [name]: !this.state[name]
        })
    }

    handleDateChange = (date) => {
        this.handleLoadAvailableAppointments(date);

        this.setState({
            DateAppointment: date,
            SelectedAvailableAppointment: null
        });
    }

    handleAppointmentChange = (event, source) => {
        this.setState({
            SelectedValue: event.target.value,
            SelectedAvailableAppointment: source.props.data
        });
    };

    handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({
            SnackbarMessage: null
        });
    };

    handleSubmitTake = () => {
        var appointment = this.state.SelectedAvailableAppointment;
        var user = this.props.parent.state.User;

        var error = AppointmentsController.CheckAppointment(appointment, user);

        if (error === null) {
            this.props.parent.handleLoadMainLoader();
            AppointmentsController.IsAvailable(
                appointment, (isAvailable) => {
                    if (isAvailable) {
                        AppointmentsController.MakeAppointments(appointment, user, (success) => {
                            if (success) {
                                this.setState({
                                    SnackbarMessage: 'Rendez-vous confirmé',
                                    SnackbarType: 'success'
                                });
                                getWSService().sendMessage("appointments");
                                this.setState({
                                    MyAppointmentsCollapsed: false,
                                    SelectedAvailableAppointment: null,
                                    SelectedValue: '',
                                });
                            }
                            else {
                                this.setState({
                                    SnackbarMessage: 'Erreur lors de la confirmation du rendez-vous, veuillez ressayer',
                                    SnackbarType: 'error'
                                });
                                this.props.parent.handleUnloadMainLoader();
                            }
                        })
                    }
                    else {
                        this.setState({
                            SnackbarMessage: 'Désolé ce créneau horaire n\'est plus disponible',
                            SnackbarType: 'warning'
                        });
                        this.props.parent.handleUnloadMainLoader();
                    }
                })
        }
        else {
            this.setState({
                SnackbarMessage: error,
                SnackbarType: 'error'
            });
        }
    }

    handleOpenDots = (event, appointment) => {
        this.setState({
            anchorEl: event.currentTarget,
            SelectedUserAppointment: appointment
        });
    };

    handleShowInfo = () => {
        this.setState({
            ShowInfo: true,
            anchorEl: null
        });
    }

    handleHideInfo = () => {
        this.setState({
            ShowInfo: false,
            SelectedUserAppointment: null
        });
    }

    handleCloseDots = () => {
        this.setState({
            anchorEl: null,
            SelectedUserAppointment: null,
        });
    };

    handleDeleteAppointment = () => {
        this.setState({
            anchorEl: null,
            DialogDeleteIsOpen: true,
        });
    }

    handleCloseDeleteAppointment = () => {
        this.setState({
            DialogDeleteIsOpen: false,
            SelectedUserAppointment: null,
        });
    }

    handleConfirmDeleteAppointment = () => {
        this.props.parent.handleLoadMainLoader();
        AppointmentsController.RemoveAppointments(this.state.SelectedUserAppointment,
            (success) => {
                if (success) {
                    this.setState({
                        SnackbarMessage: 'Rendez-vous supprimé',
                        SnackbarType: 'success'
                    });
                    getWSService().sendMessage("appointments");
                }
                else {
                    this.setState({
                        SnackbarMessage: 'Erreur lors de la suppression du rendez-vous, veuillez ressayer',
                        SnackbarType: 'error'
                    });
                    this.props.parent.handleUnloadMainLoader();
                }
                this.handleCloseDeleteAppointment();
            })
    }

    render() {
        const { classes } = this.props;

        return (
            <div>
                <SnackbarHelper
                    type={this.state.SnackbarType}
                    message={this.state.SnackbarMessage}
                    closeSnackbar={this.handleCloseSnackbar}
                />
                {
                    (this.props.parent.state.User && !this.props.parent.state.User.IsAdministrator) &&
                    <Paper
                        elevation={12}
                        className={classes.paper}
                    >
                        <Grid justify='center' container spacing={0}>
                            <Grid xs={12} item className={classNames(classes.textAlignCenter, classes.headContainer)}>
                                <Typography variant="h6" color="primary" className={classes.title}>
                                    Prendre rendez-vous
                            </Typography>
                                <IconButton
                                    color="default"
                                    className={classes.iconContainer}
                                    onClick={() => this.handleExpendCollapse("AppointmentsCollapsed")}
                                >
                                    {this.state.AppointmentsCollapsed ? <ChevronDown /> : <Close />}
                                </IconButton>
                            </Grid>
                        </Grid>
                        <Collapse in={!this.state.AppointmentsCollapsed}>
                            <Grid justify='center' container spacing={8} className={classNames(classes.spaceBetweenGrid, classes.subContainer)}>
                                <Grid xs={12} item className={classes.textAlignCenter}>
                                    <MuiPickersUtilsProvider utils={MomentUtils} locale="fr">
                                        <DatePicker
                                            className={classes.datepicker}
                                            label={form.DateAppointment.label}
                                            variant="outlined"
                                            cancelLabel="Annuler"
                                            value={this.state.DateAppointment}
                                            disablePast
                                            openTo="day"
                                            format="DD/MM/YYYY"
                                            views={["year", "month", "day"]}
                                            onChange={(date) => this.handleDateChange(date)}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid xs={12} item className={classes.textAlignCenter}>
                                    <FormControl variant="outlined">
                                        <InputLabel
                                            ref={ref => {
                                                this.InputLabelRef = ref;
                                            }}
                                            htmlFor="outlined-appointment"
                                        >
                                            {form.HourAppointment.label}
                                        </InputLabel>
                                        <Select
                                            classes={{
                                                selectMenu: classes.selectMenu
                                            }}
                                            value={this.state.SelectedValue}
                                            onChange={this.handleAppointmentChange}
                                            input={
                                                <OutlinedInput
                                                    labelWidth={this.state.labelWidth}
                                                    name={form.HourAppointment.name}
                                                    id="outlined-appointment"
                                                />
                                            }
                                            MenuProps={{
                                                style: {
                                                    maxHeight: '50vh',
                                                    width: 256,
                                                },
                                            }}
                                        >
                                            {
                                                this.state.AvailableAppointments &&
                                                this.state.AvailableAppointments.map((app, i) => (
                                                    <MenuItem
                                                        key={i}
                                                        value={moment(app.DateTime).utc().format("HH[:]mm")}
                                                        data={app}
                                                    >
                                                        {moment(app.DateTime).utc().format("HH[:]mm")}
                                                    </MenuItem>
                                                ))
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid justify='center' container spacing={8}>
                                <Grid xs={12} item className={classes.textAlignCenter}>
                                    <Fab
                                        variant="extended"
                                        color="secondary"
                                        onClick={this.handleSubmitTake}
                                    >
                                        VALIDER
                                    </Fab>
                                </Grid>
                            </Grid>
                        </Collapse>
                    </Paper>
                }
                <Paper
                    elevation={12}
                    className={classes.paper}
                >
                    <Grid justify='center' container spacing={0}>
                        <Grid xs={12} item className={classNames(classes.textAlignCenter, classes.headContainer)}>
                            <Typography variant="h6" color="primary" className={classes.title}>
                                Mes rendez-vous
                            </Typography>
                            {
                                (this.props.parent.state.User && !this.props.parent.state.User.IsAdministrator) &&
                                <IconButton
                                    color="default"
                                    className={classes.iconContainer}
                                    onClick={() => this.handleExpendCollapse("MyAppointmentsCollapsed")}
                                >
                                    {this.state.MyAppointmentsCollapsed ? <ChevronDown /> : <Close />}
                                </IconButton>
                            }
                        </Grid>
                    </Grid>
                    <Collapse in={!this.state.MyAppointmentsCollapsed} className={classes.listAppointments}>
                        <Grid justify='center' container spacing={8} className={classNames(classes.spaceBetweenGrid, classes.subContainer)}>
                            <Grid xs={12} item>
                                {
                                    this.state.UserAppointments && this.state.UserAppointments.map((o, i) => {
                                        return (
                                            <Paper key={i} className={classes.paperlist} elevation={10}>
                                                <Avatar>
                                                    <CalendarClock />
                                                </Avatar>
                                                <div className={classes.typographyList}>
                                                    <Typography variant="button" style={{ fontSize: 16 }}>
                                                        {moment(o.DateTime).utc().format('DD/MM/YYYY[ ]HH[:]mm')}
                                                    </Typography>
                                                    {
                                                        (this.props.parent.state.User && this.props.parent.state.User.IsAdministrator) &&
                                                        <Typography variant="button" style={{ fontSize: 16 }}>
                                                            {`${o.User.FirstName} ${o.User.LastName}`}
                                                        </Typography>
                                                    }
                                                </div>
                                                <IconButton
                                                    onClick={event => this.handleOpenDots(event, o)}>
                                                    <DotsVertical />
                                                </IconButton>
                                            </Paper>
                                        )
                                    })
                                }
                                <Menu
                                    anchorEl={this.state.anchorEl}
                                    open={Boolean(this.state.anchorEl)}
                                    onClose={this.handleCloseDots}
                                    style={{ padding: 20 }}
                                >
                                    {
                                        (this.props.parent.state.User && this.props.parent.state.User.IsAdministrator) &&
                                        <MenuItem button onClick={this.handleShowInfo}>
                                            Infos Client <Information style={{ marginLeft: 10 }} color="primary" />
                                        </MenuItem>
                                    }
                                    <MenuItem button onClick={this.handleDeleteAppointment}>
                                        Supprimer <Delete style={{ marginLeft: 10 }} color="secondary" />
                                    </MenuItem>
                                </Menu>
                                {
                                    this.state.SelectedUserAppointment &&
                                    <>
                                        <Dialog
                                            open={this.state.ShowInfo}
                                            onClose={this.handleHideInfo}
                                            className={classes.textAlignCenter}
                                            classes={{
                                                paper: classes.paperInfo
                                            }}
                                        >
                                            <DialogContent>
                                                <Typography variant="button" className={classes.nameInfo} style={{ fontSize: 30 }}>
                                                    {`${this.state.SelectedUserAppointment.User.FirstName} ${this.state.SelectedUserAppointment.User.LastName}`}
                                                </Typography>
                                                <Typography style={{ fontSize: 24 }}>
                                                    <a className={classes.aprimary} href={`tel:${this.state.SelectedUserAppointment.User.PhoneNumber}`}>
                                                        {this.state.SelectedUserAppointment.User.PhoneNumber}
                                                    </a>
                                                </Typography>
                                                <Typography className={classes.spaceInfo} style={{ fontSize: 24 }}>
                                                    <a className={classes.asecondary} href={`mailto:${this.state.SelectedUserAppointment.User.Email.toLowerCase()}`}>
                                                        {this.state.SelectedUserAppointment.User.Email.toLowerCase()}
                                                    </a>
                                                </Typography>
                                                <Typography className={classes.grey} style={{ fontSize: 24 }}>
                                                    {this.state.SelectedUserAppointment.User.Address.toLowerCase()}
                                                </Typography>
                                                {
                                                    this.state.SelectedUserAppointment.User.AdditionalAddress &&
                                                    <Typography className={classes.grey} style={{ fontSize: 24 }}>
                                                        {this.state.SelectedUserAppointment.User.AdditionalAddress.toLowerCase()}
                                                    </Typography>
                                                }
                                                <Typography className={classes.grey} style={{ fontSize: 24 }}>
                                                    {`${this.state.SelectedUserAppointment.User.PostalCode} ${this.state.SelectedUserAppointment.User.City.toUpperCase()}`}
                                                </Typography>
                                                <DialogActions>
                                                    <Button onClick={this.handleHideInfo} color="primary">
                                                        Ok
                                                </Button>
                                                </DialogActions>
                                            </DialogContent>
                                        </Dialog>
                                        <Dialog
                                            open={this.state.DialogDeleteIsOpen}
                                            onClose={this.handleCloseDialogDelete}
                                        >
                                            <DialogTitle>{"Supprimer mon rendez-vous"}</DialogTitle>
                                            <DialogContent>
                                                <DialogContentText>
                                                    Voulez-vous vraiment supprimer ce rendez-vous ?
                                                </DialogContentText>
                                            </DialogContent>
                                            <DialogActions>
                                                <Button onClick={this.handleCloseDeleteAppointment} color="primary">
                                                    non
                                                </Button>
                                                <Button onClick={this.handleConfirmDeleteAppointment} color="primary">
                                                    oui
                                                </Button>
                                            </DialogActions>
                                        </Dialog>
                                    </>
                                }
                            </Grid>
                        </Grid>
                    </Collapse>
                </Paper>
            </div>
        )
    }
}


Appointments.displayName = 'Appointments';

Appointments.propTypes = {
    classes: PropTypes.object.isRequired,
};

Appointments.contextTypes = {
    router: PropTypes.object.isRequired,
};

export default withStyles(AppointmentsStyles)(Appointments);