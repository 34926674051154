import React, { Component } from 'react';
import Profil from './Profil';

class Account extends Component {
	render() {
		return (
			<Profil parent={this.props.parent} User={this.props.parent.state.User} />
		);
	}
}

Account.displayName = 'Account';

export default Account;