import { grey } from '@material-ui/core/colors';

import { hidden, positionAbsolute, fullWidthpc, textAlignCenter } from './_MainStyles';

const AdministrationLensStyles = theme => ({
	rootPaper: {
		padding: 20,
		minWidth: 200,
		margin: '80px auto 80px',
		[theme.breakpoints.up("xs")]: {
			width: 296,
			padding: 10,
		},
		[theme.breakpoints.up("sm")]: {
			width: 570,
		},
		[theme.breakpoints.up("md")]: {
			width: 600,
		},
	},
	textAlignCenter: {
		...textAlignCenter,
	},
	appBarTabs: {
		marginTop: 56,
		'@media (min-width:0px) and (orientation: landscape)': {
			marginTop: 48,
		},
		'@media (min-width:600px)': {
			marginTop: 64,
		},
	},
	table: {
		minWidth: 700,
		maxWidth: 900,
		marginTop: 50,
		marginLeft: 400,
	},
	fab: {
		margin: theme.spacing.unit,
	},
	iconButton: {
		padding: 10,
	},
	swipeableviews: {
		marginTop: 100,
		marginBottom: 60,
	},
	paperlist: {
		width: '80%',
		maxWidth: 980,
		margin: 'auto',
		padding: 20,
		marginBottom: 40,
		alignItems: 'center',
		display: 'flex'
	},
	typographyList: {
		flex: '1 1 auto',
		padding: '0 16px',
		minWidth: 0
	},
	paperglasses: {
		padding: 20,
		minWidth: 200,
		[theme.breakpoints.up("xs")]: {
			width: 296,
			padding: 10,
			margin: '0px auto 36px',
		},
		[theme.breakpoints.up("sm")]: {
			width: 570,
			margin: '20px auto 36px',
		},
		[theme.breakpoints.up("md")]: {
			width: 600,
			margin: '40px auto 36px',
		},
	},
	cameraIcon: {
		width: 50,
		height: 50,
		color: grey[400],
		[theme.breakpoints.up("xs")]: {
			marginTop: 71.5,
		},
		[theme.breakpoints.up("sm")]: {
			marginTop: 196.5,
		},
		[theme.breakpoints.up("md")]: {
			marginTop: 208.5,
		},
	},
	pictureText: {
		color: grey[400],
		padding: '0 20px',
	},
	hidden: {
		...hidden
	},
	pictureContent: {
		width: '80%',
		margin: 'auto',
		border: '1px solid #eee',
		borderRadius: 4,
		backgroundColor: 'transparent',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'contain',
		backgroundPosition: 'center',
		cursor: 'pointer',
		marginBottom: 20
	},
	uploadInput: {
		cursor: 'pointer',
		...positionAbsolute,
		top: 0,
		bottom: 0,
		right: 0,
		left: 0,
		...fullWidthpc,
		...hidden,
		zIndex: -1
	},
	openingTimePaper: {
		marginTop: 30,
		marginBottom: 10,
		paddingBottom: 16
	},
	headContainer: {
		display: 'flex',
	},
	subContainer: {
		marginTop: 20
	},
	textFieldWidth: {
		width: '80%'
	},
	datepicker: {
		width: '80%',
		marginTop: 16,
		marginBottom: 8
	},
	timepicker: {
		width: '80%',
		marginTop: 16,
		marginBottom: 8
	},
	iconContainer: {
		marginTop: 5,
		height: 48
	},
	title: {
		display: 'flex',
		flexGrow: '1',
		color: grey[400],
		padding: '0 20px',
		marginTop: 15,
		marginBottom: 10
	},
	subTitle: {
		color: grey[400],
		padding: '0 20px',
		marginTop: 15,
		marginBottom: 5
	},
	submitButtonOpeningTimeContent: {
		margin: '20px auto',
		height: 36
	},
	submitButtonContent: {
		margin: '20px auto',
		width: '80%',
		height: 36
	},
	submitButton: {
		float: 'right',
	},
	card: {
		marginTop: 30
	},
	details: {
		display: 'flex',
		flexDirection: 'column',
	},
	content: {
		flex: '1 0 auto',
		padding: '0 0 24px 0 !important'
	},
	cover: {
		width: 100,
		height: 100,
		margin: '20px auto 0 auto',
		borderRadius: '50%'
	},
	controls: {
		display: 'flex',
		alignItems: 'center',
		paddingLeft: theme.spacing.unit,
		paddingBottom: theme.spacing.unit,
	},
	playIcon: {
		height: 38,
		width: 38,
	},
});

export default AdministrationLensStyles;