import {
  positionAbsolute, textAlignLeft, textAlignCenter,
  textAlignJustify, textAlignRight, floatRight, whiteColor, hidden, fullWidthpc
} from './_MainStyles';

import { grey } from '@material-ui/core/colors';

const HomeStyles = theme => ({
  banner: {
    backgroundRepeat: 'no-repeat',
    height: 'calc(100vh - 56px)',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    '@media (min-width:0px) and (orientation: landscape)': {
      height: 'calc(100vh - 48px)',
    },
    '@media (min-width:600px)': {
      height: 'calc(100vh - 64px)',
    }
  },
  cameraIcon: {
    width: 50,
    height: 50,
    color: grey[400],
    [theme.breakpoints.up("xs")]: {
      marginTop: 71.5,
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: 196.5,
    },
    [theme.breakpoints.up("md")]: {
      marginTop: 208.5,
    },
  },
  rootSnackBarContent: {
    alignItems: 'self-start',
    flexWrap: 'nowrap'
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing.unit * 4,
  },
  snackBarText: {
    whiteSpace: 'pre-line',
    wordWrap: 'break-word',
  },
  message: {
    display: 'flex',
  },
  info: {
    backgroundColor: theme.palette.primary.main,
  },
  pictureText: {
    color: grey[400],
    padding: '0 20px',
  },
  textAlignLeft: {
    ...textAlignLeft
  },
  textAlignCenter: {
    ...textAlignCenter
  },
  textAlignRight: {
    ...textAlignRight
  },
  subTitle: {
    color: grey[400],
    padding: '0 20px',
    marginTop: 15,
    marginBottom: 5
  },
  timepicker: {
    width: '80%',
    marginTop: 16,
    marginBottom: 8
  },
  noOpacity: {
    opacity: 0
  },
  smallPadding: {
    padding: theme.spacing.unit * 3,
  },
  largePadding: {
    padding: theme.spacing.unit * 6,
  },
  paperBanner: {
    ...theme.mixins.gutters(),
    background: 'rgba(0, 0, 0, 0.4)',
    width: '20%',
    minWidth: 256,
    ...positionAbsolute,
    bottom: '25vh',
    left: '10vw',
    ...textAlignCenter,
    [theme.breakpoints.down('sm')]: {
      left: '5vw',
    },
    [`${theme.breakpoints.down('sm')} and (orientation: landscape)`]: {
      width: '50%',
      bottom: '5vh',
    },
    [`${theme.breakpoints.down('xs')}`]: {
      bottom: '15vh',
    }
  },
  editHomeButton: {
    ...positionAbsolute,
    height: 48,
    left: '10vw',
    bottom: '10vh',
    [theme.breakpoints.down('sm')]: {
      left: '5vw',
      bottom: '5vh',
    },
  },
  listOpeningTime: {
    [`${theme.breakpoints.down('lg')} and (orientation: landscape)`]: {
      maxHeight: '30vh',
    },
    [theme.breakpoints.down('sm')]: {
      maxHeight: '25vh',
    },
    maxHeight: '40vh',
    overflow: 'auto'
  },
  paperBannerMaxWidth: {
    maxWidth: 256,
  },
  paperBannerTitle: {
    marginBottom: 30,
    ...whiteColor
  },
  showOpeningTime: {
    marginTop: 30,
    marginBottom: -30
  },
  notShowOpeningTime: {
    marginBottom: 20
  },
  labelTitleOpeningTime: {
    fontSize: '18px !important'
  },
  labelOpeningTime: {
    fontSize: '16px !important'
  },
  pictureContent: {
    width: '80%',
    margin: 'auto',
    border: '1px solid #eee',
    borderRadius: 4,
    backgroundColor: 'transparent',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    cursor: 'pointer',
    marginBottom: 20
  },
  anchorOriginTopRight: {
    top: 90,
    zIndex: 1200
  },
  uploadInput: {
    cursor: 'pointer',
    ...positionAbsolute,
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    ...fullWidthpc,
    ...hidden,
    zIndex: -1
  },
  textFieldWidth: {
    width: '80%'
  },
  datepicker: {
    width: '80%',
    marginTop: 16,
    marginBottom: 8
  },
  submitButtonContent: {
    margin: '20px auto',
    width: '80%',
    height: 36
  },
  fullSubmitButtonContent: {
    margin: '20px auto',
    height: 36
  },
  submitButton: {
    float: 'right',
  },
  submitButtonLeft: {
    float: 'left',
  },
  paperBannerButton: {
    ...positionAbsolute,
    bottom: '10vh',
    right: '10vw',
    [theme.breakpoints.down('sm')]: {
      right: '5vw',
      bottom: '5vh',
    },
  },
  showCollection: {
    ...textAlignCenter,
    marginBottom: 50,
  },
  homepaper: {
    width: 'calc(95% - 100px)',
    margin: '120px auto',
    padding: 50
  },
  rootSlider: {
    margin: '0',
  },
  rootVerticalTimeline: {
    margin: '0px auto !important',
  },
  paragraphVerticalTimeline: {
    ...textAlignJustify,
    margin: '30px 0',
    whiteSpace: 'pre-line',
    wordWrap: 'break-word',
  },
  addPresentations: {
    marginTop: 30
  },
  iconVerticalTimeline: {
    ...floatRight,
    marginLeft: 15
  },
  whiteColor: {
    ...whiteColor
  },
  map: {
    height: 450,
    width: '100%'
  },
  footer: {
    ...textAlignCenter,
    width: '100%',
    height: 200,
    backgroundColor: theme.palette.primary.main,
    ...whiteColor,
    paddingTop: 50
  },
  nooverflowy: {
    overflowY: 'inherit'
  }
});

export default HomeStyles;