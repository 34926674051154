import { Component } from 'react';
import HomeController from './HomeController';
import $ from 'jquery';
import bcrypt from 'bcryptjs';
import SessionController from './SessionController';


const saltRounds = 10;
let that;

class UsersController extends Component {
	constructor(props) {
		super(props);

		that = this;
	}

	GetUserByEmail(email, promise) {
		$.ajax({
			method: 'POST',
			url: HomeController.UrlApi + 'Users/GetUserByEmail',
			data: {
				Email: email
			},
			success: (user) => {
				promise(user);
			},
			error: () => {
				promise(null);
			},
		});
	}

	UserAlreadyExist(email, promise) {
		$.ajax({
			async: false,
			method: 'POST',
			url: HomeController.UrlApi + 'Users/UserAlreadyExist',
			data: {
				Email: email
			},
			success: (data) => {
				promise(data);
			},
			error: () => {
				promise(null);
			}
		});
	}

	GetToken() {
		$.ajax({
			method: 'POST',
			url: HomeController.UrlApi + 'Users/GetToken',
			data: {
				User: JSON.stringify(SessionController.GetUser())
			},
			success: (data) => {
				SessionController.SetJwt(data);
			}
		});
	}

	AddUsers(state, promise) {
		$.ajax({
			method: 'POST',
			url: HomeController.UrlApi + 'Users/AddUsers',
			data: {
				DataUsers: that.StringifyUser(state)
			},
			success: (data) => {
				promise(data);
			},
			error: () => {
				promise(null);
			}
		});
	}

	UpdateUsers(state, user, promise) {
		$.ajax({
			method: 'POST',
			url: HomeController.UrlApi + 'Users/UpdateUsers',
			data: {
				DataUsers: that.StringifyUser(state, user)
			},
			success: (data) => {
				promise(data);
			},
			error: () => {
				promise(null);
			}
		});
	}

	DeleteUsers(state, user, promise) {
		$.ajax({
			method: 'POST',
			url: HomeController.UrlApi + 'Users/DeleteUsers',
			data: {
				DataUsers: that.StringifyUser(state, user)
			},
			success: () => {
				promise(true);
			},
			error: () => {
				promise(null);
			}
		});
	}

	SendLink(state, promise) {
		$.ajax({
			method: 'POST',
			url: HomeController.UrlApi + 'Users/SendLink',
			data: {
				Email: state.Email,
				Type: state.IsPasswordProblem ? "RecoveryPassword" : "ConfirmationEmail"
			},
			success: (response) => {
				promise(response);
			},
			error: () => {
				promise(null);
			},
		});
	}

	Confirm(u, l, promise) {
		$.ajax({
			method: "POST",
			url: HomeController.UrlApi + "Users/Confirm",
			data: {
				U: u,
				L: l
			},
			success: (linksReturns) => {
				promise(linksReturns);
			},
			error: () => {
				promise(null);
			},
		});
	}

	SetUser(state, user = null) {
		return {
			Id: !user ? null : user.Id,
			Gender: state.Gender,
			FirstName: state.FirstName,
			LastName: state.LastName,
			Address: state.Address,
			AdditionalAddress: state.AdditionalAddress,
			PostalCode: state.PostalCode,
			City: state.City,
			PhoneNumber: state.PhoneNumber,
			Email: !user ? state.Email : user.Email,
			EmailConfirmed: !user ? false : user.EmailConfirmed,
			Password: !user ? bcrypt.hashSync(state.Password, saltRounds) :
				(state.Password === "" ? user.Password : bcrypt.hashSync(state.Password, saltRounds))
		};
	}


	StringifyUser(state, user = null) {
		return JSON.stringify(this.SetUser(state, user));
	}
}


UsersController.displayName = 'UsersController';

export default new UsersController();