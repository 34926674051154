import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import LegalNoticeStyles from './Styles/LegalNoticeStyles';
import $ from 'jquery';


class LegalNotice extends Component {
    componentDidMount() {
        $(document).scrollTop(0);
    }

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <Typography variant="h4" gutterBottom>MENTIONS LEGALES</Typography>
                <div className={classes.margint30}></div>
                <Typography variant="h5" gutterBottom>Edition du site</Typography>
                <div className={classes.margint10}></div>
                <Typography variant="subtitle1" gutterBottom>opticsalomon.com est un site édité par la société OPTIC SALOMON.</Typography>
                <Typography variant="subtitle1" gutterBottom>OPTIC SALOMON est une SARL unipersonnelle, dont le siège social est 57 AV HENRI BECQUEREL 44490 LE CROISIC, immatriculée au registre du commerce et des sociétés de Saint-Nazaire sous le numéro 819 375 882.</Typography>
                <div className={classes.margint20}></div>
                <Typography variant="h5" gutterBottom>Propriété Intellectuelle</Typography>
                <div className={classes.margint10}></div>
                <Typography variant="subtitle1" gutterBottom>L'ensemble de ce site relève de la législation française et internationale sur le droit d'auteur et la propriété intellectuelle. Tous les droits de reproduction sont réservés, y compris les représentations iconographiques et photographiques. La reproduction, adaptation et/ou traduction de tout ou partie de ce site sur un support quel qu'il soit, est formellement interdite sauf autorisation.</Typography>
                <div className={classes.margint20}></div>
                <Typography variant="h5" gutterBottom>Modification du site</Typography>
                <div className={classes.margint10}></div>
                <Typography variant="subtitle1" gutterBottom>L'équipe éditoriale se réserve le droit de modifier ou de corriger le contenu de ce site et de ces mentions légales à tout moment et ceci sans préavis.</Typography>
                <div className={classes.margint20}></div>
                <Typography variant="h5" gutterBottom>Hébergeur</Typography>
                <div className={classes.margint10}></div>
                <Typography variant="subtitle1" gutterBottom>Le site opticsalomon.com est hébergé par la société Amazon Web Services.</Typography>
                <Typography variant="subtitle1" gutterBottom>Amazon Web Services Inc.</Typography>
                <Typography variant="subtitle1" gutterBottom>410 Terry Avenue North,</Typography>
                <Typography variant="subtitle1" gutterBottom>Seattle, WA 98109-5210, USA</Typography>
            </div>
        );
    }
}

LegalNotice.displayName = 'LegalNotice';

LegalNotice.contextTypes = {
    router: PropTypes.object.isRequired,
};

LegalNotice.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(LegalNoticeStyles)(LegalNotice);