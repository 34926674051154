import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles'
import ProfilStyles from '../Styles/_ProfilStyles/ProfilStyles';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Fab from '@material-ui/core/Fab';
import Typography from '@material-ui/core/Typography';
import UsersController from '../../Controllers/UsersController';
import SnackbarHelper from '../../Helpers/SnackbarHelper';
import HomeController from '../../Controllers/HomeController';
import classNames from 'classnames';
import bcrypt from 'bcryptjs';
import SessionController from '../../Controllers/SessionController';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core';
import { getWSService } from '../../Controllers/WebSocketService';

let that;
const form = (user) => [
	[
		{
			name: 'FirstName',
			label: 'Prénom',
			type: 'text',
			required: true,
			regex: /[A-ÿ- ]{3,30}/,
			errorMessage: 'Prénom invalide',
			isVisble: true,
			isDisabled: false
		},
		{
			name: 'LastName',
			label: 'Nom',
			type: 'text',
			required: true,
			regex: /[A-ÿ- ]{3,30}/,
			errorMessage: 'Nom invalide',
			isVisble: true,
			isDisabled: false
		},
	],
	[
		{
			name: 'Email',
			label: 'Email',
			type: 'email',
			required: true,
			regex: /^[^\W][a-zA-Z0-9_]+(\.[a-zA-Z0-9_]+)*@[a-zA-Z0-9_]+(\.[a-zA-Z0-9_]+)*\.[a-zA-Z]{2,5}$/,
			errorMessage: 'Email invalide',
			isVisble: true,
			isDisabled: user != null
		},
		{
			name: 'PhoneNumber',
			label: 'Téléphone',
			type: 'text',
			required: true,
			regex: /[0-9]{10}/,
			errorMessage: 'Téléphone invalide',
			isVisble: true,
			isDisabled: false
		},
	],
	// [
	// 	{
	// 		name: 'Address',
	// 		label: 'Adresse',
	// 		type: 'text',
	// 		required: true,
	// 		regex: /[A-ÿ- 0-9,]{3,100}/,
	// 		errorMessage: 'Adresse invalide',
	// 		isVisble: true,
	// 		isDisabled: false
	// 	},
	// 	{
	// 		name: 'AdditionalAddress',
	// 		label: "Complément d'adresse",
	// 		type: 'text',
	// 		required: false,
	// 		regex: /[A-ÿ- 0-9,]{3,100}/,
	// 		errorMessage: "Complément d'adresse invalide",
	// 		isVisble: true,
	// 		isDisabled: false
	// 	},
	// ],
	// [
	// 	{
	// 		name: 'PostalCode',
	// 		label: 'Code postal',
	// 		type: 'text',
	// 		required: true,
	// 		regex: /[0-9]{5}/,
	// 		errorMessage: 'Code postal invalide',
	// 		isVisble: true,
	// 		isDisabled: false
	// 	},
	// 	{
	// 		name: 'City',
	// 		label: 'Ville',
	// 		type: 'text',
	// 		required: true,
	// 		regex: /[A-ÿ- ]{3,50}/,
	// 		errorMessage: 'Ville invalide',
	// 		isVisble: true,
	// 		isDisabled: false
	// 	},
	// ],
	[
		{
			name: 'Password',
			label: !user ? 'Mot de passe' : 'Nouveau mot de passe',
			type: 'password',
			required: user == null,
			regex: /\S{6,50}/,
			errorMessage: !user ? 'Mot de passe invalide' : 'Nouveau mot de passe invalide',
			isVisble: true,
			isDisabled: false
		},
		{
			name: 'PasswordConfirmation',
			label: 'Confirmation du mot de passe',
			type: 'password',
			required: user == null,
			regex: /\S{6,50}/,
			errorMessage: 'Confirmation du mot de passe invalide',
			isVisble: true,
			isDisabled: false
		},
	],
	[
		{
			name: 'ActualPassword',
			label: 'Mot de passe actuel',
			type: 'password',
			required: user != null,
			regex: /\S{6,50}/,
			errorMessage: 'Mot de passe invalide',
			isVisble: user != null,
			isDisabled: false
		},
	],
];


class Profil extends Component {
	constructor(props) {
		super(props);

		this.state = this.initState(this.props.User);

		that = this;
	}

	initState = (user) => {
		return {
			Gender: user ? user.Gender : true,
			FirstName: user ? user.FirstName : '',
			LastName: user ? user.LastName : '',
			Address: user ? user.Address : '',
			AdditionalAddress: user ? user.AdditionalAddress : '',
			PostalCode: user ? user.PostalCode : '',
			City: user ? user.City : '',
			PhoneNumber: user ? user.PhoneNumber : '',
			Email: user ? user.Email : '',
			Password: '',
			PasswordConfirmation: '',
			ActualPassword: '',
			DialogDeleteIsOpen: false,
			PasswordDelete: ''
		}
	}

	handleHaveAnUpdate = () => {
		const user = this.props.User;
		const {
			Gender, FirstName, LastName, Address,
			AdditionalAddress, PostalCode, PhoneNumber, Email, Password
		} = this.state;

		return Gender !== user.Gender ||
			FirstName !== user.FirstName || LastName !== user.LastName ||
			Address !== user.Address || AdditionalAddress !== user.AdditionalAddress ||
			PostalCode !== user.PostalCode || PhoneNumber !== user.PhoneNumber ||
			Email !== user.Email || (Password !== '' && !bcrypt.compareSync(Password, user.Password));
	}

	handleChangeGender = () => {
		this.setState({
			Gender: !this.state.Gender
		});
	}

	handleChangeTextField = event => {
		this.setState({
			[event.target.name]: event.target.value,
		});
	};

	handleCheckData = () => {
		var error = null;
		form(this.props.User).map((o) =>
			o.map((oc) => {
				if (!error && oc.name !== 'ActualPassword' &&
					oc.required && !oc.regex.test(that.state[oc.name])) {
					error = oc.errorMessage
				}

				if (!error && !this.props.User && oc.name === "Email") {
					UsersController.UserAlreadyExist(that.state.Email, (userAlreadyExist) => {
						if (userAlreadyExist) {
							error = 'Email déjà utilisé';
						}
					});
				}

				return false;
			}));

		if (error) {
			that.setState({
				SnackbarMessage: error,
				SnackbarType: "warning"
			});
		}
		else if (!error && this.props.User) {
			if (!bcrypt.compareSync(this.state.ActualPassword, this.props.User.Password)) {
				error = 'Mot de passe actuel invalide';

				that.setState({
					SnackbarMessage: error,
					SnackbarType: 'error'
				});
			}
		}

		return error;
	}

	handleCloseSnackbar = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		this.setState({
			SnackbarMessage: null
		});
	};

	handleSubmit = () => {
		if (this.handleCheckData() == null) {
			if (!this.props.User) {
				this.props.parent.handleLoadMainLoader();
				UsersController.AddUsers(
					this.state,
					(user) => {
						if (!user) {
							that.setState({
								SnackbarType: 'error',
								SnackbarMessage: 'Erreur lors de la création du compte'
							});
						}
						else {
							HomeController.EmailSended = true;
							that.props.parent.handleMoveTo('/connection');
						}
						this.props.parent.handleUnloadMainLoader();
					}
				)
			}
			else {
				if (this.handleHaveAnUpdate()) {
					this.props.parent.handleLoadMainLoader();
					UsersController.UpdateUsers(
						this.state, this.props.User,
						(user) => {
							if (!user) {
								that.setState({
									SnackbarType: 'error',
									SnackbarMessage: 'Erreur lors de la mise à jour du compte'
								});
							}
							else {
								SessionController.SetUser(user);
								this.props.parent.UpdateUser();
								that.setState({
									SnackbarType: 'success',
									SnackbarMessage: 'Votre compte a été mis à jour'
								});
								getWSService().sendMessage("appointments");
							}
							that.setState({
								Password: '',
								PasswordConfirmation: '',
								ActualPassword: '',
							});
							this.props.parent.handleUnloadMainLoader();
						}
					);
				}
				else {
					that.setState({
						SnackbarType: 'info',
						SnackbarMessage: 'Aucune modification détectée',
						ActualPassword: ''
					});
				}
			}
		}
	}

	handleCloseDialogDelete = () => {
		that.setState({
			DialogDeleteIsOpen: false,
			PasswordDelete: ''
		});
	}

	handleDeleteAccount = () => {
		that.setState({
			DialogDeleteIsOpen: true,
			PasswordDelete: ''
		});
	}

	handleConfirmDeleteAccount = () => {
		if (bcrypt.compareSync(this.state.PasswordDelete, this.props.User.Password)) {
			UsersController.DeleteUsers(
				this.state, this.props.User,
				(success) => {
					if (!success) {
						that.setState({
							SnackbarType: 'error',
							SnackbarMessage: 'Erreur lors de la suppression du compte'
						});
					}
					else {
						HomeController.AccountDeleted = true;
						getWSService().sendMessage("appointments",
							{
								promise: () => {
									SessionController.Logout(() => this.context.router.history.push('/'))
								}
							}
						);
					}
				}
			);
		}
		else {
			that.setState({
				SnackbarType: 'error',
				SnackbarMessage: 'Mot de passe invalide'
			});
		}
	}

	render() {
		const { classes } = this.props;


		return (
			<div>
				<Paper
					elevation={12}
					className={classNames({ [classes.paperWithButtonLens]: this.props.User }, classes.paper)}
				>
					<Grid justify='center' container spacing={8} className={classes.spaceBetweenGrid}>
						<Grid xs={12} item className={classes.textAlignCenter}>
							<Typography variant="h5" gutterBottom color="primary">
								{this.props.User ? "Mon Compte" : "Inscription"}
							</Typography>
						</Grid>
					</Grid>
					<Grid justify='center' container spacing={8} className={classes.spaceBetweenGrid}>
						<Grid xs={12} item>
							<FormGroup row className={classes.justifyContentCenter}>
								<FormControlLabel
									control={
										<Switch
											checked={this.state.Gender}
											onChange={this.handleChangeGender}
											value={this.state.Gender}
											color="secondary"
										/>
									}
									label={!this.state.Gender ? "Monsieur" : "Madame"}
								/>
							</FormGroup>
						</Grid>
						{
							form(this.props.User).map((o, i) => {
								return (
									<Grid key={i} xs={12} item className={classes.textAlignCenter}>
										{
											o.map((oc, ic) => {
												return oc.isVisble && (
													<TextField
														key={i + "" + ic}
														name={oc.name}
														type={oc.type}
														label={oc.label}
														className={classes.textField}
														value={this.state[oc.name]}
														onChange={this.handleChangeTextField}
														margin="normal"
														variant="outlined"
														disabled={oc.isDisabled}
													/>
												)
											})
										}
									</Grid>
								)
							})
						}
					</Grid>
					<Grid justify='center' container spacing={8}>
						<Grid xs={12} item className={classes.textAlignCenter}>
							<Fab
								variant="extended"
								color="secondary"
								onClick={this.handleSubmit}
							>
								{this.props.User ? "MODIFIER" : "VALIDER"}
							</Fab>
						</Grid>
					</Grid>
					<SnackbarHelper
						type={this.state.SnackbarType}
						message={this.state.SnackbarMessage}
						closeSnackbar={this.handleCloseSnackbar}
					/>
				</Paper>
				{
					(this.props.User !== null && !this.props.User.IsAdministrator) &&
					<div>
						<Paper
							elevation={12}
							className={classNames({ [classes.paperWithButtonLens]: this.props.User }, classes.paper)}
						>
							<Grid justify='center' container spacing={8} className={classes.spaceBetweenGrid}>
								<Grid xs={12} item className={classes.textAlignCenter}>
									<Typography variant="h5" gutterBottom color="primary">
										{"Supprimer mon Compte"}
									</Typography>
								</Grid>
							</Grid>
							<Grid justify='center' container spacing={8}>
								<Grid xs={12} item className={classes.textAlignCenter}>
									<Fab
										variant="extended"
										color="secondary"
										onClick={this.handleDeleteAccount}
									>
										{"Supprimer mon compte"}
									</Fab>
								</Grid>
							</Grid>
						</Paper>
						<Dialog
							open={this.state.DialogDeleteIsOpen}
							onClose={this.handleCloseDialogDelete}
						>
							<DialogTitle>{"Supprimer mon compte"}</DialogTitle>
							<DialogContent>
								<DialogContentText>
									Toutes les données liés à votre compte vont être supprimées.
            					</DialogContentText>
								<DialogContentText>
									Voulez-vous continuer ?
            					</DialogContentText>
								<TextField
									className={classes.passwordDelete}
									autoFocus
									variant="outlined"
									margin="dense"
									name="PasswordDelete"
									label="Mot de passe"
									type="password"
									fullWidth
									onChange={this.handleChangeTextField}
								/>
							</DialogContent>
							<DialogActions>
								<Button onClick={this.handleCloseDialogDelete} color="primary">
									non
									<span style={{ fontSize: 24, marginLeft: 10 }} role="img" aria-label="">😊</span>
								</Button>
								<Button onClick={this.handleConfirmDeleteAccount} color="primary">
									oui
									<span style={{ fontSize: 24, marginLeft: 10 }} role="img" aria-label="">😢</span>
								</Button>
							</DialogActions>
						</Dialog>
					</div>
				}
			</div>
		)
	}
}


Profil.displayName = 'Profil';

Profil.propTypes = {
	classes: PropTypes.object.isRequired,
};

Profil.contextTypes = {
	router: PropTypes.object.isRequired,
};

export default withStyles(ProfilStyles)(Profil);