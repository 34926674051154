export const sliderSettings = {
	slidesToShow: 4,
	slidesToScroll: 1,
	autoplay: true,
	speed: 500,
	dots: true,
	autoplaySpeed: 2000,
	arrows: false,
	responsive: [
		{
			breakpoint: 1920,
			settings: {
				slidesToShow: 3,
			}
		},
		{
			breakpoint: 1280,
			settings: {
				slidesToShow: 2,
			}
		},
		{
			breakpoint: 840,
			settings: {
				slidesToShow: 1,
			}
		},
	]
};