import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import PrivacyPolicyStyles from './Styles/PrivacyPolicyStyles';
import $ from 'jquery';


class PrivacyPolicy extends Component {
    componentDidMount() {
        $(document).scrollTop(0);
    }

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <Typography variant="h4" gutterBottom>POLITIQUE DE CONFIDENTIALITÉ</Typography>
                <div className={classes.margint30}></div>
                <Typography variant="h5" gutterBottom>ARTICLE 1 : PRÉAMBULE</Typography>
                <div className={classes.margint10}></div>
                <Typography variant="subtitle1" gutterBottom>Cette politique de confidentialité s'applique au site : opticsalomon.com. </Typography>
                <Typography variant="subtitle1" gutterBottom>La présente politique de confidentialité a pour but d'exposer aux utilisateurs du site :</Typography>
                <Typography variant="subtitle1" gutterBottom>La manière dont sont collectées et traitées leurs données à caractère personnel. Doivent être considérées comme données personnelles toutes les données étant susceptibles d'identifier un utilisateur. Il s'agit notamment du prénom et du nom, l'adresse mail ou encore son numéro de téléphone;</Typography>
                <Typography variant="subtitle1" gutterBottom>Quels sont les droits des utilisateurs concernant ces données ;</Typography>
                <Typography variant="subtitle1" gutterBottom>Qui est responsable du traitement des données à caractère personnel collectées et traitées ;</Typography>
                <Typography variant="subtitle1" gutterBottom>A qui ces données sont transmises ;</Typography>
                <Typography variant="subtitle1" gutterBottom>Eventuellement, la politique du site en matière de fichiers "cookies".</Typography>
                <Typography variant="subtitle1" gutterBottom>Cette politique de confidentialité complète les mentions légales et les Conditions Générales d'Utilisation que les utilisateurs peuvent consulter à l'adresse ci-après :</Typography>
                <Typography variant="subtitle1" gutterBottom>opticsalomon.com</Typography>
                <div className={classes.margint20}></div>
                <Typography variant="h5" gutterBottom>ARTICLE 2 : PRINCIPES GÉNÉRAUX EN MATIÈRE DE COLLECTE ET DE TRAITEMENT DE DONNÉES</Typography>
                <div className={classes.margint10}></div>
                <Typography variant="subtitle1" gutterBottom>Conformément aux dispositions de l'article 5 du Règlement européen 2016/679, la collecte et le traitement des données des utilisateurs du site respectent les principes suivants :</Typography>
                <Typography variant="subtitle1" gutterBottom>Licéité, loyauté et transparence : les données ne peuvent être collectées et traitées qu'avec le consentement de l'utilisateur propriétaire des données. A chaque fois que des données à caractère personnel seront collectées, il sera indiqué à l'utilisateur que ses données sont collectées, et pour quelles raisons ses données sont collectées ;</Typography>
                <Typography variant="subtitle1" gutterBottom>Finalités limitées : la collecte et le traitement des données sont exécutés pour répondre à un ou plusieurs objectifs déterminés dans les présentes conditions générales d'utilisation ;</Typography>
                <Typography variant="subtitle1" gutterBottom>Minimisation de la collecte et du traitement des données : seules les données nécessaires à la bonne exécution des objectifs poursuivis par le site sont collectées ;</Typography>
                <Typography variant="subtitle1" gutterBottom>Conservation des données réduites dans le temps : les données sont conservées pour une durée limitée, dont l'utilisateur est informé. Si la durée de conservation ne peut être communiquée à l'utilisateur ;</Typography>
                <Typography variant="subtitle1" gutterBottom>Intégrité et confidentialité des données collectées et traitées : le responsable du traitement des données s'engage à garantir l'intégrité et la confidentialité des données collectées.</Typography>
                <Typography variant="subtitle1" gutterBottom>Afin d'être licites, et ce conformément aux exigences de l'article 6 du règlement européen 2016/679, la collecte et le traitement des données à caractère personnel ne pourront intervenir que s'ils respectent au moins l'une des conditions ci-après énumérées :</Typography>
                <Typography variant="subtitle1" gutterBottom>L'utilisateur a expressément consenti au traitement ;</Typography>
                <Typography variant="subtitle1" gutterBottom>Le traitement est nécessaire à la bonne exécution d'un contrat ;</Typography>
                <Typography variant="subtitle1" gutterBottom>Le traitement répond à une obligation légale ;</Typography>
                <Typography variant="subtitle1" gutterBottom>Le traitement s'explique par une nécessité liée à la sauvegarde des intérêts vitaux de la personne concernée ou d'une autre personne physique ;</Typography>
                <Typography variant="subtitle1" gutterBottom>Le traitement peut s'expliquer par une nécessité liée à l'exécution d'une mission d'intérêt public ou qui relève de l'exercice de l'autorité publique ;</Typography>
                <Typography variant="subtitle1" gutterBottom>Le traitement et la collecte des données à caractère personnel sont nécessaires aux fins des intérêts légitimes et privés poursuivis par le responsable du traitement ou par un tiers.</Typography>
                <div className={classes.margint20}></div>
                <Typography variant="h5" gutterBottom>ARTICLE 3 : DONNÉES À CARACTÈRE PERSONNEL COLLECTÉES ET TRAITÉES DANS LE CADRE DE LA NAVIGATION SUR LE SITE</Typography>
                <div className={classes.margint10}></div>
                <Typography variant="subtitle1" gutterBottom>A. DONNÉES COLLECTÉES ET TRAITÉES ET MODE DE COLLECTE</Typography>
                <Typography variant="subtitle1" gutterBottom>Les données à caractère personnel collectées sur le site opticsalomon.com sont les suivantes :</Typography>
                <Typography variant="subtitle1" gutterBottom>Genre, Nom, Prénom, Adresse email, Numéro de téléphone, et adresse postale</Typography>
                <Typography variant="subtitle1" gutterBottom>Ces données sont collectées lorsque l'utilisateur effectue l'une des opérations suivantes sur le site :</Typography>
                <Typography variant="subtitle1" gutterBottom>Lorsque l'utilisateur s'inscrit sur opticsalomon.com</Typography>
                <Typography variant="subtitle1" gutterBottom>Le responsable du traitement conservera dans ses systèmes informatiques du site et dans des conditions raisonnables de sécurité l'ensemble des données collectées pour une durée de : égale à la durée d'inscription à la newsletter.</Typography>
                <Typography variant="subtitle1" gutterBottom>La collecte et le traitement des données répondent aux finalités suivantes :</Typography>
                <Typography variant="subtitle1" gutterBottom>Les données personnelles citées ci-dessus sont collectées à des fins communications. </Typography>
                <Typography variant="subtitle1" gutterBottom>B. TRANSMISSION DES DONNÉES A DES TIERS</Typography>
                <Typography variant="subtitle1" gutterBottom>Les données à caractère personnel collectées par le site ne sont transmises à aucun tiers, et ne sont traitées que par l'éditeur du site. </Typography>
                <Typography variant="subtitle1" gutterBottom>C. HÉBERGEMENT DES DONNÉES</Typography>
                <Typography variant="subtitle1" gutterBottom>Le site opticsalomon.com est hébergé par : Amazon Web Services Inc., dont le siège est situé à l'adresse ci-après :</Typography>
                <Typography variant="subtitle1" gutterBottom>410 Terry Avenue North, Seattle, WA 98109-5210, USA</Typography>
                <div className={classes.margint20}></div>
                <Typography variant="h5" gutterBottom>ARTICLE 4 : RESPONSABLE DU TRAITEMENT DES DONNÉES</Typography>
                <div className={classes.margint10}></div>
                <Typography variant="subtitle1" gutterBottom>A. LE RESPONSABLE DU TRAITEMENT DES DONNÉES</Typography>
                <Typography variant="subtitle1" gutterBottom>Le responsable du traitement des données à caractère personnel est : OPTIC SALOMON. Il peut être contacté de la manière suivante :</Typography>
                <Typography variant="subtitle1" gutterBottom><a href="mailto:hello@opticsalomon.com">hello@opticsalomon.com</a></Typography>
                <Typography variant="subtitle1" gutterBottom>Le responsable du traitement des données est chargé de déterminer les finalités et les moyens mis au service du traitement des données à caractère personnel.</Typography>
                <Typography variant="subtitle1" gutterBottom>B. OBLIGATIONS DU RESPONSABLE DU TRAITEMENT DES DONNÉES</Typography>
                <Typography variant="subtitle1" gutterBottom>Le responsable du traitement s'engage à protéger les données à caractère personnel collectées, à ne pas les transmettre à des tiers sans que l'utilisateur n'en ait été informé et à respecter les finalités pour lesquelles ces données ont été collectées.</Typography>
                <Typography variant="subtitle1" gutterBottom>De plus, le responsable du traitement des données s'engage à notifier l'utilisateur en cas de rectification ou de suppression des données, à moins que cela n'entraîne pour lui des formalités, coûts et démarches disproportionnés.</Typography>
                <Typography variant="subtitle1" gutterBottom>Dans le cas où l'intégrité, la confidentialité ou la sécurité des données à caractère personnel de l'utilisateur est compromise, le responsable du traitement s'engage à informer l'utilisateur par tout moyen. </Typography>
                <div className={classes.margint20}></div>
                <Typography variant="h5" gutterBottom>ARTICLE 5 : DROITS DE L'UTILISATEUR </Typography>
                <div className={classes.margint10}></div>
                <Typography variant="subtitle1" gutterBottom>Conformément à la réglementation concernant le traitement des données à caractère personnel, l'utilisateur possède les droits ci-après énumérés.</Typography>
                <Typography variant="subtitle1" gutterBottom>Afin que le responsable du traitement des données fasse droit à sa demande, l'utilisateur est tenu de lui communiquer : ses prénom et nom ainsi que son adresse e-mail.</Typography>
                <Typography variant="subtitle1" gutterBottom>Le responsable du traitement des données est tenu de répondre à l'utilisateur dans un délai de 30 (trente) jours maximum.</Typography>
                <Typography variant="subtitle1" gutterBottom>A. PRÉSENTATION DES DROITS DE L'UTILISATEUR EN MATIÈRE DE COLLECTE ET TRAITEMENT DE DONNÉES</Typography>
                <Typography variant="subtitle1" gutterBottom>a. Droit d'accès, de rectification et droit à l'effacement</Typography>
                <Typography variant="subtitle1" gutterBottom>L'utilisateur peut prendre connaissance, mettre à jour, modifier ou demander la suppression des données le concernant, en respectant la procédure ci-après énoncée :</Typography>
                <Typography variant="subtitle1" gutterBottom>L’utilisateur est inscrit ses données personnelles lors d'une prise de contact via le site internet ou lorsqu'il s’inscrit à la newsletter.</Typography>
                <Typography variant="subtitle1" gutterBottom>Toutes les données de l'utilisateur sont supprimés lorsque ce dernier se désinscrit de la newsletter. </Typography>
                <Typography variant="subtitle1" gutterBottom>b. Droit à la portabilité des données</Typography>
                <Typography variant="subtitle1" gutterBottom>L'utilisateur a le droit de demander la portabilité de ses données personnelles, détenues par le site, vers un autre site, en se conformant à la procédure ci-après :</Typography>
                <Typography variant="subtitle1" gutterBottom>L'utilisateur doit faire une demande de portabilité de ses données personnelles auprès du responsable du traitement des données, en envoyant un e-mail à l'adresse prévue ci-dessus.</Typography>
                <Typography variant="subtitle1" gutterBottom>c. Droit à la limitation et à l'opposition du traitement des données</Typography>
                <Typography variant="subtitle1" gutterBottom>L'utilisateur a le droit de demander la limitation ou de s'opposer au traitement de ses données par le site, sans que le site ne puisse refuser, sauf à démontrer l'existence de motifs légitimes et impérieux, pouvant prévaloir sur les intérêts et les droits et libertés de l'utilisateur.</Typography>
                <Typography variant="subtitle1" gutterBottom>Afin de demander la limitation du traitement de ses données ou de formuler une opposition au traitement de ses données, l'utilisateur doit suivre la procédure suivante :</Typography>
                <Typography variant="subtitle1" gutterBottom>L'utilisateur doit faire une demande de limitation au traitement de ses données personnelles auprès du responsable du traitement des données, en envoyant un e-mail à l'adresse prévue ci-dessus.</Typography>
                <Typography variant="subtitle1" gutterBottom>d. Droit de ne pas faire l'objet d'une décision fondée exclusivement sur un procédé automatisé</Typography>
                <Typography variant="subtitle1" gutterBottom>Conformément aux dispositions du règlement 2016/679, l'utilisateur a le droit de ne pas faire l'objet d'une décision fondée exclusivement sur un procédé automatisé si la décision produit des effets juridiques le concernant, ou l'affecte de manière significative de façon similaire.</Typography>
                <Typography variant="subtitle1" gutterBottom>e. Droit de déterminer le sort des données après la mort</Typography>
                <Typography variant="subtitle1" gutterBottom>Il est rappelé à l'utilisateur qu'il peut organiser quel doit être le devenir de ses données collectées et traitées s'il décède, conformément à la loi n°2016-1321 du 7 octobre 2016.</Typography>
                <Typography variant="subtitle1" gutterBottom>f. Droit de saisir l'autorité de contrôle compétente</Typography>
                <Typography variant="subtitle1" gutterBottom>Dans le cas où le responsable du traitement des données décide de ne pas répondre à la demande de l'utilisateur, et que l'utilisateur souhaite contester cette décision, ou, s'il pense qu'il est porté atteinte à l'un des droits énumérés ci-dessus, il est en droit de saisir la CNIL (Commission Nationale de l'Informatique et des Libertés, https://www.cnil.fr) ou tout juge compétent. </Typography>
                <Typography variant="subtitle1" gutterBottom>B. DONNÉES PERSONNELLES DES PERSONNES MINEURES</Typography>
                <Typography variant="subtitle1" gutterBottom>Conformément aux dispositions de l'article 8 du règlement européen 2016/679 et à la loi Informatique et Libertés, seuls les mineurs âgés de 15 ans ou plus peuvent consentir au traitement de leurs données personnelles.</Typography>
                <Typography variant="subtitle1" gutterBottom>Si l'utilisateur est un mineur de moins de 15 ans, l'accord d'un représentant légal sera requis afin que des données à caractère personnel puissent être collectées et traitées.</Typography>
                <Typography variant="subtitle1" gutterBottom>L'éditeur du site se réserve le droit de vérifier par tout moyen que l'utilisateur est âgé de plus de 15 ans, ou qu'il aura obtenu l'accord d'un représentant légal avant de naviguer sur le site.</Typography>
                <div className={classes.margint20}></div>
                <Typography variant="h5" gutterBottom>ARTICLE 6 : CONDITIONS DE MODIFICATION DE LA POLITIQUE DE CONFIDENTIALITÉ</Typography>
                <div className={classes.margint10}></div>
                <Typography variant="subtitle1" gutterBottom>La présente politique de confidentialité peut être consultée à tout moment à l'adresse ci-après indiquée :</Typography>
                <Typography variant="subtitle1" gutterBottom><a href="https://opticsalomon.com/#/privacypolicy">https://opticsalomon.com/#/privacypolicy</a></Typography>
                <Typography variant="subtitle1" gutterBottom>L'éditeur du site se réserve le droit de la modifier afin de garantir sa conformité avec le droit en vigueur.</Typography>
                <Typography variant="subtitle1" gutterBottom>Par conséquent, l'utilisateur est invité à venir consulter régulièrement cette politique de confidentialité afin de se tenir informé des derniers changements qui lui seront apportés.</Typography>
                <Typography variant="subtitle1" gutterBottom>Il est porté à la connaissance de l'utilisateur que la dernière mise à jour de la présente politique de confidentialité est intervenue le : 27/05/2020.</Typography>
            </div>
        );
    }
}

PrivacyPolicy.displayName = 'PrivacyPolicy';

PrivacyPolicy.contextTypes = {
    router: PropTypes.object.isRequired,
};

PrivacyPolicy.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(PrivacyPolicyStyles)(PrivacyPolicy);