import { textAlignCenter, justifyContentCenter, textAlignLeft } from './_MainStyles';

const AppointmentsStyles = theme => ({
	container: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	textField: {
		marginLeft: theme.spacing.unit,
		marginRight: theme.spacing.unit,
	},
	datepicker: {
		marginTop: 16,
		marginBottom: 8,
		marginLeft: theme.spacing.unit,
		marginRight: theme.spacing.unit,
	},
	dense: {
		marginTop: 16,
	},
	menu: {
		width: 200,
	},
	root: {
		flexGrow: 1,
	},
	demo: {
		height: 240,
	},
	control: {
		padding: theme.spacing.unit * 2,
	},
	paper: {
		width: 'calc(95% - 40px)',
		minWidth: 256,
		padding: '30px 20px',
		margin: '60px auto',
		[theme.breakpoints.up('sm')]: {
			width: '80%',
			minWidth: 556,
			maxWidth: 600,
		},
	},
	headContainer: {
		display: 'flex',
	},
	title: {
		display: 'flex',
		flexGrow: '1',
		padding: '0 20px',
		marginTop: 15,
		marginBottom: 10
	},
	iconContainer: {
		marginTop: 5,
		height: 48
	},
	subContainer: {
		marginTop: 20
	},
	spaceBetweenGrid: {
		marginBottom: 20,
	},
	spaceBetweenTitle: {
		marginTop: 30,
	},
	textAlignCenter: {
		...textAlignCenter
	},
	justifyContentCenter: {
		...justifyContentCenter
	},
	inputTypeFile: {
		display: 'none',
	},
	pictureButton: {
		width: 256,
		height: 56,
		marginTop: 16,
		marginBottom: 8,
		marginLeft: theme.spacing.unit,
		marginRight: theme.spacing.unit,
	},
	pictureButtonIcon: {
		marginLeft: theme.spacing.unit,
	},
	paperlist: {
		width: '80%',
		maxWidth: 980,
		margin: 'auto',
		padding: 20,
		marginTop: 40,
		marginBottom: 20,
		alignItems: 'center',
		display: 'flex'
	},
	typographyList: {
		flex: '1 1 auto',
		padding: '0 16px',
		minWidth: 0
	},
	listAppointments: {
		maxHeight: 650,
		overflow: 'auto'
	},
	selectMenu: {
		background: 'none !important',
		...textAlignLeft
	},
	paperInfo: {
		paddingTop: 30,
		minWidth: 400,
		maxWidth: 600,
		width: '100%'
	},
	nameInfo: {
		marginBottom: 30
	},
	spaceInfo: {
		marginBottom: 20
	},
	grey: {
		color: `#aaa !important`,
	},
	aprimary: {
		color: `${theme.palette.primary.main} !important`,
		textDecoration: 'none !important'
	},
	asecondary: {
		color: `${theme.palette.secondary.main} !important`,
		textDecoration: 'none !important'
	}
});

export default AppointmentsStyles