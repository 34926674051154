import { Component } from 'react';
import PropTypes from 'prop-types';

class NotFound extends Component {
    componentDidMount() {
        this.context.router.history.push("/")
    }

    render() {
        return (null);
    }
}

NotFound.displayName = 'NotFound';

NotFound.contextTypes = {
    router: PropTypes.object.isRequired,
};
export default NotFound;