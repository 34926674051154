import { textAlignRight, textAlignCenter } from './_MainStyles';

const CollectionStyles = theme => ({
	rootSlider: {
		margin: '0',
	},
	textAlignCenter: {
		...textAlignCenter
	},
	spaceBetweenGrid: {
		marginBottom: 20,
	},
	root: {
		...theme.mixins.gutters(),
		paddingTop: 25,
		paddingBottom: 20,
	},
	filterContent: {
		...textAlignRight,
		...theme.mixins.gutters(),
		marginTop: 30,
		marginBottom: 40
	},
	rootFilter: {
		width: '100%',
		maxWidth: 360,
		minWidth: 200,
		backgroundColor: theme.palette.background.paper,
	},
	clearFilter: {
		float: 'right',
		textDecoration: 'underline',
		cursor: 'pointer',
		lineHeight: '48px',
		color: 'rgba(0, 0, 0, 0.54)',
	},
	grid: {
		flexGrow: 1
	},
	control: {
		padding: theme.spacing.unit * 2,
	},
	demo: {
		margin: 25
	},
	typography: {
		margin: theme.spacing.unit * 2,
	},
	loadMoreButton: {
		marginBottom: 30
	},
	addGlasses: {
		position: 'fixed',
		right: 30,
		bottom: 30
	},
	extendedIcon: {
		marginRight: theme.spacing.unit,
	},
});
export default CollectionStyles;