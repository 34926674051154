import { textAlignCenter, textAlignRight } from '../_MainStyles';

const CardHelperStyles = theme => ({
	card: {
		maxWidth: 345,
		width: '100%',
		padding: 25,
		[theme.breakpoints.only('xs')]: {
			padding: 0,
		},
		margin: '0 auto 60px auto',
	},
	media: {
		backgroundSize: 'contain ! important',
		height: 345,
	},
	cardcontent: {
		marginTop: 24,
		padding: 0,
		...textAlignCenter
	},
	cardaction: {
		display: 'block',
		...textAlignRight
	}
});

export default CardHelperStyles;