import UsersController from "../Controllers/UsersController";


export function emailConfirmation(promise) {
    var response = null;
    if (getUrlParameter("u") && getUrlParameter("l")) {
        setTimeout(() => {
            UsersController.Confirm(getUrlParameter("u"), getUrlParameter("l"), (linksReturns) => {
                if (!linksReturns) {
                    response = {
                        message: "Erreur lors de l'envoi de l'email, veuillez réssayer",
                        type: "error"
                    };
                }
                else {
                    switch (linksReturns) {
                        case "invalidlink":
                            response = {
                                message: "Le lien est invalide, veuillez réssayer",
                                type: "info"
                            };
                            break;
                        case "expiredlink":
                            response = {
                                message: "Le lien à expiré, veuillez le regénérer",
                                type: "info"
                            };
                            break;
                        case "recoverypassword":
                            response = {
                                message: "Le mot de passe à été réinitialisé, veuillez consulter vos emails",
                                type: "info"
                            };
                            break;
                        case "emailalreadyconfirmed":
                            response = {
                                message: "Votre email est déjà confimé",
                                type: "success"
                            };
                            break;
                        case "confirmationemail":
                            response = {
                                message: "Votre email est confirmé",
                                type: "success"
                            };
                            break;
                        case "newsletteralreadyunsubscribe":
                            response = {
                                message: "Vous n'êtes pas inscrit à la newsletter",
                                type: "success"
                            };
                            break;
                        case "newsletterunsubscribe":
                            response = {
                                message: "Votre email vient d'être retiré de la newsletter",
                                type: "success"
                            };
                            break;
                        default:
                            response = null;
                            break;
                    }
                }
                promise(response);
            });
        }, 2000);
    }
    else {
        promise(response);
    }
}


function getUrlParameter(paramName) {
    const urlParams = new URLSearchParams(window.location.search);

    return urlParams.get(paramName);
};