import { textAlignCenter, justifyContentCenter, textAlignRight, floatRight } from './_MainStyles';

const ConnectionStyles = theme => ({
	spaceBetweenGrid: {
		marginBottom: 20,
	},
	textAlignCenter:{
		...textAlignCenter
    },
	justifyContentCenter:{
		...justifyContentCenter
    },
    linkConnectionProblem:{
        width: 256,
        ...textAlignRight,
        margin: 'auto',
        cursor: 'pointer'
    },
    closeConnectionProblem:{
        ...floatRight,
        marginTop: -55,
        [theme.breakpoints.only('xs')]: {
            marginTop: -69,
            marginRight: -20
        },
    },
	paper: {
        width: '80%',
        minWidth: 256,
        maxWidth: 300,
        padding: '30px 20px',
        [theme.breakpoints.up('sm')]: {
            width: '80%',
            minWidth: 556,
            maxWidth: 600,
            margin: '120px auto 36px',
        },
        [theme.breakpoints.only('xs')]: {
            margin: '80px auto 70px',
        },
	},
});
export default ConnectionStyles