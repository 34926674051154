import $ from 'jquery';
import { Component } from 'react';
import HomeController from './HomeController';
import moment from 'moment';
import "moment/locale/fr";
moment.locale('fr');

let that;

class AlertWelcomeController extends Component {
    constructor(props) {
        super(props);
        that = this;
    }

    GetAlertWelcome(promise) {
        $.ajax({
            url: HomeController.UrlApi + "AlertWelcome/GetAlertWelcome",
            method: 'post',
            success: (alertWelcome) => {
                promise(alertWelcome);
            },
            error: () => {
                promise(null);
            }
        });
    }

    UpdateAlertWelcome(component, isdelete, promise) {
        $.ajax({
            url: HomeController.UrlApi + "AlertWelcome/UpdateAlertWelcome",
            method: 'post',
            data: that.SetAjaxAlertWelcome(component, isdelete),
            success: (alertWelcome) => {
                promise(alertWelcome);
            },
            error: () => {
                promise('error');
            }
        });
    }

    SetAjaxAlertWelcome(component, isdelete) {
        return {
            AlertWelcome: isdelete ? null : JSON.stringify(that.SetAlertWelcome(component))
        };
    }

    SetAlertWelcome(component) {
        return {
            Message: component.state.AlertMessage,
            StartDate: component.state.AlertStartDate,
            EndDate: component.state.AlertEndDate,
        };
    }

    CheckAlertWelcome(component) {
        var error = null;

        if (!error && !component.state.AlertMessage) {
            error = 'Oops, où est passé le message ?';
        }

        if (!error && !component.state.AlertStartDate) {
            error = 'Oops, où est passé la date de début ?';
        }

        if (!error && !component.state.AlertEndDate) {
            error = 'Oops, où est passé la date de fin ?';
        }

        if (!error && moment.utc(component.state.AlertStartDate) > moment.utc(component.state.AlertEndDate)) {
            error = 'La date de fin ne peut pas être supérieure à la date de début du message d\'accueil';
        }

        return error;
    }
}

AlertWelcomeController.displayName = 'AlertWelcomeController';

export default (new AlertWelcomeController());