import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles'
import ConnectionStyles from './Styles/ConnectionStyles';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import Typography from '@material-ui/core/Typography';
import SwipeableViews from 'react-swipeable-views';
import IconButton from '@material-ui/core/IconButton';
import Close from 'mdi-material-ui/Close';
import UsersController from '../Controllers/UsersController';
import SnackbarHelper from '../Helpers/SnackbarHelper';
import bcrypt from 'bcryptjs';
import SessionController from '../Controllers/SessionController';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import HomeController from '../Controllers/HomeController';

class Connection extends Component {
	constructor(props) {
		super(props);

		this.state = {
			Email: '',
			Password: '',
			ConnectionProblem: true,
			SnackbarMessage: null,
			SnackbarType: null,
			IsPasswordProblem: true,
		};
	}

	componentDidMount() {
		this.setState({
			ConnectionProblem: false,
		});
		if (HomeController.EmailSended) {
			this.setState({
				SnackbarMessage: "Un email d'activation vous à été envoyé",
				SnackbarType: 'success'
			});
		}
	}

	handleChangeTextField = event => {
		this.setState({
			[event.target.name]: event.target.value,
		});
	};

	handleConnectionProblem = (isConnectionProblem) => {
		this.setState({
			ConnectionProblem: isConnectionProblem,
		});
	}

	handleCloseSnackbar = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		this.setState({
			SnackbarMessage: null
		});
	}

	handleSubmit = () => {
		if (this.state.Email && this.state.Password) {
			this.props.parent.handleLoadMainLoader();
			UsersController.GetUserByEmail(this.state.Email, (user) => {
				if (user) {
					if (bcrypt.compareSync(this.state.Password, user.Password)) {
						if (user.EmailConfirmed) {
							SessionController.SetUser(user);
							this.props.parent.handleMoveTo('/');
						}
						else {
							this.setState({
								SnackbarMessage: 'Email non confirmé',
								SnackbarType: 'warning'
							});
						}
					}
					else {
						this.handleSetInvalidEmailOrPAssword();
					}
					this.props.parent.handleUnloadMainLoader();
				}
				else {
					this.handleSetInvalidEmailOrPAssword();
					this.props.parent.handleUnloadMainLoader();
				}
			});
		}
	}

	handleSetInvalidEmailOrPAssword = () => {
		this.setState({
			SnackbarMessage: 'Email et/ou mot de passe invalide(s)',
			SnackbarType: 'error'
		});
	}

	handleSubmitConnectionProblem = () => {
		if (this.state.Email) {
			this.props.parent.handleLoadMainLoader();
			UsersController.SendLink(
				this.state,
				(response) => {
					if (response) {
						if (!this.state.IsPasswordProblem && response === 'alreadyconfirmed') {
							this.setState({
								SnackbarMessage: 'Ce compte est déjà valide',
								SnackbarType: 'warning'
							});
						}
						else {
							this.setState({
								SnackbarMessage: response === 'emailsended' ?
									'Un email vous à été envoyé' : 'Compte invalide',
								SnackbarType: response === 'emailsended' ? 'success' : 'error'
							});
						}
						this.setState({
							ConnectionProblem: false,
						});
					}
					else {
						this.setState({
							SnackbarMessage: "Erreur lors de l'envoi de l'email",
							SnackbarType: 'error'
						});
					}
					this.props.parent.handleUnloadMainLoader();
				});
		}
	}


	handleChangeProblemType = () => {
		this.setState({
			IsPasswordProblem: !this.state.IsPasswordProblem
		});
	}

	onKeyDown = (event) => {
		if (event.key === 'Enter') {
			event.preventDefault();
			event.stopPropagation();
			this.handleSubmit();
		}
	}

	render() {
		const { classes } = this.props;

		return (
			<div>
				<SnackbarHelper
					type={this.state.SnackbarType}
					message={this.state.SnackbarMessage}
					closeSnackbar={this.handleCloseSnackbar}
				/>
				<SwipeableViews
					index={this.state.ConnectionProblem ? 1 : 0}
					disabled
				>
					<Paper
						elevation={12}
						className={classes.paper}
					>
						<Grid justify='center' container spacing={8} className={classes.spaceBetweenGrid}>
							<Grid xs={12} item className={classes.textAlignCenter}>
								<Typography variant="h5" gutterBottom color="primary">
									Connexion
								</Typography>
							</Grid>
						</Grid>
						<Grid justify='center' container spacing={8} className={classes.spaceBetweenGrid}>
							<Grid xs={12} item className={classes.textAlignCenter}>
								<TextField
									name="Email"
									type="email"
									label="Email"
									className={classes.textField}
									value={this.state.Email}
									onChange={this.handleChangeTextField}
									margin="normal"
									variant="outlined"
									onKeyDown={this.onKeyDown}
								/>
							</Grid>
							<Grid xs={12} item className={classes.textAlignCenter}>
								<TextField
									name="Password"
									type="password"
									label="Password"
									className={classes.textField}
									value={this.state.Password}
									onChange={this.handleChangeTextField}
									margin="normal"
									variant="outlined"
									onKeyDown={this.onKeyDown}
								/>
							</Grid>
						</Grid>
						<Grid justify='center' container spacing={8} className={classes.spaceBetweenGrid}>
							<Grid xs={12} item className={classes.textAlignCenter}>
								<Typography
									className={classes.linkConnectionProblem}
									variant="overline"
									color="default"
									gutterBottom
									onClick={() => this.handleConnectionProblem(true)}
								>
									Problème de connexion ?
								</Typography>
							</Grid>
						</Grid>
						<Grid justify='center' container spacing={8}>
							<Grid xs={12} item className={classes.textAlignCenter}>
								<Fab
									variant="extended"
									color="secondary"
									onClick={this.handleSubmit}
								>
									VALIDER
								</Fab>
							</Grid>
						</Grid>
					</Paper>
					<Paper
						elevation={12}
						className={classes.paper}
					>
						<Grid justify='center' container spacing={8} className={classes.spaceBetweenGrid}>
							<Grid xs={12} item className={classes.textAlignCenter}>
								<Typography variant="h5" gutterBottom color="primary" >
									Problème de Connexion
								</Typography>
								<IconButton
									color="default"
									className={classes.closeConnectionProblem}
									onClick={() => this.handleConnectionProblem(false)}
								>
									<Close />
								</IconButton>
							</Grid>
						</Grid>
						<Grid justify='center' container spacing={8} className={classes.spaceBetweenGrid}>
							<Grid xs={12} item className={classes.textAlignCenter}>
								<TextField
									name="Email"
									type="email"
									label="Email"
									className={classes.textField}
									value={this.state.Email}
									onChange={this.handleChangeTextField}
									margin="normal"
									variant="outlined"
								/>
							</Grid>
						</Grid>
						<Grid justify='center' container spacing={8}>
							<Grid xs={12} item className={classes.textAlignCenter}>
								<FormControlLabel
									onChange={this.handleChangeProblemType}
									control={
										<Switch checked={this.state.IsPasswordProblem} />
									}
									label="Mot de passe oublié"
								/>
							</Grid>
						</Grid>
						<Grid justify='center' container spacing={8} className={classes.spaceBetweenGrid}>
							<Grid xs={12} item className={classes.textAlignCenter}>
								<FormControlLabel
									onChange={this.handleChangeProblemType}
									control={
										<Switch checked={!this.state.IsPasswordProblem} />
									}
									label="Email d'activation"
								/>
							</Grid>
						</Grid>
						<Grid justify='center' container spacing={8}>
							<Grid xs={12} item className={classes.textAlignCenter}>
								<Fab
									variant="extended"
									color="secondary"
									onClick={this.handleSubmitConnectionProblem}
								>
									Envoyer
								</Fab>
							</Grid>
						</Grid>
					</Paper>
				</SwipeableViews>
			</div>
		)
	}
}


Connection.displayName = 'Connection';

Connection.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(ConnectionStyles)(Connection);