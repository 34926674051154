import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CardHelperStyles from '../Views/Styles/HelperStyles/CardHelperStyles';
import { IconButton } from '@material-ui/core';
import Pencil from 'mdi-material-ui/PencilOutline';
import Delete from 'mdi-material-ui/DeleteOutline';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';


class CardHelper extends Component {
    constructor(props) {
		super(props);

		this.state = {
			DeleteGlasses: null,
		};
    }

    handleToogleDeleteDialog = (glasses) => {
		this.setState({
			DeleteGlasses: glasses
		});
    }

    handleDeleteGlasses = () => {
        this.props.handleDeleteGlasses(this.state.DeleteGlasses);

        this.setState({
            DeleteGlasses: null
        });
    }
    
    render() {

        const { classes, image, title, parent, glasses, elevation } = this.props;

        return (
            <Card square elevation={elevation} className={classes.card}>
                <CardMedia
                    className={classes.media}
                    image={image}
                />
                <CardContent className={classes.cardcontent}>
                    <Typography gutterBottom variant="h5" component="h2">
                        {title}
                    </Typography>
                </CardContent>
                {
                    parent && parent.state.User && parent.state.User.IsAdministrator &&
                    <div>
                        <CardActions className={classes.cardaction}>
                            <IconButton onClick={() => parent.handleMoveTo('/updateglasses/' + glasses.Id)}>
                                <Pencil />
                            </IconButton>
                            <IconButton color="secondary" onClick={() => this.handleToogleDeleteDialog(glasses)}>
                                <Delete />
                            </IconButton>
                        </CardActions>
                        <Dialog
                            open={Boolean(this.state.DeleteGlasses)}
                            onClose={() => this.handleToogleDeleteDialog(null)}
                        >
                            <DialogTitle>Suppression</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    Voulez-vous vraiment supprimer cette paire de lunettes ?
            				</DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => this.handleToogleDeleteDialog(null)} color="default">
                                    Non
            				    </Button>
                                <Button onClick={() => this.handleDeleteGlasses()} color="primary">
                                    Oui
							</Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                }
            </Card>
        );
    }
}

CardHelper.propTypes = {
    classes: PropTypes.object.isRequired,
    image: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    glasses: PropTypes.object.isRequired,
    elevation: PropTypes.number.isRequired,
};

export default withStyles(CardHelperStyles)(CardHelper);