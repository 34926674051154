import WebSocket from 'isomorphic-ws';
import HomeController from './HomeController';

const WS_URL = HomeController.UrlWS;

let WSService = null;

class WebSocketService {

    constructor() {
        this.websocket = null;
        this.messageListeners = [];
        this.isOpen = false;
    }

    /**
     *  Set up WebSocket connection for a new user and
     *  basic listeners to handle events
     */
    initSocket = () => {
        this.websocket = new WebSocket(WS_URL);
        this.websocket.onopen = this.onConnOpen;
        this.websocket.onmessage = this.onMessage;
        this.websocket.onclose = this.onConnClose;
    }

    /**
     *  Show connection status to us in the log
     */
    onConnOpen = () => {
        this.isOpen = true;
        console.log('Websocket connected!');
    }

    /**
     *  Log lost connection for now
     */
    onConnClose = () => {
        console.log('Websocket closed!');
        this.initSocket();
    }

    /**
     *  Used by application to send message to the WebSocket API Gateway
     *  @param routeKey The route key for WebSocket API Gateway
     *  @param message String message
     *  message {
     *    room,
     *    type,
     *    msg,
     *    username,
     *    for
     *  }
     */
    sendMessage = (routeKey, data) => {
        if (this.websocket && this.isOpen) {
            if (data && typeof data.message !== 'undefined') {
                this.websocket.send(JSON.stringify({
                    rcaction: routeKey,
                    rcmsg: data.message
                }));
            }
            else {
                this.websocket.send(JSON.stringify({
                    rcaction: routeKey
                }));
            }

            if (data && typeof data.promise === 'function') {
                data.promise();
            }
        } else {
            console.log(`Websocket connection not found!!`);
        }
    }

    /**
     *  Used by application to register different listeners for 
     *  different message types
     *  @param type Message type ['all', 'pm', 'userlist', 'useradd']
     *  @param listener Function to handle message type
     */
    addMessageListener = (type, listener) => {
        if (!type || typeof listener !== 'function') {
            return;
        }
        this.messageListeners.push({
            room: 'Default',
            type,
            listener
        });
    }

    /**
     * Handler that receives the actual messages from the WebSocket API
     * For now it simply returns the parsed message body to the appropriate
     * registered handler
     * @param data Message body received from WebSocket 
     */
    onMessage = (data) => {
        console.log(data)
        if (data) {
            const message = JSON.parse(data.data);
            const typeListener = this.messageListeners.find(listener => listener.type === message.type);

            if (typeListener && typeof typeListener.listener === "function") {
                typeListener.listener(message);
            } else {
                console.log('No handler found for message type');
            }
        }
    }

    static initWSService() {
        if (!WSService) {
            WSService = new WebSocketService();
            WSService.initSocket();
            return WSService;
        }

        return WSService;
    }

}

export const getWSService = WebSocketService.initWSService;