import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import withWidth from '@material-ui/core/withWidth';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import AddUpdateStyles from '../Styles/AddUpdateStyles';
import SnackbarHelper from '../../Helpers/SnackbarHelper';
import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import PresentationsController from '../../Controllers/PresentationsController';
import IconPhotoCameraOutlined from 'mdi-material-ui/CameraOutline';
import $ from 'jquery';
import { formatPicture } from '../../Tools/ImageTool';
import BackPageHelper from '../../Helpers/BackPageHelper';
import HomeIcon from 'mdi-material-ui/Home';
import StoreIcon from 'mdi-material-ui/Store';
import { getWSService } from '../../Controllers/WebSocketService';

let that;
var inputFilePicture;
var fileSize = 640;

class AddUpdate extends Component {
    constructor(props) {
        super(props);
        that = this;
        this.state = this.handleInitStates();
    }

    componentDidMount() {
        $(window).off();
        $(window).on('resize', this.handleMakeASquare);
        this.handleMakeASquare();
    }

    handleFormatPicture(file, size) {
        formatPicture(file, size,
            (picture, thumbnail, extension, error) => {
                if (!error) {
                    that.setState({
                        PictureExtension: extension,
                        Picture: picture,
                        Thumbnail: thumbnail,
                    });
                } else {
                    this.setState({
                        SnackbarMessage: error,
                        SnackbarType: 'warning'
                    });
                }
            });
    }

    handleInitStates() {
        var isAnUpdate = Boolean(this.props.Presentations);

        return {
            IdPresentations: isAnUpdate ? this.props.Presentations.Id : null,
            Title: isAnUpdate ? this.props.Presentations.Title : '',
            Description: isAnUpdate ? this.props.Presentations.Description : '',
            DateTimePost: isAnUpdate ? this.props.Presentations.DateTimePost : null,
            PicturePath: isAnUpdate ? this.props.Presentations.PicturePath : null,
            Picture: null,
            PictureExtension: isAnUpdate ? this.props.Presentations.PicturePath.split('.').pop().toLowerCase() : null,
            Thumbnail: isAnUpdate ? this.props.Presentations.PicturePath : null,
            Home: isAnUpdate ? this.props.Presentations.Home : false,
            Store: isAnUpdate ? this.props.Presentations.Store : false,
            SnackbarType: null,
            SnackbarMessage: null
        };
    }

    handleChoosePicture = () => {
        $(inputFilePicture).val('');
        $(inputFilePicture).off('change');
        inputFilePicture = null;
        $('#choosePicture').click();
        inputFilePicture = $('#choosePicture');
        $(inputFilePicture).on("change", function (e) {
            that.handleChangePicture(e);
        });
    }


    handleChangePicture = (event) => {
        this.handleFormatPicture(event.target.files[0], fileSize);
    }


    handleChangeTextField = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    }

    handleToggleType = (type) => {
        this.setState({
            [type]: !this.state[type]
        });
    }

    handleSubmit = () => {
        var error = PresentationsController.CheckPresentations(this);

        if (!error) {
            this.props.parent.handleLoadMainLoader();
            PresentationsController.AddUpdatePresentations(this, (success) => {
                if (success) {
                    getWSService().sendMessage("home",
                        {
                            promise: () => {
                                that.context.router.history.push('/');
                            }
                        });
                }
                else {
                    this.setState({
                        SnackbarMessage: `Erreur lors de ${!this.state.IdPresentations ? "l'ajout" : "la modification"} de la présentation`,
                        SnackbarType: 'error'
                    });
                    this.props.parent.handleUnloadMainLoader();
                }
            });
        }
        else {
            this.setState({
                SnackbarMessage: error,
                SnackbarType: 'error'
            });
        }
    }

    handleMakeASquare = () => {
        $("#picturecontent").height($("#picturecontent").innerWidth());
    }


    render() {
        const { classes } = this.props;

        return (
            <div>
                <SnackbarHelper
                    type={this.state.SnackbarType}
                    message={this.state.SnackbarMessage}
                    closeSnackbar={this.handleCloseSnackbar}
                />
                <BackPageHelper path="/" />
                <Paper elevation={1} className={classNames(classes.paperau)} onKeyPress={this.handleKeyPress}>
                    <Grid justify='center' container spacing={0}>
                        <Grid xs={12} item className={classes.textAlignCenter}>
                            <input id="choosePicture" className={classes.uploadInput} type="file" accept="image/*" />
                            <div
                                id="picturecontent"
                                className={classNames(classes.pictureContentP)}
                                style={this.state.Thumbnail && { backgroundImage: "url('" + this.state.Thumbnail + "')" }}
                                onClick={event => this.handleChoosePicture(event, 'choosePicture')}
                            >
                                {!this.state.Thumbnail &&
                                    <div>
                                        <IconPhotoCameraOutlined className={classes.cameraIcon} />
                                        <Typography
                                            component="p"
                                            className={classes.pictureText}
                                        >
                                            Cliquer pour ajouter ou modifier une photo
                                        </Typography>
                                    </div>
                                }
                            </div>
                        </Grid>
                    </Grid>
                    <Grid justify='center' container spacing={0}>
                        <div className={classes.contentControls}>
                            <Grid xs={12} item className={classes.textAlignCenter}>
                                <IconButton
                                    color={this.state.Home ? 'secondary' : 'default'}
                                    onClick={() => this.handleToggleType('Home')}
                                >
                                    <HomeIcon fontSize="large" />
                                </IconButton>
                                <IconButton
                                    color={this.state.Store ? 'secondary' : 'default'}
                                    onClick={() => this.handleToggleType('Store')}
                                >
                                    <StoreIcon fontSize="large" />
                                </IconButton>
                            </Grid>
                        </div>
                    </Grid>
                    <Grid justify='center' container spacing={0}>
                        <Grid xs={12} item className={classes.textAlignCenter}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                label="Title"
                                type="text"
                                value={this.state.Title}
                                onChange={this.handleChangeTextField}
                                name="Title"
                                className={classes.textFieldWidth}
                            />
                        </Grid>
                    </Grid>
                    <Grid justify='center' container spacing={0}>
                        <Grid xs={12} item className={classes.textAlignCenter}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                label="Description"
                                type="text"
                                value={this.state.Description}
                                rowsMax="10"
                                multiline={true}
                                onChange={this.handleChangeTextField}
                                name="Description"
                                className={classes.textFieldWidth}
                            />
                        </Grid>
                    </Grid>
                    <Grid justify='center' container spacing={0}>
                        <Grid xs={12} item className={classes.textAlignCenter}>
                            <div className={classNames(classes.submitButtonContent)}>
                                <Button
                                    variant="contained"
                                    tabIndex="-1"
                                    color="primary"
                                    onClick={this.handleSubmit}
                                    className={classNames(classes.submitButton)}
                                >
                                    Valider
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                </Paper>
            </div>
        );
    }
}

AddUpdate.displayName = 'AddUpdate';

AddUpdate.contextTypes = {
    router: PropTypes.object.isRequired,
};

AddUpdate.propTypes = {
    classes: PropTypes.object.isRequired,
    width: PropTypes.string.isRequired,
};

export default withWidth()(withStyles(AddUpdateStyles)(AddUpdate));
