import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import HeaderStyles from '../Styles/_SharedStyles/HeaderStyles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Menu from 'mdi-material-ui/Menu';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import ChevronRight from 'mdi-material-ui/ChevronRight';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import HomeOutline from 'mdi-material-ui/HomeOutline';
import Newspaper from 'mdi-material-ui/Newspaper';
// eslint-disable-next-line no-unused-vars
import Eye from 'mdi-material-ui/Eye';
import Calendar from 'mdi-material-ui/Calendar';
import CalendarPlus from 'mdi-material-ui/CalendarPlus';
import ShieldLockOutline from 'mdi-material-ui/ShieldLockOutline';
import ShieldAccountOutline from 'mdi-material-ui/ShieldAccountOutline';
import AccountPlusOutline from 'mdi-material-ui/AccountPlusOutline';
import AccountOutline from 'mdi-material-ui/AccountOutline';
import AccountArrowRightOutline from 'mdi-material-ui/AccountArrowRightOutline';
import SessionController from '../../Controllers/SessionController';
import { emailConfirmation } from '../../Tools/EmailTool';
import SnackbarHelper from '../../Helpers/SnackbarHelper';
import MainLoaderHelper from '../../Helpers/MainLoaderHelper';

class Header extends Component {
	constructor(props) {
		super(props);

		this.state = {
			User: null,
			DrawerIsOpen: false,
			SnackbarMessage: null,
			SnackbarType: null,
			MainLoaderIsVisible: false,
			MainLoaderIsHome: false,
		};
	}

	componentWillMount() {
		emailConfirmation((response) => {
			if (response) {
				this.setState({
					SnackbarMessage: response.message,
					SnackbarType: response.type
				});

				window.history.pushState(null, null, `${window.location.origin}/#/`);
			}
		});

		this.setState({
			User: SessionController.GetUser()
		});

		this.context.router.history.listen((location, action) => {
			this.setState({
				User: SessionController.GetUser()
			});
		});
	}

	UpdateUser = () => {
		this.props.UpdateUser();
		this.setState({
			User: SessionController.GetUser()
		});
	}

	handleUpdateUser = (user) => {
		this.setState({
			User: user
		});

		SessionController.SetUser(user);
	}

	handleLoadMainLoader = (isHome = false) => {
		this.setState({
			MainLoaderIsVisible: true,
			MainLoaderIsHome: isHome
		});
	}

	handleUnloadMainLoader = (timeout = null) => {
		var realTimeout = !timeout ? (this.state.MainLoaderIsHome ? 500 : 200) : timeout;
		setTimeout(() => {
			this.setState({
				MainLoaderIsVisible: false,
				MainLoaderIsHome: false
			});
		}, realTimeout);
	}

	handleOpenDrawer = () => {
		this.setState({
			DrawerIsOpen: true
		});
	}

	handleCloseDrawer = () => {
		this.setState({
			DrawerIsOpen: false
		});
	}

	handleCloseSnackbar = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		this.setState({
			SnackbarMessage: null
		});
	}

	handleMoveTo = (path) => {
		this.handleCloseDrawer();
		this.context.router.history.push(path);
	}

	getDrawerList = () => {
		var handle = (path) => this.handleMoveTo(path);

		return [
			{ type: "ListItem", label: "Accueil", icon: <HomeOutline />, function: handle, path: "/" },
			{ type: "ListItem", label: "Collection", icon: <Newspaper />, function: handle, path: "/collection" },
			{ type: "ListItem", label: "Mention légale", icon: <ShieldLockOutline />, function: handle, path: "/legalnotice" },
			{ type: "ListItem", label: "Politique confidentialité", icon: <ShieldAccountOutline />, function: handle, path: "/privacypolicy" },
			{ type: "Divider" },
			// (this.state.User &&
			// 	{ type: "ListItem", label: "Mes lentilles", icon: <Eye />, function: handle, path: "/renewallens" }),
			(!this.state.User &&
				{ type: "ListItem", label: "Prendre RDV", icon: <CalendarPlus />, function: handle, path: "/connection" }),
			((this.state.User && !this.state.User.IsAdministrator) &&
				{ type: "ListItem", label: "Prendre RDV", icon: <CalendarPlus />, function: handle, path: "/makeappointments" }),
			(this.state.User &&
				{ type: "ListItem", label: "Mes RDV", icon: <Calendar />, function: handle, path: "/appointments" }),
			(!this.state.User ?
				{ type: "ListItem", label: "Inscription", icon: <AccountPlusOutline />, function: handle, path: "/registration" } :
				{ type: "ListItem", label: "Mon Compte", icon: <AccountOutline />, function: handle, path: "/account" }),
			(!this.state.User ?
				{ type: "ListItem", label: "Connexion", icon: <AccountOutline />, function: handle, path: "/connection" } :
				{ type: "ListItem", label: "Déconnexion", icon: <AccountArrowRightOutline />, function: () => SessionController.Logout(() => this.context.router.history.push('/')) })
		];
	}


	render() {
		const { classes, children } = this.props;
		const childrenWithProps = React.cloneElement(children, { parent: this });

		return (
			<div>
				{
					this.state.MainLoaderIsVisible &&
					<MainLoaderHelper
						visible={this.state.MainLoaderIsVisible}
						isHome={this.state.MainLoaderIsHome}
					/>
				}

				<SnackbarHelper
					type={this.state.SnackbarType}
					message={this.state.SnackbarMessage}
					closeSnackbar={this.handleCloseSnackbar}
				/>
				<AppBar elevation={6} position="fixed">
					<Toolbar>
						<div className={classes.logo} onClick={() => this.handleMoveTo('/')} />
						<Typography
							color="inherit"
							variant="h6"
							className={classes.title}
						>
							OPTIC SALOMON
						</Typography>
						<IconButton
							onClick={this.handleOpenDrawer}
							color="inherit"
						>
							<Menu />
						</IconButton>
						<Drawer
							classes={{
								paper: classes.paperDrawer
							}}
							anchor="right"
							open={this.state.DrawerIsOpen}
							onClose={this.handleCloseDrawer}
						>
							<div className={classes.drawerHeader}>
								<IconButton
									onClick={this.handleCloseDrawer}
								>
									<ChevronRight />
								</IconButton>
							</div>
							<Divider className={classes.dividerDrawerHeader} />
							<div className={classes.drawerdivlist}>
								<List className={classes.drawerlist}>
									{this.getDrawerList().map((e, i) => (
										e !== null && (e.type === "ListItem" ?
											<div
												key={i}
												tabIndex={0}
												role="button"
												onClick={e.path ? () => e.function(e.path) : () => e.function()}
												onKeyDown={e.path ? () => e.function(e.path) : () => e.function()}
											>
												<ListItem button key={i}>
													<ListItemIcon className={classes.listItemIcon}>{e.icon}</ListItemIcon>
													<ListItemText className={classes.listItemText} primary={e.label} />
												</ListItem>
											</div> :
											<Divider key={i} />
										)))}
								</List>
							</div>
							{
								this.state.User &&
								<div>
									<Divider className={classes.absoluteDivider} />
									<div className={classes.typoName}>
										<Typography
											variant="subtitle1"
											color="inherit"
											className={classes.typoNameText}
										>
											{`${this.state.User.FirstName} ${this.state.User.LastName}`}
										</Typography>
									</div>
								</div>
							}
						</Drawer>
					</Toolbar>
				</AppBar>
				<div className={classes.fakeAppBar} />
				{childrenWithProps}
			</div>
		);
	}
}

Header.displayName = "Header";

Header.propTypes = {
	classes: PropTypes.object.isRequired,
	children: PropTypes.node,
};

Header.contextTypes = {
	router: PropTypes.object.isRequired,
};

export default withStyles(HeaderStyles)(Header);