const BackPageHelperStyles = theme => ({
    backButtonContainer: {
        position: 'fixed',
    },
    backButton: {
        [theme.breakpoints.only('xs')]: {
            position: 'absolute',
            left: 0,
            top: 56,
		},
        top: 76,
		'@media (min-width:0px) and (orientation: landscape)': {
			top: 64,
		},
		'@media (min-width:600px)': {
			top: 80,
		},
        left: 20,
        position: 'fixed'
    },
});

export default BackPageHelperStyles;