import common from '@material-ui/core/colors/common';

const textAlignLeft = {
    textAlign: 'left'
};
const textAlignCenter = {
    textAlign: 'center'
};
const textAlignRight = {
    textAlign: 'right'
};
const textAlignJustify = {
    textAlign: 'justify'
};
const justifyContentCenter = {
    justifyContent: 'center'
};
const floatLeft = {
    float: 'left'
};
const floatRight = {
    float: 'right'
};
const positionAbsolute = {
    position: 'absolute',
};
const whiteColor = {
    color: common.white,
};
const displayFlex = {
    display: 'flex',
};
const fullWidthpc = {
    width: '100%',
};
const fullHeightpc = {
    height: '100%',
};
const hidden = {
    display: 'none !important',
};




export {
    textAlignLeft,
    textAlignCenter,
    textAlignRight,
    textAlignJustify,
    justifyContentCenter,
    floatLeft,
    floatRight,
    positionAbsolute,
	whiteColor,
    displayFlex,
    fullWidthpc,
    fullHeightpc,
    hidden
};