import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles'
import AdministrationLensStyles from './Styles/AdministrationLensStyles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Account from 'mdi-material-ui/Account';
import DotsVertical from 'mdi-material-ui/DotsVertical';
import moment from 'moment';
import "moment/locale/fr";
moment.locale('fr');

class AdministrationLens extends Component {
	constructor(props) {
		super(props);

		this.state = {
			View: 1,
			anchorEl: null,
			PictureCollapsed: true,
			PicturePath: 'http://cdn-uploads.gameblog.fr/images/dossiers/joshua-dunlop-geodude-final-watermarked-1.jpg',
			Picture: null,
			PictureExtension: 'jpg',
			Thumbnail: 'http://cdn-uploads.gameblog.fr/images/dossiers/joshua-dunlop-geodude-final-watermarked-1.jpg',
			AlertCollapsed: true,
			AlertMessage: '',
			AlertStartDate: null,
			AlertEndDate: null,
			OpeningTimeCollapsed: true,
			PresentationCollapsed: true,
		};
	}

	componentDidMount() {

	}

	handleDateFocused = (name) => {
		if (!this.state[name]) {
			this.setState({
				[name]: moment.utc()
			});
		}
	}

	handleDateChange = (date, name) => {
		this.setState({
			[name]: date
		});
	}

	handleListItemClick = (event, index) => {
		this.setState({ selectedIndex: index });
	};


	handleClick = event => {
		this.setState({ anchorEl: event.currentTarget });
	};


	handleClose = () => {
		this.setState({ anchorEl: null });
	};

	handleSubmit() {

	}

	render() {
		const { classes } = this.props;
		const { anchorEl } = this.state;
		const open = Boolean(anchorEl);

		return (
			<div className={classes.rootPaper}>
				{
					["Flavien", "Julie"].map((o, i) => {
						return (
							<Paper key={o} className={classes.paperlist} elevation={10}>
								<Avatar>
									<Account />
								</Avatar>
								<div className={classes.typographyList}>
									<Typography variant="button">
										{o}
									</Typography>
									<Typography variant="overline">
										{o}
									</Typography>
								</div>
								<IconButton
									aria-label="More"
									aria-owns={open ? 'long-menu' : undefined}
									aria-haspopup="true"
									onClick={this.handleClick}>
									<DotsVertical />
								</IconButton>
							</Paper>
						)
					})
				}
			</div>
		)
	}
}


AdministrationLens.displayName = 'AdministrationLens';

AdministrationLens.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(AdministrationLensStyles)(AdministrationLens);