import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import CircularProgress from '@material-ui/core/CircularProgress';
import MainLoaderHelperStyles from '../Views/Styles/HelperStyles/MainLoaderHelperStyles';

class MainLoaderHelper extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Visible: false,
            IsHome: false,
        }
    }

    componentWillMount() {
        this.setState({
            Visible: this.props.visible,
            IsHome: this.props.isHome,
        });

        document.body.style.overflow = 'hidden';
    }

    // componentWillReceiveProps(nextProps) {
    //     setTimeout(() => {
    //         this.setState({
    //             Visible: nextProps.visible,
    //             IsHome: nextProps.isHome,
    //         });
    //     }, !nextProps.visible ? 400 : 0);
    // }

    componentWillUnmount() {
        document.body.style.overflow = 'auto';
    }

    render() {
        const { classes } = this.props;

        return (
            <div className={classNames({ [classes.hidden]: !this.state.Visible })}>
                <div
                    className={classNames(
                        {
                            [classes.homeloaderbackground]: this.state.IsHome,
                            [classes.loaderbackground]: !this.state.IsHome
                        }
                    )}
                />
                <CircularProgress
                    color={this.state.IsHome ? "primary" : "secondary"}
                    size={60}
                    thickness={4}
                    className={classNames(
                        {
                            [classes.homemloader]: this.state.IsHome,
                            [classes.mloader]: !this.state.IsHome
                        }
                    )}
                />
                <div className={classes.messageLoaderContent}>
                    {
                        this.state.IsHome && <img style={{ width: '50%', minWidth: 200 }} alt="" src="https://opticsalomon.s3.eu-west-3.amazonaws.com/assets/banner.png" />
                    }
                </div>
            </div>
        );
    }
}

MainLoaderHelper.displayName = 'MainLoaderHelper';

MainLoaderHelper.propTypes = {
    visible: PropTypes.bool.isRequired,
    isHome: PropTypes.bool.isRequired,
};

export default withStyles(MainLoaderHelperStyles)(MainLoaderHelper);