import React, { Component } from 'react';
import AddUpdate from './AddUpdate';
import PresentationsController from '../../Controllers/PresentationsController';

let that;
class Update extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Presentations: null,
        };
        that = this;
    }

    componentWillMount() {
        var matchParams = PresentationsController.GetMatchParams(this);
        if (matchParams) {
            PresentationsController.GetPresentationsById(matchParams,
                (presentations) => {
                    that.setState({
                        Presentations: presentations
                    });
                });
        }
    }

    render() {
        return (
            <div>
                {
                    this.state.Presentations &&
                    <AddUpdate parent={this.props.parent} Presentations={this.state.Presentations} />
                }
            </div>
        );
    }
}

Update.displayName = "Update";

export default Update;