import { hidden } from '../_MainStyles';
import common from '@material-ui/core/colors/common';

const MainLoaderStyles = theme => ({
    homeloaderbackground: {
        position: 'fixed !important',
        backgroundColor: common.white,
        zIndex: '2000!important',
        left: '0!important',
        top: '0!important',
        right: '0!important',
        bottom: '0!important',
    },
    loaderbackground: {
        position: 'fixed !important',
        backgroundColor: common.black,
        opacity: 0.2,
        zIndex: '2000!important',
        left: '0!important',
        top: '0!important',
        right: '0!important',
        bottom: '0!important',
    },
    homemloader: {
        position: 'fixed !important',
        zIndex: '2020 !important',
        left: 'calc(50% - 40px) !important',
        top: 'calc(30% - 40px) !important',
        width: '80px !important',
        height: '80px !important',
    },
    mloader: {
        position: 'fixed !important',
        zIndex: '2020 !important',
        left: 'calc(50% - 40px) !important',
        top: 'calc(50% - 40px) !important',
        width: '80px !important',
        height: '80px !important',
    },
    messageLoaderContent: {
        color: "#bbbbbb !important",
        position: 'fixed',
        zIndex: '2020',
        left: 0,
        fontSize: '20px',
        textAlign: 'center',
        top: 'calc(30% + 100px)',
        textTransform: 'uppercase',
        width: '100%'
    },
    messageLoader: {
        color: "#222",
    },
    hidden: {
        ...hidden,
    }
});

export default MainLoaderStyles;